/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import intl from 'intl';
import RestApiUtils from '../../utils/RestApiUtils';
import RenderUtils from '../../utils/RenderUtils';
import actionCreators from '../../actions/actionCreators';
import {Navigation, State} from 'react-router';
import {TrafficStore} from '../../stores';
import {getId} from '../../utils/GeneralUtils';

export default React.createClass({
  mixins: [Navigation, State],

  componentWillMount() {
    this.getRuleset(this.props.data);
  },

  componentWillReceiveProps(nextProps) {
    if (!TrafficStore.getRingFenceRules(getId(this.props.data?.href))) {
      this.getRingFenceRules();
    }

    if (this.props.data.href !== nextProps.data.href) {
      // only if it's not the same location get the new ruleset
      this.getRuleset(nextProps.data);
    }
  },

  getRingFenceRules() {
    const {data} = this.props;
    const labelsObject = {labels: [data.locationLabel].map(label => ({href: label.href}))};

    _.defer(() =>
      RestApiUtils.ruleCoverage.get(
        [
          {
            source: labelsObject,
            destination: labelsObject,
            services: [{port: -1, protocol: -1}],
            resolve_labels_as: {source: ['workloads'], destination: ['workloads']},
          },
        ],
        labelsObject.labels,
        'ringfence',
        getId(data.href),
        'draft',
        true,
      ),
    );
  },

  getRuleset(data) {
    if (data.href === 'discovered' || data.href === 'no_location') {
      return;
    }

    _.defer(() => RestApiUtils.ruleSets.getGroupCollection({loc: data.href}, 'rule_set_services_labels_and_names'));
  },

  handleRowClick(evt, group) {
    actionCreators.clearExpandedClusters();
    this.gotoGroup(group);
  },

  handleRowHover(evt, group) {
    // Only perform the hovering if there are links otherwise this is useless and expensive
    if (this.props.data.locationGroups.length < (localStorage.getItem('max_clusters_for_links') || 75)) {
      actionCreators.hoverComponent([
        {
          type: 'group',
          href: group.href,
        },
      ]);
    }
  },

  handleRowUnhover(evt, group) {
    if (this.props.data.locationGroups.length < (localStorage.getItem('max_clusters_for_links') || 75)) {
      actionCreators.unhoverComponent([
        {
          type: 'group',
          href: group.href,
        },
      ]);
    }
  },

  gotoGroup(group) {
    const level = {
      type: 'group',
      id: group.href,
    };

    actionCreators.selectComponent([
      {
        type: 'group',
        href: group.href,
        clusterId: group.id,
      },
    ]);
    this.props.updateMapLevel(level);
  },

  handleShowWorkloads(type) {
    return () => {
      if (this.props.data.href === 'discovered' || this.props.data.href === 'no_location') {
        return;
      }

      const scope = [{key: 'loc', href: this.props.data.locationLabel.href}];
      const params = {
        scope: JSON.stringify({scope}),
      };

      this.transitionTo(`${type}.list`, null, params);
    };
  },

  render() {
    let groups = null;

    if (this.props.data.locationGroups && this.props.data.locationGroups.length) {
      groups = _.map(this.props.data.locationGroups, group => {
        let groupName = group.name;

        if (group.name.length > 35) {
          groupName = <span title={group.name}>{RenderUtils.truncateAppGroupName(group.name, 35, [20, 10, 5])}</span>;
        }

        return (
          <tr key={group.labels} className="MapSubInfoPanel-Row" data-tid="map-info-panel-row-value">
            <td
              className="MapSubInfoPanel-Row-Label-Link"
              onClick={evt => {
                this.handleRowClick(evt, group);
              }}
              onMouseOver={evt => {
                this.handleRowHover(evt, group);
              }}
              onMouseOut={evt => {
                this.handleRowUnhover(evt, group);
              }}
            >
              {groupName}
            </td>
          </tr>
        );
      });

      const groupHeader = (
        <tr className="MapSubInfoPanel-Header" data-tid="map-info-panel-row-header">
          <th className="MapInfoPanel-Row-Label">{intl('Common.GroupsCount', {count: groups.length})}</th>
        </tr>
      );

      groups = (
        <tr className="MapInfoPanel-Row">
          <td colSpan="2" className="MapSubInfoPanel-Row-Label">
            <div className="MapInfoPanel-Row--Scroll">
              <table className="MapSubInfoPanel">
                <tbody>
                  {groupHeader}
                  {groups}
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      );
    }

    const workloadsValue = this.props.data.workloadsNum;
    const workloadsValueClassName = cx({
      'CommandPanel-Link': this.props.data.href !== 'discovered' && this.props.data.href !== 'no_location',
    });
    const workloadsDisplay = (
      <tr className="MapInfoPanel-Row MapInfoPanel-Row-Value-Overflow">
        <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          {intl('Workloads.Total')}
        </th>
        <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
          <div onClick={this.handleShowWorkloads('workloads')}>
            <span className={workloadsValueClassName}>{workloadsValue}</span>
          </div>
        </td>
      </tr>
    );

    const containerWorkloadsValue = this.props.data.containerWorkloadsNum;
    const containerWorkloadsValueClassName = cx({
      'CommandPanel-Link': this.props.data.href !== 'discovered' && this.props.data.href !== 'no_location',
    });
    const containerWorkloadsValueDisplay = (
      <tr className="MapInfoPanel-Row MapInfoPanel-Row-Value-Overflow">
        <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          {intl('ContainerWorkloads.Total')}
        </th>
        <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
          <div onClick={this.handleShowWorkloads('containerWorkloads')}>
            <span className={containerWorkloadsValueClassName}>{containerWorkloadsValue}</span>
          </div>
        </td>
      </tr>
    );

    const virtualServicesValue = this.props.data.virtualServicesNum;
    const virtualServicesValueClassName = cx({
      'CommandPanel-Link': this.props.data.href !== 'discovered' && this.props.data.href !== 'no_location',
    });
    const virtualServicesDisplay = (
      <tr className="MapInfoPanel-Row MapInfoPanel-Row-Value-Overflow">
        <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          {intl('VirtualServices.Total')}
        </th>
        <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
          <div onClick={this.handleShowWorkloads('virtualServices')}>
            <span className={virtualServicesValueClassName}>{virtualServicesValue}</span>
          </div>
        </td>
      </tr>
    );

    const virtualServersValue = this.props.data.virtualServersNum;
    const virtualServersValueClassName = cx({
      'CommandPanel-Link': this.props.data.href !== 'discovered' && this.props.data.href !== 'no_location',
    });
    const virtualServersDisplay = (
      <tr className="MapInfoPanel-Row MapInfoPanel-Row-Value-Overflow">
        <th className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          {intl('VirtualServers.Total')}
        </th>
        <td className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
          <div onClick={this.handleShowWorkloads('virtualServers')}>
            <span className={virtualServersValueClassName}>{virtualServersValue}</span>
          </div>
        </td>
      </tr>
    );

    return (
      <table className="MapInfoPanel">
        <tbody>
          {groups}
          {workloadsDisplay}
          {containerWorkloadsValue !== 0 && containerWorkloadsValueDisplay}
          {virtualServicesValue !== 0 && virtualServicesDisplay}
          {virtualServersValue !== 0 && virtualServersDisplay}
        </tbody>
      </table>
    );
  },
});
