/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getSessionUri, getInstanceUri} from '../lib/api';
import RestApiUtils from './RestApiUtils';
import RenderUtils from './RenderUtils';
import ServiceUtils from './ServiceUtils';
import IpListStore from '../stores/IpListStore';
import TrafficStore from '../stores/TrafficStore';
import MapPageStore from '../stores/MapPageStore';
import SessionStore from '../stores/SessionStore';
import TrafficFilterStore from '../stores/TrafficFilterStore';
import GraphStore from '../stores/GraphStore';
import actionCreators from '../actions/actionCreators';
import intl from 'intl';
import pLimit from 'p-limit';

const MAX_CLUSTERS_PER_LOCATION_FOR_LINKS = 75;
const trafficClasses = ['unicast', 'broadcast', 'multicast', 'core_service'];

async function getMapLevelByTotalWorkloads() {
  let totalWorkloads = MapPageStore.getTotalWorkloads();

  if (totalWorkloads === -1) {
    let labels = '[]';

    if (SessionStore.isIlluminationMapEnabled()) {
      labels = JSON.stringify([
        [`${getSessionUri(getInstanceUri('labels'), {label_id: ''}).slice(0, -1)}?key=loc&exists=false`],
      ]);
    }

    const res = await RestApiUtils.workloads.getCollection({max_results: 1, labels}, true);
    const noLocationWorkloads = Number(res.headers.get('x-matched-count'));

    totalWorkloads = Number(res.headers.get('x-total-count'));

    // For performance reasons, only make these calls if we haven't found any regular workloads
    if (!totalWorkloads) {
      const otherRes = await Promise.all([
        RestApiUtils.containerWorkloads.getCollection({max_results: 1}, true),
        RestApiUtils.virtualServices.getCollection('draft', {max_results: 1}, true),
      ]);

      otherRes.forEach(response => {
        totalWorkloads += Number(response.headers.get('x-total-count'));
      });
    }

    actionCreators.setTotalWorkloads({totalWorkloads, noLocationWorkloads});
  }

  if (totalWorkloads === 0) {
    return 'none';
  }

  return totalWorkloads > (localStorage.getItem('location_view') || 50) ? 'loc' : 'full';
}

// Check if Data is Loaded

function isSummaryDataLoaded(type) {
  let location = true;

  if (MapPageStore.getLocMapType() !== 'none') {
    location = isLocationSummaryLoaded(type === 'requested');
  }

  const appGroup = isAppGroupSummaryLoaded(type === 'requested');

  return location && appGroup;
}

function isLocationSummaryLoaded(requested) {
  if (MapPageStore.getLocMapType() === 'none' || !SessionStore.isIlluminationMapEnabled()) {
    return true;
  }

  return requested ? TrafficStore.isTrafficRequested('locations') : TrafficStore.isTrafficLoaded('locations');
}

function isAppGroupSummaryLoaded(requested) {
  if (!SessionStore.isIlluminationApiEnabled()) {
    return true;
  }

  return !TrafficStore.getAppGroupsType().length || requested
    ? TrafficStore.isTrafficRequested('appGroups')
    : TrafficStore.isTrafficLoaded('appGroups');
}

function isTrafficLoaded(expandedGroups, expandedRoles) {
  if (!SessionStore.isIlluminationApiEnabled()) {
    return true;
  }

  const mapLevel = MapPageStore.getMapLevel();

  if (!isSummaryDataLoaded('loaded')) {
    return false;
  }

  if (mapLevel === 'full') {
    return TrafficStore.getAllClusterNodes().every(
      cluster => !cluster.href || TrafficStore.isTrafficLoaded('workloads', cluster.href),
    );
  }

  if (expandedRoles && mapLevel && mapLevel.includes('connected')) {
    const roles = Object.values(expandedRoles);

    const transmissionFilters = TrafficFilterStore.getTransmissionFilters();
    let isTrafficLoadedBool = transmissionFilters.length;

    transmissionFilters.forEach((transmissionFilter, i) => {
      if (transmissionFilter) {
        isTrafficLoadedBool =
          isTrafficLoadedBool &&
          TrafficStore.isTrafficLoaded('roles', `${MapPageStore.getMapRoute().previd}x${trafficClasses[i]}`) &&
          (!roles.length ||
            !roles.some(roleHref => !TrafficStore.isTrafficLoaded('workloads', `${roleHref}x${trafficClasses[i]}`)));
      }
    });

    return isTrafficLoadedBool;
  }

  return true;
}

async function loadTraffic(loadingType, delay = true, clearTraffic) {
  if (MapPageStore.getTotalWorkloads() === -1 || !SessionStore.isIlluminationApiEnabled()) {
    return;
  }

  const delayTime = loadingType === 'rebuild' && delay ? 2000 : 0;
  const transmissionFilters = TrafficFilterStore.getTransmissionFilters();

  if (sessionStorage.getItem('rebuild_map') === 'true' && loadingType === 'rebuild') {
    await sessionStorage.setItem('rebuild_map', 'false');
  }

  await new Promise(resolve => setTimeout(resolve, delayTime));
  await loadLevelTraffic(loadingType, transmissionFilters, clearTraffic || loadingType === 'rebuild');
}

async function loadLevelTraffic(type, transmissionFilters, clearTraffic) {
  if (!SessionStore.isIlluminationApiEnabled()) {
    return;
  }

  const mapType = MapPageStore.getMapType();
  const mapLevel = MapPageStore.getMapLevel();
  const mapRoute = MapPageStore.getMapRoute();
  const policyVersion = MapPageStore.getPolicyVersion();
  const data = GraphStore.getGraphData();

  // If summary is not  requested, request it and don't continue.
  if (!isSummaryDataLoaded('requested') || (type === 'rebuild' && _.isEmpty(mapRoute))) {
    // Load all the summary for both maps
    await Promise.all([getTraffic('location', {type}), getTraffic('appGroup', {type})]);

    if (SessionStore.areVulnerabilitiesEnabled()) {
      RestApiUtils.appGroups.getCollection();
    }
  }

  // If the summary is not loaded do not load the other traffic APIs
  if (!isSummaryDataLoaded('loaded')) {
    return;
  }

  if (mapType === 'app' && !TrafficStore.isPolicyGeneratorCoverageLoaded() && TrafficStore.getLoadRulesForAppGroups()) {
    RestApiUtils.appGroupRules.startAppGroupRulesTimer();
  }

  if (mapLevel === 'full') {
    await loadAllTrafficForFullMap(type, transmissionFilters, clearTraffic);
  } else if (mapLevel === 'group') {
    if (data.clusters.length > localStorage.getItem('max_clusters_for_links') || MAX_CLUSTERS_PER_LOCATION_FOR_LINKS) {
      await loadClusterLevelTraffic(type, transmissionFilters, clearTraffic);
    }
  } else if (mapLevel === 'workload') {
    await loadWorkloadLevelTraffic(type, data, transmissionFilters, clearTraffic);
  } else if (mapType === 'app') {
    if (mapLevel !== 'globalAppGroup') {
      await loadWorkloadLevelTraffic(type, data, transmissionFilters, clearTraffic);
    }

    loadVulnerabilityTraffic(data);
  }

  if (policyVersion === 'draft') {
    loadRules(data);
  }
}

async function loadClusterLevelTraffic(type, transmissionFilters, clearTraffic) {
  if (!SessionStore.isIlluminationApiEnabled()) {
    return;
  }

  const href = getSessionUri(getInstanceUri('labels'), {label_id: MapPageStore.getMapRoute().id});
  const labels = JSON.stringify([href]);
  const requests = [];

  if (TrafficStore.getLocation(href) && Object.keys(TrafficStore.getLocation(href).groups).length < 200) {
    transmissionFilters.forEach((transmissionFilter, i) => {
      const groupKey = `no_keyx${trafficClasses[i]}`;

      if (!TrafficStore.isTrafficRequested('groups', groupKey, labels) || type === 'rebuild') {
        if (transmissionFilter) {
          const query = {clusters: true, labels};

          if (clearTraffic) {
            actionCreators.clearTrafficOnNext(JSON.stringify(query));
          }

          requests.push(getTraffic({...query, traffic_class: trafficClasses[i]}, {type}));
        }
      }
    });
  }

  return Promise.all(requests);
}

function loadConnectedGroup(href) {
  const loadConnectedGroups = JSON.parse(sessionStorage.getItem('loadConnectedGroups'));

  return (
    sessionStorage.getItem('loadAllConnected') ||
    (loadConnectedGroups && Array.isArray(loadConnectedGroups) && loadConnectedGroups.includes(href))
  );
}

async function loadWorkloadLevelTraffic(type, data, transmissionFilters, clearTraffic) {
  if (!SessionStore.isIlluminationApiEnabled()) {
    return;
  }

  const mapType = MapPageStore.getMapType();
  const mapRoute = MapPageStore.getMapRoute();
  const clusterType = {};

  // scopedRequest is for connected groups, where we will only parse connected role traffic
  // so we need to make sure it is recalled later for a different set of traffic
  let scopedRequest;
  let connectedRequest;

  let clusterHrefs = [mapRoute.previd || mapRoute.id];

  if (mapType === 'app' && mapRoute.previd && loadConnectedGroup([mapRoute.previd, mapRoute.id].join(','))) {
    clusterHrefs.push(mapRoute.id);
    connectedRequest = mapRoute.id;
  }

  const requests = [];

  if (mapType === 'loc') {
    transmissionFilters.forEach((transmissionFilter, i) => {
      if (transmissionFilter) {
        const clusterKey = `${mapRoute.id}x${trafficClasses[i]}`;

        if (!TrafficStore.isTrafficRequested('groups', clusterKey) || type === 'rebuild') {
          const query = {clusters: true, cluster_keys: JSON.stringify([mapRoute.id])};

          if (clearTraffic) {
            actionCreators.clearTrafficOnNext(JSON.stringify(query));
          }

          requests.push(getTraffic({...query, traffic_class: trafficClasses[i]}, {type}));
        }
      }
    });

    // For the roles, always scope based on the appGroup, so we don't have to reload the data when we switch maps
    clusterHrefs = data.expandedClusters.length ? data.expandedClusters : [mapRoute.id];
    scopedRequest = clusterHrefs.find(href => href !== mapRoute.id);

    clusterHrefs = _.uniq(
      clusterHrefs.map(href => {
        const cluster = TrafficStore.getNode(href);
        const clusterHref = (cluster && cluster.appGroupParent) || href;

        if (!cluster || !cluster.appGroupParent) {
          clusterType[clusterHref] = 'cluster';
        } else {
          clusterType[clusterHref] = 'appGroup';
        }

        return clusterHref;
      }),
    );
  }

  const scope = clusterHrefs.join(',');

  transmissionFilters.forEach((transmissionFilter, i) => {
    if (transmissionFilter) {
      // Load Clusters/Groups is they have not already been requested.
      clusterHrefs.forEach(href => {
        const roleKey = `${href}x${trafficClasses[i]}`;

        if (
          !TrafficStore.isTrafficRequested(
            'roles',
            roleKey,
            scopedRequest === href ? scope : null,
            null,
            connectedRequest === href,
          ) ||
          type === 'rebuild'
        ) {
          if (mapType === 'app' || (TrafficStore.getAppGroupsType().length && clusterType[href] === 'appGroup')) {
            const query = {roles: true, app_group_keys: JSON.stringify([href])};

            if (clearTraffic) {
              actionCreators.clearTrafficOnNext(JSON.stringify(query));
            }

            requests.push(getTraffic({...query, traffic_class: trafficClasses[i]}, {type}));
          } else {
            const query = {roles: true, cluster_keys: JSON.stringify([href])};

            if (clearTraffic) {
              actionCreators.clearTrafficOnNext(JSON.stringify(query));
            }

            requests.push(getTraffic({...query, traffic_class: trafficClasses[i]}, {type}));
          }
        }
      });

      // Load Workloads is they have not already been requested.
      const rebuildOption = MapPageStore.getWorkloadLoadingOption();

      Object.values(data.expandedRoles).forEach(roleHref => {
        const workloadKey = `${roleHref}x${trafficClasses[i]}`;

        if (
          !TrafficStore.isTrafficRequested(
            'workloads',
            workloadKey,
            scopedRequest === roleHref.split('-')[0] ? scope : null,
          ) ||
          type === 'rebuild'
        ) {
          const query = {role_keys: JSON.stringify([roleHref])};

          if (clearTraffic) {
            actionCreators.clearTrafficOnNext(JSON.stringify(query));
          }

          requests.push(
            getTraffic({...query, traffic_class: trafficClasses[i]}, {type: rebuildOption ? type : 'rebuildStale'}),
          );
        }
      });
    }
  });

  return Promise.all(requests);
}

const retryTimeout = ms => new Promise(resolve => setTimeout(resolve, ms));

async function getTraffic(query, {type, route = MapPageStore.getMapRoute(), retryTime = 5000}) {
  if (!SessionStore.isIlluminationApiEnabled()) {
    return;
  }

  let rebuildOption = MapPageStore.getMapLoadingOption();
  const timeout = 100_000;
  const waitSinceLastRebuild = localStorage.getItem('wait_since_rebuild') || 30;

  let loadingType = type;

  if (
    (query === 'appGroup' && !TrafficStore.getAppGroupsType().length) ||
    (query === 'location' && (MapPageStore.getLocMapType() === 'none' || !SessionStore.isIlluminationMapEnabled()))
  ) {
    return;
  }

  if (loadingType !== 'retry' && rebuildOption === 'rebuildAlways') {
    loadingType = 'rebuild';
  }

  if (type === 'rebuildStale' && loadingType !== 'rebuild') {
    rebuildOption = 'rebuildStale';
    loadingType = null;
  }

  try {
    let response;

    if (loadingType === 'rebuild' || rebuildOption === 'rebuildAlways') {
      actionCreators.networkTrafficRebuildStart(route);
      // Store the time of the rebuild request
      sessionStorage.setItem(JSON.stringify(query), Date.now());

      if (query === 'location') {
        response = await RestApiUtils.locationSummary.getRebuild(timeout);
      } else if (query === 'appGroup') {
        response = await RestApiUtils.appGroupSummary.getRebuild(timeout);
      } else {
        response = await RestApiUtils.agentTraffic.getRebuild(query, timeout);
      }
    } else {
      actionCreators.networkTrafficIterateStart(route);

      if (query === 'location') {
        response = await RestApiUtils.locationSummary.getCache(rebuildOption !== 'rebuildOnDemand', timeout);
      } else if (query === 'appGroup') {
        response = await RestApiUtils.appGroupSummary.getCache(rebuildOption !== 'rebuildOnDemand', timeout);
      } else {
        response = await RestApiUtils.agentTraffic.getCache(rebuildOption !== 'rebuildOnDemand', query, timeout);
      }

      const rebuildTime = sessionStorage.getItem(JSON.stringify(query));
      const rebuildStale = !loadingType && response.body && rebuildOption === 'rebuildStale' && response.body.stale;

      // No cache hit or the updated time is before the rebuild request, try again
      if (isDataOld(response.body, query, loadingType, rebuildOption)) {
        // If we haven't recently asked for a cache rebuild do it now
        if (
          (!response.body && rebuildTime < Date.now() - waitSinceLastRebuild * 60 * 1000 && !loadingType) ||
          rebuildStale
        ) {
          actionCreators.networkTrafficIterateComplete(route);

          // Increase the timeout a bit each time up to 1 min
          return await getTraffic(query, {type: 'rebuild', route, retryType: Math.min(retryTime * 1.1, 60_000)});
        }

        if (!loadingType) {
          // Start the rebuild spinner for the retry polling
          actionCreators.networkTrafficRebuildStart(route);
        }

        // Wait some time and try again
        await retryTimeout(retryTime);

        // Waiting until after the timeout to avoid gaps in the spinner
        actionCreators.networkTrafficIterateComplete(route);

        return await getTraffic(query, {type: 'retry', route, retryTime: Math.min(retryTime * 1.1, 60_000)});
      }

      actionCreators.networkTrafficIterateComplete(route);
    }

    // Success, kill the spinner and return
    if (loadingType) {
      actionCreators.networkTrafficRebuildComplete(route);
    }

    sessionStorage.removeItem(JSON.stringify(query));

    return response;
  } catch (error) {
    // Only retry if the error was a timeout
    if (error.message !== 'TIMEOUT') {
      console.error(error);

      if (loadingType !== 'rebuild') {
        actionCreators.networkTrafficIterateComplete(route);
      }

      return;
    }

    // Handle a timeout
    if (!loadingType) {
      // Start the rebuild spinner if we are beginning the polling
      actionCreators.networkTrafficRebuildStart(route);
    }

    // Wait some time and try again
    await retryTimeout(retryTime);

    if (loadingType !== 'rebuild') {
      // Waiting until after the timeout to avoid gaps in the spinner
      actionCreators.networkTrafficIterateComplete(route);
    }

    return getTraffic(query, {type: 'retry', route});
  }
}

function isDataOld(data, query, type, rebuildOption = MapPageStore.getMapLoadingOption()) {
  const rebuildQuery = query === 'location' || query === 'appGroup' ? query : {...query};
  const oldGraphOffset = localStorage.getItem('old_graph_offset') || 100_000;

  delete rebuildQuery.max_ports;
  delete rebuildQuery.max_ip_lists;

  const rebuildTime = sessionStorage.getItem(JSON.stringify(rebuildQuery));
  const rebuildStale = !type && rebuildOption === 'rebuildStale' && data && data.stale;

  // If the update time is more than the timeout value before the rebuild request consider it old
  return (
    !data ||
    data.version < TrafficStore.getCurrentTrafficVersion() ||
    rebuildStale ||
    (rebuildTime && rebuildTime - oldGraphOffset > new Date(data.updated_at).getTime())
  );
}

async function loadAllTrafficForFullMap(type, transmissionFilters, clearTraffic) {
  if (!SessionStore.isIlluminationApiEnabled()) {
    return;
  }

  const requests = [];

  TrafficStore.getAllClusterNodes().forEach(async cluster => {
    transmissionFilters.forEach((transmissionFilter, i) => {
      if (transmissionFilter) {
        if (
          !TrafficStore.isTrafficRequested('workloads', `${cluster.href}x${trafficClasses[i]}`) ||
          type === 'rebuild'
        ) {
          const query = {cluster_keys: JSON.stringify([cluster.href])};

          if (clearTraffic) {
            actionCreators.clearTrafficOnNext(JSON.stringify(query));
          }

          requests.push(getTraffic({...query, traffic_class: trafficClasses[i]}, {type}));
        }
      }
    });
  });

  return Promise.all(requests);
}

function loadRules(data) {
  const links = RenderUtils.getAllLinksForRules(
    data.clusters,
    data.links,
    data.clusterLinks,
    data.nodes,
    data.appSupergroupLinks,
    data.ruleCoverageTruncated,
  );

  iterateRuleCoverage(links);
}

function iterateRuleCoverage(nextLinks) {
  // Filter the links which have already been requested from the next set of links
  const requestedHrefsForRuleCoverage = TrafficStore.getRequestedHrefsForRuleCoverage();
  const newLinkHrefs = _.difference(nextLinks, requestedHrefsForRuleCoverage);

  // This returns all of these links, which have traffic data ready to send to avenger.
  const readyHrefsForRuleCoverage = TrafficStore.getReadyHrefsForRuleCoverage(newLinkHrefs);

  if (readyHrefsForRuleCoverage.length) {
    _.defer(() => {
      // This will iterate through the rule coverage links
      RestApiUtils.ruleCoverage.iterate(readyHrefsForRuleCoverage);
    });

    return true;
  }
}

async function loadCapsData(type, data) {
  switch (type) {
    case 'cluster':
      await iterateCapsClusters(data);
    case 'role':
      await iterateCapsNodes('roles', data);
    case 'workload':
      await iterateCapsNodes('workloads', data);
  }
}

// Convert Entity to Rule Coverage Format
function getRuleCoverageEntity(entity, labelBased) {
  switch (entity.type) {
    case intl('Common.IPList'):
      return {ip_list: {href: entity.href}};
    case intl('Common.Workloads'):
      if (labelBased && entity.labels && entity.labels.length > 0) {
        return {labels: entity.labels.map(label => ({href: label.href}))};
      }

      if (!entity.href) {
        return null;
      }

      if (entity.href.includes('container')) {
        return {container_workload: {href: entity.href}};
      }

      return {workload: {href: entity.href}};
    case intl('Common.VirtualServices'):
      return labelBased && entity.labels && entity.labels.length > 0
        ? {labels: entity.labels.map(label => ({href: label.href}))}
        : {virtual_service: {href: entity.href}};
    case intl('Common.VirtualServers'):
      return labelBased && entity.labels && entity.labels.length > 0
        ? {labels: entity.labels.map(label => ({href: label.href}))}
        : {virtual_server: {href: entity.href}};
  }

  const anyList = IpListStore.getAnyIpList();

  // For entities which are not mapped to any object, try the any IP List first
  if (anyList) {
    return {ip_list: {href: anyList.href}};
  }

  return {actors: 'all'};
}

function getLabelKey(labels) {
  return labels
    .map(label => label.href.split('/').pop())
    .sort((a, b) => a - b)
    .join(',');
}

function getRuleCoverageForLink(links, labelBased, force) {
  // We have to walk through the links twice. The first time to map any windows services to a key of:
  // direction, src ip, dst ip, port, protocol, process
  // Sometimes the VEN doesn't return the Windows service name for every flow, so this will find
  // matching flows reported by the same VEN, and use the service name reported in the other flow.
  // This is a bit of a hack - but it is more correct than always showing the flows as blocked because
  // they don't match the rule which has the Windows service name specified.
  const windowsServiceMap = {};

  links.forEach(link => {
    const windowsServiceKey =
      link.dst_os === 'windows'
        ? [link.flow_direction, link.src_ip, link.dst_ip, link.port, link.protocol, link.processName].join(',')
        : null;

    // Save the mapping between the other flow elements and an existing Windows service
    if (windowsServiceKey && link.windowsService && link.windowsService !== 'unknown') {
      windowsServiceMap[windowsServiceKey] = link.windowsService;
    }
  });

  return (
    links.reduce((result, row) => {
      if (row.rules && (labelBased || row.ruleType === 'workloads') && (!force || row.network?.name === 'External')) {
        return result;
      }

      let rows = [row];
      // Add the any IP List to any set of IP Lists
      const anyList = IpListStore.getAnyIpList();

      if (row.src_ip_lists) {
        rows = row.src_ip_lists.map(list => ({...row, src_ip_list: list, src_href: list.href}));

        if (anyList) {
          rows = [...rows, {...row, src_ip_list: anyList, src_href: anyList.href}];
        }
      } else if (row.dst_ip_lists) {
        rows = row.dst_ip_lists.map(list => ({...row, dst_ip_list: list, dst_href: list.href}));

        if (anyList) {
          rows = [...rows, {...row, dst_ip_list: anyList, dst_href: anyList.href}];
        }
      }

      rows.forEach(link => {
        const source = getRuleCoverageEntity(
          {
            type: link.src_type,
            href: link.src_href,
            labels: link.src_labels,
          },
          labelBased,
        );

        const destination = getRuleCoverageEntity(
          {
            type: link.dst_type,
            href: link.dst_href,
            labels: link.dst_labels,
          },
          labelBased,
        );

        if (source && destination) {
          // Incorporate endpoint type into the href so virtual services and workloads are sent separately
          const resolveSourceAs =
            link.src_type === intl('Common.VirtualServices') ? ['virtual_services'] : ['workloads'];
          const resolveTargetAs =
            link.dst_type === intl('Common.VirtualServices') ? ['virtual_services'] : ['workloads'];

          const linkHref = [
            labelBased && link.src_labels && link.src_labels.length > 0 ? getLabelKey(link.src_labels) : link.src_href,
            resolveSourceAs,
            labelBased && link.dst_labels && link.dst_labels.length > 0 ? getLabelKey(link.dst_labels) : link.dst_href,
            resolveTargetAs,
            link.network.href,
          ].join(',');

          if (!result[linkHref]) {
            result[linkHref] = {
              indicies: [],
              trafficsForRuleCoverage: {
                source,
                destination,
                services: [],
                resolve_labels_as: {
                  source: resolveSourceAs,
                  destination: resolveTargetAs,
                },
              },
            };
          }

          if (link.network.href) {
            result[linkHref].trafficsForRuleCoverage.network = {href: link.network.href};
          }

          const windowsServiceKey =
            link.dst_os === 'windows'
              ? [link.flow_direction, link.src_ip, link.dst_ip, link.port, link.protocol, link.processName].join(',')
              : null;

          // Determine if this service is already in the query
          let serviceIndex = result[linkHref].trafficsForRuleCoverage.services.findIndex(
            service =>
              service.port === link.port &&
              service.protocol === ServiceUtils.reverseLookupProtocol(link.protocol) &&
              service.process_name ===
                (link.processName !== 'unknown' && link.processName ? link.processName : undefined) &&
              service.windows_service_name === windowsServiceMap[windowsServiceKey],
          );

          // If not already available add it to the list
          if (serviceIndex === -1) {
            let osType;

            if (link.flow_direction === 'outbound') {
              osType = link.src_os === 'linux' ? 'linux' : 'windows_outbound';
            } else {
              osType = link.dst_os === 'linux' ? 'linux' : 'windows';
            }

            serviceIndex = result[linkHref].trafficsForRuleCoverage.services.length;

            const newService = {
              port: link.port,
              protocol: link.protocolNum,
              os_type: osType,
            };

            if (link.processName && link.processName !== 'unknown') {
              newService.process_name = link.processName;
            }

            newService.windows_service_name = windowsServiceMap[windowsServiceKey];

            if (!ServiceUtils.isPortValid(link.protocolNum)) {
              delete newService.port;
            }

            result[linkHref].trafficsForRuleCoverage.services.push(newService);
          }

          // Add all the traffic indicies for this service
          if (result[linkHref].indicies[serviceIndex]) {
            result[linkHref].indicies[serviceIndex].push(link.index);
          } else {
            result[linkHref].indicies[serviceIndex] = [link.index];
          }
        }
      });

      return result;
    }, {}) || {}
  );
}

function expandLinks(tableLinks) {
  return tableLinks.reduce((result, tableLink) => {
    result = [...result, ...tableLink.links];

    return result;
  }, []);
}

async function handleDraftCoverage(tableLinks, ruleType, href, force) {
  const labelBased = ruleType === 'labels';
  const limit = pLimit(4);
  const openRequests = [];
  let links = tableLinks.length && tableLinks[0].src_type === 'aggregated' ? expandLinks(tableLinks) : tableLinks;

  links = Object.values(getRuleCoverageForLink(links, labelBased, force));

  _.chunk(links, 100).forEach(nextLinks => {
    openRequests.push(
      limit(() =>
        RestApiUtils.ruleCoverage.get(
          nextLinks.map(link => link.trafficsForRuleCoverage),
          nextLinks.map(link => link.indicies),
          'explorer',
          href,
          'draft',
          true,
        ),
      ),
    );
  });

  await Promise.all(openRequests);
}

function loadVulnerabilityTraffic(data) {
  const nextRoles = RenderUtils.getAllRoles(data.clusters, data.nodes);
  const nextWorkloads = RenderUtils.getAllNodes(data.clusters, data.nodes, 'vulnerability');
  const nextAppGroups = RenderUtils.getAllClusters(data.clusters, data.nodes);

  iterateVulnerabilityNodes('roles', nextRoles);
  iterateVulnerabilityNodes('workloads', nextWorkloads);
  iterateVulnerabilityNodes('appGroups', nextAppGroups);
}

function labelForCaps(labels) {
  const LabelsForCaps = {};

  LabelsForCaps.labels = [];

  if (labels) {
    (Array.isArray(labels) ? labels : Object.values(labels)).forEach(label => {
      if (label && label.href) {
        LabelsForCaps.labels.push({href: label.href});
      }
    });
  }

  return LabelsForCaps;
}

async function iterateCapsClusters(clustersForCaps) {
  const clusters = TrafficStore.getUnrequestedClustersForCaps(clustersForCaps);
  const clusterApiCalls = [];

  if (clusters.length) {
    clusters.forEach(cluster => {
      const clusterDetails = GraphStore.getCluster(cluster);

      if (clusterDetails) {
        const clusterLabels = labelForCaps(clusterDetails.labels);
        const clusterType = MapPageStore.getMapType() === 'app' ? 'appGroup' : 'cluster';

        clusterApiCalls.push(RestApiUtils.workloads.verify_caps(clusterLabels, clusterType, clusterDetails));
      }
    });
  }

  await Promise.all(clusterApiCalls);
}

async function iterateCapsNodes(type, nodesForCaps) {
  // Find what are the new nodes which are rendered. Preserve the old nodes as they are.
  const nodes = TrafficStore.getUnrequestedNodesForCaps(nodesForCaps);
  const nodeApiCalls = [];

  // Get the node from the node href.
  if (nodes.length) {
    nodes.forEach(node => {
      //Find the caps for the parent cluster, workload/node
      //Current Node Item and Labels to calculate Caps.
      const nodeDetails = TrafficStore.getNode(node);

      if (nodeDetails) {
        const nodeLabels = labelForCaps(nodeDetails.labels);

        nodeApiCalls.push(RestApiUtils.workloads.verify_caps(nodeLabels, type, nodeDetails));
      }
    });
  }

  await Promise.all(nodeApiCalls);
}

function iterateVulnerabilityNodes(type, nodesForVulnerabilities) {
  if (MapPageStore.getAppMapVersion() === 'vulnerability') {
    // Filter any workload which is already loaded
    const nodes = TrafficStore.getUnrequestedNodesForVulnerabilities(nodesForVulnerabilities);

    if (nodes.length) {
      _.defer(() => {
        RestApiUtils.vulnerabilityInstances.iterate(type, nodes);
      });
    }
  }
}
function labelsForAppGroupsCaps(labels) {
  const LabelsForAppGroups = {labels: []};

  Object.keys(labels).forEach(label => {
    if (labels[label].href) {
      LabelsForAppGroups.labels.push({href: labels[label].href});
    }
  });

  return LabelsForAppGroups;
}

async function loadWorkloadCount() {
  const labels = JSON.stringify([
    [`${getSessionUri(getInstanceUri('labels'), {label_id: ''}).slice(0, -1)}?key=loc&exists=false`],
  ]);
  const res = await RestApiUtils.workloads.getCollection({max_results: 1, labels}, true);
  const totalWorkloads = Number(res.headers.get('x-total-count'));
  const noLocationWorkloads = Number(res.headers.get('x-matched-count'));

  return {totalWorkloads, noLocationWorkloads};
}

export default {
  getMapLevelByTotalWorkloads,
  isSummaryDataLoaded,
  loadTraffic,
  loadLevelTraffic,
  loadWorkloadCount,
  getTraffic,
  isTrafficLoaded,
  iterateRuleCoverage,
  loadRules,
  isDataOld,
  labelsForAppGroupsCaps,
  loadCapsData,
  getRuleCoverageEntity,
  handleDraftCoverage,
};
