/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {getSessionUri, getInstanceUri} from '../lib/api';
import RenderUtils from './RenderUtils';
import RulesetStore from '../stores/RulesetStore';
import WorkloadStore from '../stores/WorkloadStore';
import LabelGroupStore from '../stores/LabelGroupStore';

export const getAllScopesFromRuleset = ruleset =>
  (ruleset?.scopes || [[]]).reduce((result, scope) => {
    if (scope.every(item => !item.exclusion)) {
      result.push(scope.map(item => (item.label || item.label_group).href));
    } else {
      result.push([]);
    }

    return result;
  }, []);

export const getScopeByRulesetId = rulesetId => {
  const href = getSessionUri(getInstanceUri('rule_sets'), {
    rule_set_id: rulesetId,
    pversion: 'draft',
  });
  const ruleset = RulesetStore.getSpecified(href);

  return getAllScopesFromRuleset(ruleset);
};

export const getAllScopesAndNodesFromRule = (rulesetId, rule) => {
  let scopes = [];
  const nodes = [];
  const rulesetScopes = getScopeByRulesetId(rulesetId);

  let pbubAnything = false; // is pb/ub anything or ams?

  _.union(rule?.providers || [], rule?.consumers || []).forEach(pbub => {
    if ((pbub.actors && pbub.actors === 'all') || pbub.actors === 'ams') {
      // if either side is Anything or All Workloads
      // then first make note of that, and then replace existing scopes
      // with the rulesetScopes, since we're going to have to re-calculate
      // all the links for all the scopes in ruleset
      pbubAnything = true;
      scopes = rulesetScopes;
    } else if ((pbub.label || pbub.label_group) && !pbubAnything) {
      // only add in label scope if the other side isn't "Anything" or "AMS", to add:
      // 1. go through each of the ruleset's scopes
      // 2. if the pb label (role, most likely) is already in the ruleset's scopes,
      //    then just return that scope
      // 3. if it's not yet in there, DON'T mutate the original ruleset scope,
      //    but instead return a new copy with the label added in
      // 4. then add those updated scopes into the previous scopes we've remembered
      // The goal here is to limit the scope down to those workloads that share all 4 labels (app/env/loc/role)
      // so as little subset of the workloads get updated as possible when reloading the rule graph.
      let labels = [];

      if (pbub.label) {
        labels = [pbub.label.href];
      } else {
        labels = _.map(LabelGroupStore.getChildren(pbub.label_group.href), 'href');
      }

      _.each(labels, label => {
        scopes = _.union(
          scopes,
          _.map(rulesetScopes, scope => {
            if (scope.includes(label)) {
              return scope;
            }

            scope = _.clone(scope);
            scope.push(label);

            return scope;
          }),
        );
      });
    } else if (!pbub.label && !pbub.iplist) {
      // so for any workloads, bps, vs, etc. add it to nodes array
      _.each(pbub, node => {
        nodes.push(node.href);
      });
    }
  });

  return {scopes, nodes};
};

export const getScopesAndNodesByRuleId = (rulesetId, ruleId) => {
  const rulesetHref = getSessionUri(getInstanceUri('rule_sets'), {
    rule_set_id: rulesetId,
    pversion: 'draft',
  });
  const ruleHref = getSessionUri(getInstanceUri('sec_rules'), {
    rule_set_id: rulesetId,
    sec_rule_id: ruleId,
    pversion: 'draft',
  });
  const ruleset = RulesetStore.getSpecified(rulesetHref);

  if (!ruleset) {
    return [[]];
  }

  const rule = _.find(ruleset.rules, rule => rule.href === ruleHref);

  return getAllScopesAndNodesFromRule(rulesetId, rule);
};

export const getScopeByRulesetHref = rulesetHref => {
  const ruleset = RulesetStore.getSpecified(rulesetHref);

  return getAllScopesFromRuleset(ruleset);
};

export const getScopesFromWorkload = workload => (workload ? [RenderUtils.getLabels(workload.labels)] : []);

export const getScopesFromWorkloads = workloads => {
  const workloadScopes = _.transform(
    workloads,
    (result, workload) => {
      const scopes = getScopesFromWorkload(workload)[0]; // [{app, env, loc}]
      const hrefs = _.sortBy(scopes, 'href')
        .map(scope => scope.href)
        .join(','); // 'app,env,loc'

      if (!result[hrefs]) {
        result[hrefs] = scopes;
      }
    },
    {},
  );

  return _.values(workloadScopes);
};

export const getScopeByWorkloadId = (workloadId, nodes) => {
  const href = getSessionUri(getInstanceUri('workloads'), {
    workload_id: workloadId,
  });
  const workload = WorkloadStore.getSpecified(href) || nodes[href] || _.find(nodes, {href});

  return getScopesFromWorkload(workload);
};

export const getScopeByWorkloadHref = (workloadHref, nodes) => {
  const workload = WorkloadStore.getSpecified(workloadHref) || nodes[workloadHref];

  return getScopesFromWorkload(workload);
};

export const mergeScopes = (scopes, newScopes) => {
  if (_.isEmpty(scopes) && _.isEmpty(newScopes)) {
    scopes.push(newScopes);

    return;
  }

  _.each(newScopes, newScope => {
    const inScopes = _.some(scopes, scope => _.isEqual(scope, newScope));

    if (!inScopes) {
      scopes.push(newScope);
    }
  });
};

export const onlyRoleChange = scopes => {
  const firstScope = {...scopes[0]};

  firstScope.role = null;

  return !scopes.some(scope => {
    const nextScope = {...scope};

    nextScope.role = null;

    return !_.isEqual(firstScope, nextScope);
  });
};

export const isNodeInScopes = (node, scopes) => {
  if (!node) {
    return false;
  }

  if (_.isEmpty(scopes) && _.isEmpty(node.labels)) {
    // for the discovered cluster case
    return true;
  }

  const nodeLabelHrefs = _.map(node.labels, 'href');

  // if the workload's labels matches at least one of the scopes, remove it from loadedWorkloads
  return _.some(scopes, scope =>
    // every label in the scope must be in the workload's set of labels
    _.every(scope, labelHref => nodeLabelHrefs.includes(labelHref)),
  );
};

export const isRequestedRoleInScopes = (node, scopes) => {
  // The roles are often loaded for a larger scope than the map route
  // to use the pre-built graphs so those requested nodes need to include larger scopes
  if (!node) {
    return false;
  }

  if (_.isEmpty(scopes) && _.isEmpty(node.labels)) {
    // for the discovered cluster case
    return true;
  }

  const nodeLabelHrefs = _.map(node.labels, 'href');

  // if the workload's labels matches at least one of the scopes, remove it from loadedWorkloads
  return _.some(scopes, scope =>
    // every label in the scope must be in the workload's set of labels
    _.every(
      nodeLabelHrefs,
      labelHref => (Array.isArray(scope) || typeof scope === 'string') && scope.includes(labelHref),
    ),
  );
};

export default {
  getAllScopesFromRuleset,
  getScopeByRulesetId,
  getAllScopesAndNodesFromRule,
  getScopesAndNodesByRuleId,
  getScopeByRulesetHref,
  getScopesFromWorkloads,
  getScopesFromWorkload,
  getScopeByWorkloadId,
  getScopeByWorkloadHref,
  mergeScopes,
  onlyRoleChange,
  isNodeInScopes,
  isRequestedRoleInScopes,
};
