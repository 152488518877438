/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import Spinner from './Spinner';
import React, {PropTypes} from 'react';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-icon';

export default React.createClass({
  propTypes: {
    position: PropTypes.oneOf(['before', 'after', 'inbetween']),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'xxlarge', 'huge']),
    name: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    styleClass: PropTypes.string,
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    noTitle: PropTypes.bool,
    labelGroup: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      onClick: _.noop,
      noTitle: false,
    };
  },

  getIcon(name) {
    //if you are going to use a icon in a new context, consider adding a new key for that icon in case it needs a unique icon later on
    switch (name) {
      case 'add':
      case 'zoom-in':
        return '\uE600';
      case 'boundary-right':
        return '\uE722';
      case 'boundary-left':
        return '\uE724';
      case 'across-boundary-right':
        return '\uE723';
      case 'across-boundary-left':
        return '\uE725';
      case 'remove':
      case 'zoom-out':
        return '\uE601';
      case 'remove-row':
      case 'close':
        return '\uE602';
      case 'clear':
        return '\uE658';
      case 'back':
      case 'caret-left':
        return '\uE603';
      case 'next':
      case 'caret-right':
        return '\uE604';
      case 'caret-up':
        return '\uE605';
      case 'down':
      case 'dropdown':
      case 'caret-down':
        return '\uE606';
      case 'comment':
        return '\uE607';
      case 'container-workload':
        return '\uE730';
      case 'capslock':
        return '\uE608';
      case 'decrease':
        return '\uE718';
      case 'delete':
        return '\uE609';
      case 'duplicate':
        return '\uE610';
      case 'export':
      case 'download':
        return '\uE611';
      case 'import':
      case 'upload':
        return '\uE612';
      case 'edit':
        return '\uE613';
      case 'email':
        return '\uE614';
      case 'help':
        return '\uE615';
      case 'env':
        return '\uE616';
      case 'key':
        return '\uE617';
      case 'select':
      case 'version':
        return '\uE618';
      case 'illuminate':
      case 'illuminated':
        return '\uE619';
      case 'internet':
        return '\uE620';
      case 'ip-address':
      case 'info':
        return '\uE621';
      case 'iplist':
      case 'ipList':
        return '\uE622';
      case 'allow-list':
        return '\uE622';
      case 'deny-list':
        return '\uE623';
      case 'loc':
      case 'global':
      case 'domain':
      case 'fqdn':
      case 'map':
        return '\uE624';
      case 'menu':
        return '\uE625';
      case 'name':
        return '\uE626';
      case 'note':
        return '\uE607';
      case 'group':
      case 'org':
        return '\uE627';
      case 'user':
        return '\uE628';
      case 'pause':
        return '\uE629';
      case 'play':
        return '\uE630';
      case 'provision':
        return '\uE631';
      case 'refresh':
        return '\uE692';
      case 'revert':
        return '\uE632';
      case 'role':
        return '\uE633';
      case 'expand-role':
        return '\uE690';
      case 'collapse-role':
        return '\uE691';
      case 'collapse':
        return '\uE691';
      case 'save':
        return '\uE634';
      case 'search':
        return '\uE635';
      case 'settings':
        return '\uE636';
      case 'applied':
      case 'success':
      case 'allowed':
      case 'in-use':
        return '\uE637';
      case 'increase':
        return '\uE717';
      case 'timezone':
        return '\uE638';
      case 'warning':
      case 'p-blocked':
        return '\uE639';
      case 'critical':
        return '\uE640';
      case 'arrow-left':
        return '\uE641';
      case 'arrow-right':
        return '\uE642';
      case 'filter':
        return '\uE643';
      case 'add-user':
        return '\uE644';
      case 'app':
        return '\uE645';
      case 'server':
        return '\uE646';
      case 'video':
        return '\uE647';
      case 'zoom-to-fit':
        return '\uE648';
      case 'deny':
      case 'cancel':
        return '\uE649';
      case 'check':
      case 'confirm':
        return '\uE650';
      case 'secure-connect':
        return '\uE728';
      case 'lock':
      case 'password':
        return '\uE651';
      case 'virtualService':
        return '\uE652'; // Deprecated, use 'virtual-service'
      case 'location':
        return '\uE653';
      case 'sort-up':
        return '\uE654';
      case 'sort-down':
        return '\uE655';
      case 'enforcement':
      case 'enforce':
      case 'enforced':
        return '\uE656';
      case 'unmanaged':
        return '\uE657';
      case 'error':
        return '\uE719';
      case 'blocked':
        return '\uE658';
      case 'service-running':
      case 'service-not-running':
      case 'unicast':
      case 'online':
      case 'offline':
        return '\uE659';
      case 'syncing':
        return '\uE660';
      case 'list':
        return '\uE661';
      // '': return '\ue662';
      case 'workload':
        return '\uE663';
      case 'third-party':
        return '\uE669';
      case 'pending':
        return '\uE670';
      case 'expand':
        return '\uE671';
      case 'help-menu':
        return '\uE673';
      case 'external-link':
        return '\uE674';
      case 'pop-out':
        return '\uE675';
      case 'pop-in':
        return '\uE676';
      case 'local':
        return '\uE677';
      case 'grab-handle':
        return '\uE678';
      case 'service':
        return '\uE652';
      case 'ip-list':
        return '\uE622';
      case 'user-group':
        return '\uE627';
      case 'all-workloads':
        return '\uE664';
      case 'multiple-select':
        return '\uE664';
      case 'virtual-server':
        return '\uE667';
      case 'pce-health':
        return '\uE679';
      case 'suspended':
        return '\uE680';
      case 'staged':
      case 'pending-update':
        return '\uE681';
      case 'reverse':
        return '\uE682';
      case 'calendar':
        return '\uE683';
      case 'scope':
        return '\uE685';
      case 'overflow':
        return '\uE686';
      case 'machine-auth':
        return '\uE687';
      case 'pin':
        return '\uE688';
      case 'time-count':
        return '\uE689';
      case 'selection-tool':
        return '\uE695';
      case 'move-tool':
        return '\uE696';
      case 'pair':
        return '\uE693';
      case 'unpair':
        return '\uE694';
      case 'broadcast':
        return '\uE698';
      case 'multicast':
        return '\uE699';
      case 'unlock':
        return '\uE701';
      case 'copy':
        return '\uE702';
      case 'navigateup':
        return '\uE703';
      case 'virtual-service':
        return '\uE704';
      case 'stop':
        return '\uE705';
      case 'enabled':
        return '\uE706';
      case 'disabled':
        return '\uE707';
      case 'disabled-status':
        return '\uE729';
      case 'stopped':
        return '\uE708';
      case 'dashboard':
        return '\uE709';
      case 'database':
        return '\uE726';
      case 'arrow-up':
        return '\uE710';
      case 'arrow-down':
        return '\uE711';
      case 'arrow-left-long':
        return '\uE712';
      case 'arrow-right-long':
        return '\uE713';
      case 'link':
        return '\uE714';
      case 'unlink':
        return '\uE715';
      case 'admin':
        return '\uE716';
      case 'flip-horizontal':
        return '\uE720';
      case 'non-brn':
        return '\uE727';
      default:
        return '';
    }
  },

  getIconHelpText(iconName) {
    if (!iconName) {
      return '';
    }

    switch (iconName) {
      case 'loading':
        return intl('Common.Loading');
      case 'add':
        return intl('Common.Add');
      case 'remove':
        return intl('Common.Remove');
      case 'blocked':
        return intl('Common.Blocked');
      case 'p-blocked':
        return intl('Common.PotentiallyBlocked');
      case 'allowed':
        return intl('Common.Allowed');
      case 'zoom-in':
        return intl('Common.ZoomIn');
      case 'zoom-out':
        return intl('Common.ZoomOut');
      case 'close':
        return intl('Common.Close');
      case 'back':
        return intl('Common.Back');
      case 'caret-left':
        return intl('Common.Back');
      case 'next':
      case 'caret-right':
        return intl('Common.Next');
      case 'caret-up':
        return intl('Common.Up');
      case 'dropdown':
      case 'caret-down':
        return intl('Common.Down');
      case 'comment':
        return intl('Common.Comment');
      case 'capslock':
        return intl('Common.Capslock');
      case 'delete':
        return intl('Common.Delete');
      case 'duplicate':
        return intl('Common.Duplicate');
      case 'export':
        return intl('Common.Export');
      case 'download':
        return intl('Common.Download');
      case 'import':
        return intl('Common.Import');
      case 'upload':
        return intl('Common.Upload');
      case 'edit':
        return intl('Common.Edit');
      case 'email':
        return intl('Common.Email');
      case 'help':
        return intl('Common.Help');
      case 'env':
        return intl('Labels.Environment');
      case 'key':
        return intl('PairingProfiles.KeyGen');
      case 'version':
        return intl('Common.Version');
      case 'illuminate':
      case 'illuminated':
        return intl('Common.Illumination');
      case 'internet':
        return intl('Common.Internet');
      case 'ip-address':
        return intl('Common.IPAddress');
      case 'info':
        return intl('Common.Information');
      case 'loc':
        return intl('Common.Location');
      case 'map':
        return intl('Map.Illumination');
      case 'menu':
        return intl('Menu.Navigation');
      case 'name':
        return intl('Common.Name');
      case 'org':
        return intl('Common.Organization');
      case 'user':
        return intl('Users.User');
      case 'pause':
        return intl('Common.Pause');
      case 'play':
        return intl('Common.Play');
      case 'provision':
        return intl('Common.Provision');
      case 'refresh':
        return intl('Common.Refresh');
      case 'revert':
        return intl('Common.Undo');
      case 'role':
        return intl('Role.Label');
      case 'expand-role':
        return intl('Role.Expand');
      case 'collapse-role':
        return intl('Role.Collapse');
      case 'collapse':
        return intl('Common.Collapse');
      case 'save':
        return intl('Common.Save');
      case 'search':
        return intl('Common.Search');
      case 'settings':
        return intl('Common.Settings');
      case 'success':
      case 'check':
        return intl('Common.Success');
      case 'stop':
        return intl('Common.Stop');
      case 'in-use':
        return intl('Common.InUse');
      case 'timezone':
        return intl('Common.Timezone');
      case 'warning':
        return intl('Common.Warning');
      case 'critical':
        return intl('Common.Critical');
      case 'arrow-left':
        return intl('Common.ArrowLeft');
      case 'arrow-right':
        return intl('Common.ArrowRight');
      case 'arrow-up':
        return intl('Common.ArrowUp');
      case 'arrow-down':
        return intl('Common.ArrowDown');
      case 'arrow-left-long':
        return intl('Common.ArrowLeftLong');
      case 'arrow-right-long':
        return intl('Common.ArrowRightLong');
      case 'filter':
        return intl('Common.Filter');
      case 'add-user':
        return intl('Users.Add');
      case 'app':
        return intl('Labels.Application');
      case 'server':
        return intl('Common.Server');
      case 'video':
        return intl('Common.Video');
      case 'zoom-to-fit':
        return intl('Common.ZoomToFit');
      case 'cancel':
        return intl('Common.Cancel');
      case 'confirm':
        return intl('Common.Confirm');
      case 'secure-connect':
        return intl('Common.SecureConnect');
      case 'password':
        return intl('Common.Password');
      case 'bps':
        return intl('Common.VirtualService');
      case 'legend':
        return intl('Map.LegendPanel.Legend');
      case 'sort-up':
      case 'sort-down':
        return intl('Common.Sort');
      case 'enforced':
        return intl('Common.Enforced');
      case 'unmanaged':
        return intl('Common.Unmanaged');
      case 'error':
        return intl('Common.Error');
      case 'service-running':
        return intl('Common.ServiceRunning');
      case 'service-not-running':
        return intl('Common.ServiceNotRunning');
      case 'online':
        return intl('Common.Online');
      case 'offline':
        return intl('Workloads.Status.Offline');
      case 'syncing':
        return intl('Workloads.Status.Syncing');
      case 'list':
        return intl('Common.BackToList');
      case 'workload':
        return intl('Common.Workload');
      case 'third-party':
        return intl('Common.ThirdParty');
      case 'pending':
        return intl('Common.Pending');
      case 'expand':
        return intl('Common.Expand');
      case 'multicast':
        return intl('Map.Traffic.Multicast');
      case 'broadcast':
        return intl('Map.Traffic.Broadcast');
      case 'unicast':
        return intl('Map.Traffic.Unicast');
      case 'copy':
        return intl('Common.Copy');
      case 'unlock':
        return intl('Common.Unlock');
      case 'navigateup':
        return intl('Common.NavigateUp');
      case 'pce-health':
        return intl('Health.PCEHealth');
      case 'enabled':
        return intl('Common.Enabled');
      case 'disabled':
        return intl('Common.Disabled');
      case 'deny':
        return intl('Common.Deny');
      case 'stopped':
        return intl('Common.Stopped');
      case 'dashboard':
        return intl('Common.Dashboard');
      case 'link':
        return intl('Common.Link');
      case 'unlink':
        return intl('Common.Unlink');
      case 'admin':
        return intl('Role.Admin');
      case 'flip-horizontal':
        return intl('Common.FlipHorizontal');
      case 'machine-auth':
        return intl('Common.MachineAuthentication');
      case 'non-brn':
        return intl('Rulesets.Rules.NonCorporateNetworks');
      default:
        return _.capitalize(iconName.replaceAll('-', ' '));
    }
  },

  render() {
    const {name, size, position, noTitle, styleClass, customClass, helpText, onClick, labelGroup} = this.props;

    // if user passes noTitle to Icon, 'false' will render, but null doesn't
    const title = (!noTitle && (helpText || this.getIconHelpText(name))) || null;
    const tid = ComponentUtils.tidString(ComponentUtils.tid(defaultTid, [this.props.tid, name]));

    if (name === 'loading') {
      return <Spinner color="light" size={size} position={position} title={title} data-tid={tid} />;
    }

    let cn = 'Icon';

    if (styleClass) {
      cn += ' Icon-' + styleClass;
    }

    if (position) {
      cn += ' Icon--' + position;
    }

    if (size) {
      cn += ' Icon--' + size;
    }

    if (customClass) {
      cn += ` ${customClass}`;
    }

    if (__ANTMAN__ && /^(role|app|env|location)$/.test(name)) {
      cn += ' Icon-RAEL';
    }

    return (
      <i onClick={onClick} className={cn} title={title} data-tid={tid}>
        {__ANTMAN__ && /^(role|app|env|location)$/.test(name)
          ? `${name.charAt(0).toUpperCase()}${labelGroup ? 'G' : ''}`
          : this.getIcon(name)}
      </i>
    );
  },
});
