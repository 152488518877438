/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import MapPageStore from '../stores/MapPageStore';
import TrafficStore from '../stores/TrafficStore';
import RenderUtils, {collator} from './RenderUtils';
import InfoPanelUtils from './InfoPanelUtils';
import actionCreators from '../actions/actionCreators';
import {getRulesetWithLabels} from './ActionBarUtils';
import GraphStore from '../stores/GraphStore';
import {getId} from './GeneralUtils';
import SessionStore from '../stores/SessionStore';

export function calculateBackgroundActions(data) {
  const actions = {};
  const Maplevel = MapPageStore.getMapLevel();

  let resetAction = {};

  if (Maplevel === 'location' || Maplevel === 'full') {
    resetAction = {
      text: intl('Common.ResetLayout'),
      actionType: 'resetLayout',
    };
  }

  actions.data = data;
  actions.primaryAction = [];
  actions.secondaryAction = [];
  actions.tertiaryAction = [];

  actions.primaryAction = [
    {
      text: intl('Common.ZoomToFit'),
      actionType: 'zoomToFit',
    },
    resetAction,
  ];

  return actions;
}

export function calculateRoleActions(data) {
  const actions = {};

  actions.primaryAction = [];
  actions.secondaryAction = [];
  actions.tertiaryAction = [];
  actions.data = data;

  const maxExpand = localStorage.getItem('role_expand') || 200;

  if (data.workloadsNum > maxExpand) {
    actions.primaryAction = [
      {
        text: intl('Workloads.View'),
        actionType: 'workloadListAction',
        workloadListAction: {
          labels: _.map(data.labels, label => _.clone(label)),
        },
      },
    ];
  } else {
    actions.primaryAction = [
      {
        text: intl('Role.Expand'),
        actionType: 'expandRoleAction',
        expandRoleAction: {
          data: [data.href],
        },
      },
      {
        text: intl('Common.Explorer'),
        actionType: 'viewExplorer',
      },
    ];
  }

  return actions;
}

export function calculateNodeActions(data) {
  const actions = {};

  actions.primaryAction = [];
  actions.secondaryAction = [];
  actions.tertiaryAction = [];
  actions.data = data;

  const mapLevel = MapPageStore.getMapLevel();

  if (mapLevel === 'full') {
    actions.primaryAction = [
      {
        text: intl('Common.Explorer'),
        actionType: 'viewExplorer',
        data,
      },
    ];
  } else {
    actions.primaryAction = [
      {
        text: intl('Role.Collapse'),
        actionType: 'collapseRoleAction',
        collapseRoleAction: {
          data: [data.roleParent],
        },
      },
      {
        text: intl('Common.Explorer'),
        actionType: 'viewExplorer',
        data,
      },
    ];
  }

  return actions;
}

export function calculateLocationActions(data, policyVersion, isUserReadOnly) {
  const actions = {};

  actions.primaryAction = [];
  actions.secondaryAction = [];
  actions.tertiaryAction = [];
  actions.data = data;

  let addRule = {};
  let viewEnforcementBoundaries = [];

  if (!isUserReadOnly) {
    const rules = TrafficStore.getRingFenceRules(getId(data.href));

    addRule =
      rules && rules.allow && rules.allow.length
        ? {
            text: intl('Rule.View'),
            actionType: 'viewRule',
          }
        : {
            text: intl('Rule.Add'),
            actionType: 'addRule',
          };
    viewEnforcementBoundaries =
      SessionStore.canUserViewEnforcementBoundaries() &&
      rules &&
      (!rules.allow || !rules.allow.length) &&
      rules.deny &&
      rules.deny.length
        ? [
            {
              text: intl('Workloads.ViewEnforcementBoundaries'),
              actionType: 'viewEnforcementBoundaries',
              icon: 'caret-right',
            },
          ]
        : [];
  }

  if (!actions.data.focused) {
    actions.primaryAction = [
      {
        text: intl('Rulesets.View'),
        actionType: 'viewRulesets',
      },
      {
        text: intl('Workloads.View'),
        actionType: 'viewWorkloads',
      },
    ];
  } else {
    if (policyVersion === 'draft') {
      actions.primaryAction = [
        addRule,
        {
          text: intl('Map.SearchGroups'),
          actionType: 'searchGroups',
          data,
        },
      ];
      actions.secondaryAction = [
        {
          text: intl('Common.Workloads'),
          actionType: 'viewWorkloads',
        },
        ...viewEnforcementBoundaries,
      ];
    } else {
      actions.primaryAction = [
        {
          text: intl('Map.SearchGroups'),
          actionType: 'searchGroups',
        },
      ];
      actions.secondaryAction = [
        {
          text: intl('Common.Rulesets'),
          actionType: 'viewRulesets',
        },
        {
          text: intl('Common.Workloads'),
          actionType: 'viewWorkloads',
        },
      ];
    }

    actions.tertiaryAction = [
      {
        text: intl('Common.ZoomToFit'),
        actionType: 'zoomToFit',
      },
      {
        text: intl('Common.ResetLayout'),
        actionType: 'resetLayout',
        data,
      },
    ];
  }

  return actions;
}

export function calculateClusterActions(data, policyVersion, mapType, isUserReadOnly) {
  const actions = {};
  const mapLevel = MapPageStore.getMapLevel();
  const isVulnerabilityMap = MapPageStore.getAppMapVersion() === 'vulnerability';
  const consumingAndProviding = InfoPanelUtils.calculateConnectedAppGroupCounts(data);

  // Workload Readbility.
  const clusterIsReadable = data.caps.workloads.includes('read');

  // Rulesets Readbility.
  const clusterIsRulesetReadable = data.caps.rulesets.includes('read');

  actions.primaryAction = [];
  actions.secondaryAction = [];
  actions.tertiaryAction = [];
  actions.data = data;

  let primaryAddRule = {};
  let secondaryViewPolicy = [];
  let secondaryViewEnforcementBoundary = [];
  let secondaryNonDiscovered = [];
  let mitigateVulnerability = {};
  let consumingAppGroupAction = {};
  let providingAppGroupAction = {};
  let viewVirtualServices = {};
  let viewContainerWorkloads = {};

  if (policyVersion === 'draft') {
    actions.ringFencingRulesets = getRulesetWithLabels(_.map(data.labels, 'href'));
    actions.ringFencingRules = TrafficStore.getRingFenceRules(
      Object.values(data.labels)
        .map(label => getId(label.href))
        .sort((a, b) => collator.compare(a, b))
        .join('x'),
    );
  }

  if (data.virtualServicesNum) {
    viewVirtualServices = {
      text: intl('VirtualServices.View'),
      actionType: 'viewVirtualServices',
    };
  }

  if (data.containerWorkloadsNum) {
    viewContainerWorkloads = {
      text: intl('ContainerWorkloads.View'),
      actionType: 'viewContainerWorkloads',
    };
  }

  if (data.displayType === 'full' && policyVersion === 'draft') {
    if (actions.ringFencingRules && actions.ringFencingRules.allow.length) {
      // if there are rules already, show them
      primaryAddRule = {
        text: intl('Rule.View'),
        actionType: 'viewRule',
      };
    } else if (
      actions.ringFencingRules &&
      actions.ringFencingRules.deny.length &&
      SessionStore.canUserViewEnforcementBoundaries()
    ) {
      secondaryViewEnforcementBoundary = [
        {
          text: intl('Workloads.ViewEnforcementBoundaries'),
          actionType: 'viewEnforcementBoundaries',
          icon: 'caret-right',
        },
      ];
    } else if (!isUserReadOnly) {
      // if not, only allow adding rules if user isn't read only for all
      primaryAddRule = {
        text: intl('Rule.Add'),
        actionType: 'addRule',
      };
    }
  }

  if (data.appGroupParent && mapType === 'loc') {
    secondaryViewPolicy = [
      {
        text: intl('Common.AppGroupMap'),
        actionType: 'viewPolicyMap',
      },
    ];
  }

  if (!data.discovered) {
    secondaryNonDiscovered = [
      {
        text: intl('Common.Rulesets'),
        actionType: 'viewRulesets',
      },
      {
        text: intl('Common.Explorer'),
        actionType: 'viewExplorer',
      },
      {
        text: intl('PolicyGenerator.PolicyGenerator'),
        actionType: 'viewPolicyGenerator',
      },
    ];
  }

  if (isVulnerabilityMap) {
    mitigateVulnerability = {
      text: intl('Vulnerability.MitigateVulnerabilities'),
      actionType: 'mitigateVulnerability',
    };
  }

  if (consumingAndProviding.consuming) {
    consumingAppGroupAction = {
      text: intl('Map.ConsumingAppGroups'),
      actionType: 'viewConsumingAppGroups',
    };
  }

  if (consumingAndProviding.providing) {
    providingAppGroupAction = {
      text: intl('Map.ProvidingAppGroups'),
      actionType: 'viewProvidingAppGroups',
    };
  }

  if (mapType === 'loc') {
    let viewConnectedGroup = {};

    if (mapLevel !== 'full') {
      data.connectedClusters = {};
      Object.values(GraphStore.getClusters()).forEach(cluster => {
        data.connectedClusters[cluster.href] = TrafficStore.getNode(cluster.href);
      });

      delete data.connectedClusters[data.href];
      data.connectedClusterNum = Object.keys(data.connectedClusters).length;

      viewConnectedGroup = data.href !== 'discovered' &&
        data.connectedClusterNum && {
          text: intl('Map.ViewConnectedGroups'),
          actionType: 'viewConnectedGroups',
        };
    }

    if (!_.isEmpty(primaryAddRule) && !data.discovered) {
      actions.primaryAction.push(primaryAddRule);
    }

    if (!_.isEmpty(viewConnectedGroup)) {
      actions.primaryAction.push(viewConnectedGroup);
    }
  } else if (mapType === 'app' && data.connectionType === 'focused') {
    if (!_.isEmpty(primaryAddRule) && clusterIsRulesetReadable) {
      actions.primaryAction.push(primaryAddRule);
    }

    if (!_.isEmpty(mitigateVulnerability) && clusterIsRulesetReadable) {
      actions.primaryAction.push(mitigateVulnerability);
    }

    if (!_.isEmpty(consumingAppGroupAction)) {
      actions.primaryAction.push(consumingAppGroupAction);
    }

    if (!_.isEmpty(providingAppGroupAction)) {
      actions.primaryAction.push(providingAppGroupAction);
    }
  } else if (mapType === 'app' && (data.connectionType === 'consuming' || data.connectionType === 'providing')) {
    if (clusterIsRulesetReadable) {
      actions.primaryAction = [
        ...actions.primaryAction,
        {
          text: intl('Common.AppGroupMap'),
          actionType: 'viewPolicyMap',
        },
        {
          text: intl('Common.Explorer'),
          actionType: 'viewExplorer',
        },
      ];
    }
  }

  if (mapType === 'loc' || (mapType === 'app' && data.connectionType === 'focused')) {
    let viewWorkloads = {
      text: intl('Common.Workloads'),
      actionType: 'viewWorkloads',
    };

    // Only for App Group Maps focussed appgroup
    if (mapType === 'app') {
      // If the cluster has no capabilities, do not allow viewing workloads, containerWorkloads, virtual Services.
      if (!clusterIsReadable) {
        viewWorkloads = {};
        viewContainerWorkloads = {};
        viewVirtualServices = {};
      }

      // If cluster has read properties on rulesets allow view rulesets and view policy generator.
      if (!clusterIsRulesetReadable) {
        secondaryNonDiscovered = [];
      }
    }

    actions.secondaryAction = [
      viewWorkloads,
      ...secondaryViewPolicy,
      ...secondaryViewEnforcementBoundary,
      ...secondaryNonDiscovered,
      viewContainerWorkloads,
      viewVirtualServices,
    ];

    if (data.displayType === 'full' && mapType === 'loc') {
      actions.tertiaryAction = [
        {
          text: intl('Common.ResetLayout'),
          actionType: 'resetLayout',
          data,
        },
      ];
    } else if (mapType === 'app') {
      actions.tertiaryAction = [
        {
          text: intl('Common.ZoomToFit'),
          actionType: 'zoomToFit',
          data,
        },
        {
          text: intl('Common.ResetLayout'),
          actionType: 'resetLayout',
          data,
        },
      ];
    }
  }

  return actions;
}

export function calculateLinkActions(data, policyVersion, collapse, isUserReadOnly) {
  const actions = {};

  // Conditions for Rulesets to be readble on a Link.
  // The target or source endpoint must have the read capabilities.
  const rulesetIsReadable =
    (data.target.caps && data.target.caps.rulesets.includes('read')) ||
    (data.source.caps && data.source.caps.rulesets.includes('read'));
  const sourceHasAllLabels = RenderUtils.isInternetIpList(data.source) || RenderUtils.hasAllLabels(data.source);
  const targetHasAllLabels = RenderUtils.isInternetIpList(data.target) || RenderUtils.hasAllLabels(data.target);
  const isInterapp = RenderUtils.isInterappTraffic(data);

  const trafficDeny =
    data.filtered.blockedByBoundary ||
    data.filtered.potentiallyBlockedByBoundary ||
    data.filtered.allowedAcrossBoundary;

  actions.primaryAction = [];
  actions.secondaryAction = [];
  actions.tertiaryAction = [];
  actions.data = data;

  const addRule =
    (!isUserReadOnly && rulesetIsReadable && sourceHasAllLabels && targetHasAllLabels) || isInterapp
      ? {
          text: intl('Rule.Add'),
          actionType: 'addRule',
        }
      : {};
  const viewRule = rulesetIsReadable
    ? {
        text: intl('AppGroupCoverage.GoToAppGroupRules'),
        actionType: 'viewRules',
      }
    : {};

  const collapseOrExpand = collapse
    ? {
        text: intl('Map.CollapseTrafficLinks'),
        actionType: 'collapseTrafficLinks',
      }
    : {
        text: intl('Map.ExpandTrafficLinks'),
        actionType: 'expandTrafficLinks',
      };

  const viewEnforcementBoundaries = {
    text: intl('Workloads.ViewEnforcementBoundaries'),
    actionType: 'viewEnforcementBoundaries',
    icon: 'caret-right',
  };

  if (policyVersion === 'draft') {
    actions.primaryAction = [
      data.type === 'green' ? viewRule : data.type !== 'discovered' ? addRule : {},
      trafficDeny && rulesetIsReadable && SessionStore.canUserViewEnforcementBoundaries()
        ? viewEnforcementBoundaries
        : {},
      MapPageStore.getMapLevel() !== 'full' ? collapseOrExpand : {},
      {
        text: intl('Common.Explorer'),
        actionType: 'viewExplorer',
      },
    ];
  } else if (MapPageStore.getMapLevel() === 'full') {
    actions.primaryAction = [
      {
        text: intl('Common.Explorer'),
        actionType: 'viewExplorer',
      },
    ];
  } else {
    actions.primaryAction = [
      collapseOrExpand,
      {
        text: intl('Common.Explorer'),
        actionType: 'viewExplorer',
      },
    ];
  }

  return actions;
}

export function calculateLocationInfo(location) {
  //This code has been picked up from InfoPanelUtils to replicate Search Group Command Panel functionality.
  const info = {};

  // Data for the info panel
  info.href = location.href;
  info.name = location.name;
  info.groups = TrafficStore.getGroupSearch();
  info.locationGroups = _.transform(
    TrafficStore.getAllClusterNodes(),
    (result, cluster) => {
      const labels = RenderUtils.getLabels(cluster.labels);

      if (cluster.workloadCounts && labels.loc && labels.loc.href === location.href) {
        result.push({
          href: cluster.href,
          id: cluster.clusterId,
          workloadsNum: cluster.workloadCounts,
          labels: _.sortBy(cluster.labels, 'key').map(label => label.value),
          name: _.isEmpty(cluster.labels)
            ? cluster.href
            : _.sortBy(cluster.labels, 'key')
                .map(label => label.value)
                .join(' | '),
        });
      }
    },
    [],
  );

  info.workloadsNum = location.workloadsNum;
  info.locationLabel = {
    href: location.href,
    value: location.name,
    key: 'loc',
  };
  // shouldn't show discovered and no_location locations in location search panel in any level
  info.locations = _.transform(
    TrafficStore.getLocations(),
    (result, location, key) => {
      if (location.href !== 'discovered' && location.href !== 'no_location') {
        result[key] = location;
      }
    },
    {},
  );

  return info;
}

export function expandGroupLink(data, currentLevel, currentRoute) {
  let mapLevel;

  //Expand Source Group
  if (data.source.displayType === 'summary' && currentLevel === 'group') {
    mapLevel = {
      type: 'group',
      id: data.source.href,
      prevtype: currentRoute.type,
      previd: currentRoute.id,
    };
  }

  //Expand the Target Group.
  const focusedCluster = data.source.href;
  const expandedCluster = data.target.href;

  const expansionTarget = [focusedCluster, expandedCluster];

  if (mapLevel) {
    actionCreators.updateMapRoute(mapLevel);
  }

  if (expansionTarget) {
    actionCreators.expandCluster(expansionTarget);
  }
}

export function expandRoleLink(data) {
  if (data.source.type === 'role') {
    actionCreators.updateGraphCalculated();
    actionCreators.expandRole([data.source.href]);
  }

  if (data.target.type === 'role') {
    actionCreators.updateGraphCalculated();
    actionCreators.expandRole([data.target.href]);
  }
}

export default {
  calculateBackgroundActions,
  calculateLocationActions,
  calculateClusterActions,
  calculateLinkActions,
  calculateRoleActions,
  calculateNodeActions,
  calculateLocationInfo,
  expandGroupLink,
  expandRoleLink,
};
