/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import {State, Link} from 'react-router';
import intl from 'intl';
import Constants from '../../constants';
import {getId} from '../../utils/GeneralUtils';
import {ProposedRulesetSettings} from '../../modals';
import {RestApiUtils, RulesetUtils, PolicyGeneratorUtils, GeneralUtils, RenderUtils} from '../../utils';
import {SessionStore, VersionStore, RulesetStore, OrgStore, ExplorerFilterStore, TrafficStore} from '../../stores';
import {getSessionUri, getInstanceUri} from '../../lib/api';
import actionCreators from '../../actions/actionCreators';
import {SelectRuleset} from '../../components/CommandPanel/AddRule';
import {RulesetScopes, RulesetRules} from '../../components/RuleWriting';
import {Icon, Navbar, SpinnerOverlay, Button, AlertDialog, Notification, Banner} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {RouterMixin, StoreMixin, UserMixin, UnsavedChangesMixin, PolicyObjectAlertMixin} from '../../mixins';
import HTML5Backend from 'react-dnd-html5-backend';
import {DragDropContext} from 'react-dnd';
import GridRowDragLayer from '../../components/GridRowDragLayer';
import {ExplorerBoundaryPolicyUtils, ExplorerPolicyUtils} from '../../utils/Explorer';
import * as webStorageUtils from 'utils/webStorage';

let ignoreChanges;

const setIgnoreChanges = value => {
  ignoreChanges = value;
};

function getStateFromStore() {
  return Object.assign(this.getFromStore(), {
    status: [RulesetStore.getStatus(), ExplorerFilterStore.getStatus()],
  });
}

const RulesetScopesAndRules = React.createClass({
  mixins: [
    RouterMixin,
    UserMixin,
    PolicyObjectAlertMixin,
    State,
    StoreMixin([RulesetStore, VersionStore], getStateFromStore),
  ],

  getInitialState() {
    const lastRuleset = localStorage.getItem('last_explorer_ruleset');
    const scopedUser = SessionStore.isUserScoped();

    return {
      anyHref: '',
      ready: false,
      reorderMode: 'none',
      settings: (!scopedUser && JSON.parse(localStorage.getItem('boundary_settings'))) || {serviceType: 'port'},
      lastRuleset: lastRuleset ? {...JSON.parse(lastRuleset), isInitial: true} : null,
      scopedUser,
    };
  },

  componentWillMount() {
    if (SessionStore.isUserWithReducedScope() || SessionStore.isEdge()) {
      this.replaceWith('landing');
    }
  },

  componentDidMount() {
    if (SessionStore.isUserWithReducedScope()) {
      return;
    }

    ExplorerFilterStore.addDownloadActionListener(this.getFromStore);

    this.getRuleset();
    this.getHistory();

    RestApiUtils.labels.getCollection();

    if (this.getParams().pversion === 'draft') {
      RestApiUtils.services.getCollection({max_results: 500}, 'draft', true);
    }

    RestApiUtils.securityPrincipals.autocomplete(undefined, {max_results: 1}).then(result => {
      this.setState({enableUserGroups: Boolean(result.body.num_matches)});
    });
    RestApiUtils.ipLists
      .autocomplete(this.props.version, {
        query: intl('IPLists.Any'),
        max_results: 1,
      })
      .then(response => {
        if (response.body.matches.length) {
          this.setState({anyHref: response.body.matches[0].href});
        }
      });
  },

  componentDidUpdate() {
    if (this.getParams().id !== this.rulesetId || this.getParams().pversion !== this.rulesetVersion) {
      this.getRuleset();
      this.getHistory();
      this.setState({ready: false});
    }
  },

  componentWillUnmount() {
    ExplorerFilterStore.removeDownloadActionListener(this.getFromStore);
    // Reset changes flag on leave manually since it's a module's variable, not instance's
    ignoreChanges = undefined;

    if (this.queryPollingId) {
      clearTimeout(this.queryPollingId);
    }
  },

  getFromStore() {
    if (ExplorerPolicyUtils.getProposedRulesetFromPath(this.getPathname())) {
      return {};
    }

    let href;
    let diffHref;
    let currentActive;
    let currentDraft;

    if (this.rulesetVersion) {
      href = getSessionUri(getInstanceUri('rule_sets'), {
        pversion: this.rulesetVersion,
        rule_set_id: this.getParams().id,
      });
    }

    if (this.previousRulesetVersion) {
      diffHref = getSessionUri(getInstanceUri('rule_sets'), {
        pversion: this.previousRulesetVersion,
        rule_set_id: this.getParams().id,
      });
    }

    const ruleset = RulesetStore.getSpecified(href);
    let diffRuleset = RulesetStore.getSpecified(diffHref);

    if (this.getParams().pversion === 'active' && !diffRuleset) {
      diffRuleset = ruleset;
    }

    if (ruleset && ruleset.rules) {
      ruleset.rules = ruleset.rules.map(RulesetUtils.addUsageEntities);
    }

    if (diffRuleset && diffRuleset.rules) {
      diffRuleset.rules = diffRuleset.rules.map(RulesetUtils.addUsageEntities);
    }

    if (this.getParams().pversion !== 'active' && this.getParams().pversion !== 'draft') {
      const activeHref = getSessionUri(getInstanceUri('rule_sets'), {
        pversion: 'active',
        rule_set_id: this.getParams().id,
      });
      const draftHref = getSessionUri(getInstanceUri('rule_sets'), {
        pversion: 'draft',
        rule_set_id: this.getParams().id,
      });

      currentActive = RulesetStore.getSpecified(activeHref);
      currentDraft = RulesetStore.getSpecified(draftHref);
    }

    return {ruleset, diffRuleset, draftAndActive: {currentActive, currentDraft}};
  },

  getHistory() {
    if (
      this.getParams().pversion !== 'draft' &&
      this.getParams().pversion !== 'active' &&
      !ExplorerPolicyUtils.getProposedRulesetFromPath(this.getPathname())
    ) {
      RestApiUtils.secPolicies.getCollection();
      RestApiUtils.ruleSets.getInstance(this.rulesetId, 'active', true, true).catch(_.noop);
      RestApiUtils.ruleSets.getInstance(this.rulesetId, 'draft', true, true).catch(_.noop);
    }
  },

  async getRuleset() {
    const pathName = this.getPathname();
    const proposed = ExplorerPolicyUtils.getProposedRulesetFromPath(pathName);

    this.rulesetId = this.getParams().id;
    this.rulesetVersion = this.getParams().pversion;

    if (this.rulesetVersion === 'draft') {
      this.previousRulesetVersion = 'active';
    } else if (this.rulesetVersion !== 'active') {
      this.previousRulesetVersion = this.rulesetVersion - 1;
    }

    try {
      if (proposed) {
        this.setState({loading: true});

        const params = this.getParams();
        let data = {};

        if (ExplorerBoundaryPolicyUtils.getProposedBoundaryRulesetFromPath(pathName)) {
          data = await ExplorerBoundaryPolicyUtils.loadBoundaryPolicyData(params);
        } else {
          data = await ExplorerPolicyUtils.loadExplorerPolicyData(params);
        }

        if (!data) {
          this.handleCancelRuleset();
        }

        this.setState(data, () => {
          this.setState({...this.getFromStore(), loading: false});
        });
      } else {
        const response = await RestApiUtils.ruleSets.getInstance(this.rulesetId, this.rulesetVersion, true, true);

        // Don't load the other version ruleset (for diffing) if more than 500 rules.
        if (response.body.rules.length < 501 && this.previousRulesetVersion) {
          RestApiUtils.ruleSets.getInstance(this.rulesetId, this.previousRulesetVersion).catch(error => {
            // Swallow 404
            if (error.status !== 404) {
              throw error;
            }
          });
        }
      }
    } catch (error) {
      if (error.status === 404) {
        ignoreChanges = true;
        this.handleBack();

        return;
      }

      console.error(error);
    }

    if (this.isMounted()) {
      this.setState({ready: true});
    }
  },

  /**
   * Used to save the polling timer id
   */
  setQueryPollId(id) {
    this.queryPollingId = id;
  },

  //Used by react drag and drop to move rows in table
  moveRow(dragIndex, hoverIndex) {
    const {dragOrder} = this.state;
    const dragRow = dragOrder[dragIndex];

    dragOrder.splice(dragIndex, 1);
    dragOrder.splice(hoverIndex, 0, dragRow);

    dragOrder.forEach((rule, index) => {
      rule.index = index + 1;
    });

    this.setState({dragOrder});
  },

  handleEditCancel() {
    if (ExplorerPolicyUtils.getProposedRulesetFromPath(this.getPathname())) {
      const {savedRuleset} = this.state;
      const ruleset = (savedRuleset !== 'saved' && savedRuleset) || this.state.ruleset;

      this.setState({ruleset, savedRuleset: null});

      return;
    }

    this.setState({ruleset: RulesetStore.getSpecified(this.state.ruleset.href)});
  },

  //Creates a temporary copy of the intra, extra or custom ip table rules called dragOrder to use for tracking reordering
  handleStartReorder(type) {
    let dragOrder;

    if (type === 'customip') {
      const {ruleset, diffRuleset} = this.state;
      const tooManyRules = ruleset && ruleset.rules && ruleset.rules.length > 500;
      const previousRuleset = tooManyRules ? null : diffRuleset;
      const activeIpTableRules = previousRuleset && _.cloneDeep(previousRuleset.ip_tables_rules);

      dragOrder =
        ruleset && ruleset.ip_tables_rules
          ? RulesetUtils.getComparedIpTablesRules(ruleset.ip_tables_rules, activeIpTableRules)
          : [];
    } else {
      dragOrder = this.state.ruleset.rules.filter(rule =>
        type === 'intra' ? !rule.unscoped_consumers : rule.unscoped_consumers,
      );
    }

    dragOrder.forEach((rule, index) => {
      rule.index = index + 1;
    });
    this.setState({reorderMode: type, dragOrder, lastType: type});
  },

  //Updates the intra, extra or custom ip table rule order by saving dragOrder to the ruleset
  handleReorderSave(type) {
    if (type === 'customip') {
      const newIpTableRules = this.state.dragOrder.map(rule => ({href: rule.href}));

      RestApiUtils.ruleSet.update(this.rulesetId, {ip_tables_rules: newIpTableRules});
    } else {
      // intra and extra rules are stored in the same list, so when the new order is saved, this makes sure that the rules that were not reordered stay in place.
      const oldRules = this.state.ruleset.rules;
      const oldHrefs = oldRules.map(rule => rule.href);
      const typeHrefs = oldRules
        .filter(rule => (type === 'intra' ? !rule.unscoped_consumers : rule.unscoped_consumers))
        .map(rule => rule.href);
      const dragHrefs = this.state.dragOrder.map(rule => rule.href);
      const newHrefs = oldRules.map(rule => rule.href);

      oldHrefs.forEach(href => {
        if (dragHrefs.includes(href)) {
          newHrefs[oldHrefs.indexOf(typeHrefs[dragHrefs.indexOf(href)])] = href;
        }
      });

      const newRules = newHrefs.map(href => ({href}));

      RestApiUtils.ruleSet.update(this.rulesetId, {rules: newRules});
    }

    this.setState({reorderMode: 'none', dragOrder: [], lastType: type});
  },

  handleReorderCancel(type) {
    this.setState({reorderMode: 'none', dragOrder: [], lastType: type});
  },

  handleRuleChange(newRule, isCustomIp) {
    const {savedRuleset} = this.state;
    let savedUpdateInfo = {};
    let newSavedRuleset;

    // Avoid cloneDeep
    const rulesStr = isCustomIp ? 'ip_tables_rules' : 'rules';
    const rulesetRules = this.state.ruleset[rulesStr].slice();
    const ruleIndex = rulesetRules.findIndex(rule => rule.href === newRule.href);
    const proposed = ExplorerPolicyUtils.getProposedRulesetFromPath(this.getPathname());

    // The during the first change save the original rule
    // But don't save again after that
    if (!savedRuleset && proposed) {
      newSavedRuleset = {...this.state.ruleset};
    }

    // Mark the rules with update information
    if (rulesetRules[ruleIndex].proposedDelete) {
      savedUpdateInfo = {
        proposedDelete: true,
        update_type: 'delete',
        saved_update_type: rulesetRules[ruleIndex].saved_update_type,
      };
    } else if (proposed && !newRule.href.includes('proposed') && !_.isEqual(rulesetRules[ruleIndex], newRule)) {
      savedUpdateInfo = {
        proposedUpdate: true,
        update_type: 'update',
        saved_update_type: rulesetRules[ruleIndex].update_type,
      };
    }

    rulesetRules[rulesetRules.findIndex(rule => rule.href === newRule.href)] = {...newRule, ...savedUpdateInfo};
    // This is called after onSave, so removed the savedRuleset in that case
    this.setState({
      ruleset: {...this.state.ruleset, [rulesStr]: rulesetRules},
      savedRuleset: newSavedRuleset || (savedRuleset === 'saved' ? null : savedRuleset),
    });
  },

  handleRuleSave(note) {
    if (ExplorerPolicyUtils.getProposedRulesetFromPath(this.getPathname())) {
      // Find the overlapping ruleset after adding the original rules to the current rules
      const ruleset = ExplorerPolicyUtils.findOverlappingRules(this.state.ruleset);

      this.setState({ruleset, savedRuleset: note && 'saved'});
    }
  },

  handleRemoveRules(selection) {
    // This is only called for proposed rulesets
    const {ruleset} = this.state;
    const rules = ruleset.rules.map(rule => {
      if (selection.includes(rule.href)) {
        if (rule.href.includes('proposed')) {
          return {...rule, hidden: true, delete: true};
        }

        return {...rule, update_type: 'delete', proposedDelete: true};
      }

      return rule;
    });

    this.setState({ruleset: {...ruleset, rules}});
  },

  handleRuleSearchClick() {
    const labels = [];
    const labelGroups = [];

    this.state.ruleset.scopes.forEach(scope =>
      scope.forEach(label => {
        if (label.label) {
          labels.push(label.label);
        } else if (label.label_group) {
          labelGroups.push(label.label_group);
        }
      }),
    );
    actionCreators.setRuleSearchFilters({
      providers_or_consumers: {
        ...(labelGroups.length && {'Label Groups': labelGroups}),
        ...(labels.length && {Labels: labels}),
      },
    });
  },

  handleGoToPolicyGenerator() {
    const id = PolicyGeneratorUtils.getPolicyGeneratorId(this.state.ruleset.scopes[0]);

    if (id) {
      this.transitionTo('appGroupPolicyGenerator', {id});
    }
  },

  async handleEnableRuleset() {
    const id = getId(this.state.ruleset.href);

    await RestApiUtils.ruleSet.update(id, {enabled: true});

    const response = await RestApiUtils.ruleSets.getInstance(id, 'draft', true);

    await this.handleRulesetSelect(response.body);
  },

  handleGoToRevert() {
    this.transitionTo('pending');
  },

  handleGoToProvision() {
    const {ruleset} = this.state;
    const changeSubset = {};
    const objectType = ruleset.href.split('/').slice(-2)[0];
    const storedGeneralSelection = (webStorageUtils.getSessionItem('GeneralSelection') || []).filter(
      ({key}) => key !== 'provision',
    );

    changeSubset[objectType] = [{href: ruleset.href}];
    storedGeneralSelection.push({key: 'provision', value: {operation: 'commit', change_subset: changeSubset}});
    webStorageUtils.setSessionItem('GeneralSelection', storedGeneralSelection);

    this.transitionTo('provision');
  },

  handleBack() {
    const pathName = this.getPathname();

    if (ExplorerBoundaryPolicyUtils.getProposedBoundaryRulesetFromPath(pathName)) {
      this.transitionTo('boundaries.list');
    } else if (ExplorerPolicyUtils.getProposedAppGroupRulesetFromPath(pathName)) {
      this.transitionTo('appGroups');
    } else if (ExplorerPolicyUtils.getProposedRulesetFromPath(pathName)) {
      this.transitionTo('explorer');
    } else {
      this.transitionTo('rulesets.list');
    }
  },

  handleSettingsSave(settings) {
    const ruleset = ExplorerPolicyUtils.updateRuleSettings(this.state.ruleset, settings);

    localStorage.setItem('boundary_settings', JSON.stringify(settings));
    this.setState({settings, ruleset});
  },

  handleOpenSettings() {
    const {settings} = this.state;

    actionCreators.openDialog(<ProposedRulesetSettings settings={settings} onSave={this.handleSettingsSave} />);
  },

  async handleScopeChange(scopes) {
    const {ruleset} = this.state;

    await this.handleRulesetSelect({...ruleset, scopes});
  },

  handleCancelRuleset() {
    const pathName = this.getPathname();
    const params = this.getParams();

    if (ExplorerBoundaryPolicyUtils.getProposedBoundaryRulesetFromPath(pathName)) {
      this.replaceWith('boundariesEnforcementExplorer', params);
    } else if (ExplorerPolicyUtils.getProposedAppGroupRulesetFromPath(pathName)) {
      this.replaceWith('appGroupExplorer', params);
    } else if (ExplorerPolicyUtils.getProposedRulesetFromPath(pathName)) {
      this.replaceWith('explorer');
    } else {
      this.replaceWith('rulesets.list');
    }
  },

  async handleSaveProposedRuleset({provision}) {
    const {ruleset, boundaryRulesets} = this.state;
    const pathName = this.getPathname();
    const params = this.getParams();
    let href;

    ignoreChanges = true;
    this.setState({savingProposedRuleset: true});

    if (boundaryRulesets) {
      await ExplorerBoundaryPolicyUtils.removeBoundaryTags(boundaryRulesets);
    }

    try {
      href = await ExplorerPolicyUtils.saveRuleset(ruleset);
    } catch (error) {
      this.setState({savingProposedRuleset: false});

      if (
        error.status === 406 &&
        error.parsedBody[0].token !== 'rule_set_name_in_use' &&
        error.parsedBody[0].token !== 'external_reference_not_unique'
      ) {
        actionCreators.openDialog(
          <AlertDialog alert={true} title={intl('Common.ValidationError')} message={error.parsedBody[0].message} />,
        );
      }

      return;
    }

    localStorage.setItem('last_explorer_ruleset', JSON.stringify({...ruleset, href, rules: [], initial: true}));
    localStorage.removeItem('selectedLinks');
    localStorage.removeItem('explorer-page');

    _.defer(() => actionCreators.updateGeneralSelection('linkTable', {id: this.props.href, selection: []}));

    if (provision) {
      webStorageUtils.setSessionItem('GeneralSelection', [
        {key: 'provision', value: {operation: 'commit', change_subset: {rule_sets: [{href}]}}},
      ]);
      this.replaceWith('provision');
    } else if (ExplorerBoundaryPolicyUtils.getProposedBoundaryRulesetFromPath(pathName)) {
      this.replaceWith('boundariesEnforcementRuleSearch', params);
    } else if (ExplorerPolicyUtils.getProposedAppGroupRulesetFromPath(pathName)) {
      this.replaceWith('appGroupExplorer', params);
    } else if (ExplorerPolicyUtils.getProposedRulesetFromPath(pathName)) {
      this.replaceWith('explorer');
    }
  },

  async handleRulesetSelect(ruleset) {
    const selectedRuleset = ruleset && {
      ...ruleset,
      ...(ruleset.rules ? {rules: ruleset.rules.map(RulesetUtils.addUsageEntities)} : {}),
    };

    if (selectedRuleset?.name) {
      let {ruleset} = this.state;
      const existingProposedRules = ruleset?.rules.filter(rule => rule.href.includes('proposed'));
      const sameScope = ruleset && existingProposedRules.length && _.isEqual(selectedRuleset.scopes, ruleset.scopes);

      // Save the proposed edits when switching between same scoped ruleset
      ruleset = selectedRuleset.rules
        ? {
            ...selectedRuleset,
            rules: [
              ...(sameScope ? existingProposedRules : []),
              ...selectedRuleset.rules.filter(rule => !rule.href.includes('proposed')),
            ],
          }
        : {...selectedRuleset, rules: sameScope ? existingProposedRules : null};

      const selectedLinks = JSON.parse(localStorage.getItem('selectedLinks'));
      const serviceType = this.state?.settings?.serviceType || 'port';
      const params = this.getParams();
      let rulesetInfo = {};
      let links = [];

      // Add info for newly created ruleset
      if (!ruleset.href) {
        rulesetInfo = ExplorerPolicyUtils.getNewRulesetInfo();
      }

      if (this.state?.boundary) {
        const {boundary} = this.state;

        links = ExplorerBoundaryPolicyUtils.getLinks(params);

        if (!ruleset.href) {
          rulesetInfo = {
            ...rulesetInfo,
            ...ExplorerBoundaryPolicyUtils.getExternalDataForBoundaryRuleset(GeneralUtils.getId(boundary.href)),
          };
        }
      } else {
        if (ruleset && !ruleset.initial) {
          localStorage.setItem('last_explorer_ruleset', JSON.stringify({...ruleset, rules: [], initial: true}));
        }

        links = ExplorerPolicyUtils.getLinks(params);

        if (!ruleset.href) {
          const externalData = await ExplorerPolicyUtils.getExternalDataForRuleset(ruleset.scopes);

          rulesetInfo = {
            ...rulesetInfo,
            ...externalData,
          };
        }
      }

      const labelGroupAllLabels = await ExplorerPolicyUtils.getAllLabelGroupLabels(ruleset.scopes);

      if (!sameScope) {
        ruleset = ExplorerPolicyUtils.createRules({
          ruleset,
          links,
          selectedLinks,
          labelGroupAllLabels,
          serviceType,
        });
      }

      ruleset = ExplorerPolicyUtils.findOverlappingRules({...ruleset, ...rulesetInfo});

      this.setState({ruleset});

      if (ruleset.rules.some(rule => rule.outOfScope) && !ruleset.initial) {
        actionCreators.openDialog(<AlertDialog message={intl('Rulesets.SomeSelectedFlowsAreNotCoveredByRules')} />);
      }
    }
  },

  handleEdit(edit) {
    this.setState({edit});
  },

  render() {
    const {
      ruleset,
      diffRuleset,
      ready,
      status,
      anyHref,
      boundary,
      rulesetName,
      loading,
      savingProposedRuleset,
      edit,
      newScopes,
      boundaryRulesets,
      lastRuleset,
      scopedUser,
    } = this.state;

    const proposed = ExplorerPolicyUtils.getProposedRulesetFromPath(this.getPathname());
    const noRuleset = !ruleset || (!proposed && !ready);
    const tooManyRules = ruleset && ruleset.rules && ruleset.rules.length > 500;
    // Don't use the diffRuleset if more than 500 rules
    const previousRuleset = tooManyRules ? null : diffRuleset;
    let customRulesets;

    if (boundary) {
      customRulesets = boundaryRulesets;
    } else {
      customRulesets = lastRuleset ? [lastRuleset] : null;
    }

    const rulesetSelection = (
      <div className="Ruleset-Selection">
        <div className="Ruleset-Selection-Title">{intl('Rulesets.AddRulesTo')}</div>
        <SelectRuleset
          groupTypes={TrafficStore.getAppGroupsType()}
          selectedRuleset={ruleset || {}}
          onSelect={this.handleRulesetSelect}
          onEdit={this.handleEdit}
          labels={RenderUtils.getLabelObject(newScopes) || {}}
          editable={!edit}
          edit={edit === 'ruleset'}
          customRulesets={customRulesets}
          withScopes={scopedUser}
          allRulesets
        />
      </div>
    );

    if (proposed && !ruleset) {
      return newScopes ? rulesetSelection : null;
    }

    if ((noRuleset && !proposed) || !ruleset || loading) {
      return <SpinnerOverlay />;
    }

    const emptyCaps = _.isEmpty(ruleset.caps);
    const provOnly =
      ruleset.caps && ruleset.caps.length === 1 && ruleset.caps.includes('provision') && !this.isUserReadOnlyAll();
    const readOnly = emptyCaps || ruleset.update_type === 'delete' || provOnly || this.isUserReadOnlyOrProvisioner();
    const scopes = ruleset && RulesetUtils.getScopesWithLabelKeys(_.cloneDeep(ruleset.scopes));
    const activeScopes = previousRuleset && RulesetUtils.getScopesWithLabelKeys(_.cloneDeep(previousRuleset.scopes));
    const activeRules = previousRuleset && _.cloneDeep(previousRuleset.rules);
    const activeIpTableRules = previousRuleset && _.cloneDeep(previousRuleset.ip_tables_rules);
    const comparedScopes = scopes ? RulesetUtils.getComparedScopes(scopes, activeScopes) : [];
    let comparedRules = ruleset && ruleset.rules ? RulesetUtils.getComparedRules(ruleset.rules, activeRules) : [];
    const comparedIpTableRules =
      ruleset && ruleset.ip_tables_rules
        ? RulesetUtils.getComparedIpTablesRules(ruleset.ip_tables_rules, activeIpTableRules)
        : [];
    const title = ruleset ? rulesetName || ruleset.name : null;
    let label =
      !ready || (ruleset && ruleset.enabled) ? null : (
        <div>
          <Icon name="cancel" styleClass="Warning" position="before" />
          {intl('Rulesets.Disabled')}
        </div>
      );

    label ||= intl('Common.Rulesets');

    if (proposed) {
      label = boundary ? intl('Rulesets.EnforcementBoundaryRuleset') : intl('Rulesets.ProposedRuleset');
    }

    const ruleLabelTypeMap = {};

    comparedRules.forEach(rule => {
      if ((rule.update_type && rule.update_type === 'delete') || rule.href.includes('proposed')) {
        return;
      }

      _.forEach(rule.providers, entity => RulesetUtils.markLabels(entity, ruleLabelTypeMap));

      if (!rule.unscoped_consumers) {
        _.forEach(rule.consumers, entity => RulesetUtils.markLabels(entity, ruleLabelTypeMap));
      }
    });

    comparedIpTableRules.forEach(rule => {
      if (rule.update_type && rule.update_type === 'delete') {
        return;
      }

      _.forEach(rule.actors, entity => RulesetUtils.markLabels(entity, ruleLabelTypeMap));
    });

    const comparedExtraScopeRules = [];

    _.forEach(comparedRules, rule => {
      if (rule.unscoped_consumers) {
        comparedExtraScopeRules.push(rule);
      }
    });

    comparedRules = comparedRules.filter(rule => !rule.hidden && !rule.outOfScope);

    let hideRules = [];

    if (
      proposed &&
      !comparedRules.some(rule => !rule.unscoped_consumers && rule.href.includes('proposed')) &&
      comparedRules.some(rule => rule.unscoped_consumers && rule.href.includes('proposed'))
    ) {
      // If there are only extrascope proposed rules, hide the intra section
      hideRules = ['intra'];
    }

    return (
      <div className="RulesetScopesAndRules">
        {status.includes(Constants.STATUS_BUSY) || savingProposedRuleset || this.queryPollingId ? (
          <SpinnerOverlay />
        ) : null}
        <GridRowDragLayer />
        <Navbar title={title} label={label} type="detail" onUp={this.handleBack} />
        {this.state.reorderMode === 'none' && (
          <div>
            <div>
              {SessionStore.isUserScoped() && (
                <Notification
                  type="instruction"
                  message={intl(
                    'Rulesets.ScopedUserRuleset',
                    {
                      pageName: (
                        <Link
                          to="ruleSearch"
                          params={{pversion: 'draft'}}
                          onClick={this.handleRuleSearchClick}
                          className="RuleSearch-link"
                        >
                          {intl('Common.RuleSearch')}
                        </Link>
                      ),
                    },
                    {jsx: true},
                  )}
                />
              )}
              {proposed && (
                <div className="RulesetProposed-Header">
                  <Notification
                    type="instruction"
                    message={
                      boundary ? intl('Rulesets.NewProposedBoundaryRuleset') : intl('Rulesets.NewProposedRuleset')
                    }
                  />
                  <ToolBar>
                    <ToolGroup>
                      <Button
                        text={intl('Common.Save')}
                        icon="save"
                        disabled={
                          savingProposedRuleset ||
                          ruleset.update_type === 'delete' ||
                          !ruleset.enabled ||
                          !ruleset.rules?.length ||
                          this.state.savedRuleset
                        }
                        onClick={this.handleSaveProposedRuleset}
                        tid="save"
                      />
                      {!boundary && SessionStore.canUserManageAndProvisionRuleSets() && (
                        <Button
                          text={intl('Common.SaveAndProvision')}
                          disabled={
                            savingProposedRuleset ||
                            ruleset.update_type === 'delete' ||
                            !ruleset.enabled ||
                            !ruleset.rules?.length ||
                            this.state.savedRuleset
                          }
                          type="secondary"
                          onClick={_.partial(this.handleSaveProposedRuleset, {provision: true})}
                          tid="save-and-provision"
                        />
                      )}
                      <Button
                        text={intl('Common.Cancel')}
                        icon="cancel"
                        disabled={savingProposedRuleset}
                        type="secondary"
                        onClick={this.handleCancelRuleset}
                        tid="cancel"
                      />
                      {scopedUser ? null : (
                        <Button
                          text={intl('Common.Settings')}
                          icon="settings"
                          disabled={savingProposedRuleset}
                          type="secondary"
                          onClick={this.handleOpenSettings}
                          tic="settings"
                        />
                      )}
                    </ToolGroup>
                  </ToolBar>
                  {rulesetSelection}
                </div>
              )}
            </div>
            {!__ANTMAN__ && (
              <RulesetScopes
                ref="rulesetscopes"
                reorderMode={this.state.reorderMode}
                ruleset={ruleset}
                rulesetId={this.rulesetId}
                version={this.rulesetVersion}
                scopes={comparedScopes}
                readonly={
                  readOnly ||
                  (proposed && ruleset.href) ||
                  (!proposed &&
                    (ruleset.external_data_set === 'illumio_rule_builder' ||
                      ruleset.external_data_set === 'illumio_policy_generator'))
                }
                proposed={proposed}
                ruleLabelTypes={ruleLabelTypeMap}
                setIgnoreChanges={setIgnoreChanges}
                onScopeChange={this.handleScopeChange}
              />
            )}
          </div>
        )}
        {proposed && (ruleset.update_type === 'delete' || !ruleset.enabled) ? (
          <div className="Graph-no-data">
            {ruleset.update_type === 'delete' ? (
              <Banner
                type="action"
                header={<div className="Ruleset-banner-header">{intl('Rulesets.RulesetDeletedHeader')}</div>}
                message={<div className="Ruleset-banner-message">{intl('Rulesets.RulesetDeletedInstructions')}</div>}
                content={
                  <div className="Ruleset-banner-buttons">
                    <Button
                      autoFocus={true}
                      text={intl('Rulesets.ProvisionRuleset')}
                      disabled={!ruleset.caps.includes('provision') || SessionStore.isSuperclusterMember()}
                      onClick={this.handleGoToProvision}
                    />
                    <Button
                      type="secondary"
                      text={intl('Rulesets.RevertRuleset')}
                      disabled={!ruleset.caps.includes('provision') || SessionStore.isSuperclusterMember()}
                      onClick={this.handleGoToRevert}
                    />
                  </div>
                }
              />
            ) : (
              <Banner
                type="action"
                header={intl('Rulesets.RulesetDisabledHeader')}
                message={intl('Rulesets.RulesetDisabledInstructions')}
                content={
                  <div className="ARuleset-banner-buttons">
                    <Button autoFocus={true} text={intl('Rulesets.EnableRuleset')} onClick={this.handleEnableRuleset} />
                  </div>
                }
              />
            )}
          </div>
        ) : (
          <RulesetRules
            lastType={this.state.lastType}
            anyHref={anyHref}
            reorderEnable={!proposed}
            reorderMode={this.state.reorderMode}
            comparedRules={comparedRules}
            comparedIpTableRules={comparedIpTableRules}
            diffRuleset={previousRuleset}
            onEditCancel={this.handleEditCancel}
            onRuleChange={this.handleRuleChange}
            onRuleSave={this.handleRuleSave}
            onRemoveRules={this.handleRemoveRules}
            onStartReorder={this.handleStartReorder}
            onReorderSave={this.handleReorderSave}
            onReorderCancel={this.handleReorderCancel}
            readonly={readOnly}
            ruleset={{...ruleset, rules: ruleset.rules?.filter(rule => !rule.hidden)}}
            rulesetId={this.rulesetId}
            version={proposed ? 'draft' : this.rulesetVersion}
            setIgnoreChanges={setIgnoreChanges}
            tooManyRules={tooManyRules}
            moveRow={this.moveRow}
            dragOrder={this.state.dragOrder}
            providerConsumerOrder={OrgStore.providerConsumerOrder()}
            proposed={proposed}
            hideRules={hideRules}
          />
        )}
      </div>
    );
  },
});
const DnDRulesetScopesAndRules = DragDropContext(HTML5Backend)(RulesetScopesAndRules);

/**
 * DragDropContext returns a Higher Order component which doesn't copy the statics from the child component
 * and the willTransitionFrom function from UnsavedChangesMixin never gets fired
 *
 * So wrap the higher order component in another component, and attach the statics there
 */
export default React.createClass({
  mixins: [State, UnsavedChangesMixin],

  setIgnoreChanges,

  hasChanged() {
    return ignoreChanges !== undefined && !ignoreChanges;
  },

  render() {
    return <DnDRulesetScopesAndRules />;
  },
});
/* eslint-enable react/no-multi-comp */
