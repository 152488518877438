/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import update from 'react-addons-update';
import {createStore} from '../lib/store';
import {getSessionUri, getInstanceUri} from '../lib/api';
import dispatcher from '../actions/dispatcher';
import Constants from '../constants';
import MapPageStore from './MapPageStore';
import TrafficStore from './TrafficStore';
import FilterStore from './FilterStore';
import TrafficFilterStore from './TrafficFilterStore';
import MapSpinnerStore from './MapSpinnerStore';
import RenderUtils from '../utils/RenderUtils';
import GraphDataUtils from '../utils/GraphDataUtils';
import GraphRenderUtils from '../utils/GraphRenderUtils';
import GraphPositionUtils from '../utils/GraphPositionUtils';
import GraphFilterUtils from '../utils/GraphFilterUtils';
import GraphHoveringUtils from '../utils/GraphHoveringUtils';

const UPDATE_EVENT = 'update';

const positions = localStorage.getItem('positions') ? JSON.parse(localStorage.getItem('positions')) : {};

if (!positions.locations || _.isEmpty(positions.locations)) {
  positions.locations = {};
}

if (!positions.summaryClusters || _.isEmpty(positions.summaryClusters)) {
  positions.summaryClusters = {};
}

if (!positions.clusters || _.isEmpty(positions.clusters)) {
  positions.clusters = {};
}

if (!positions.nodes || _.isEmpty(positions.nodes)) {
  positions.nodes = {};
}

let emptyPositions = false;
let locationTruncation = true;

let prevClusters = positions.clusters || {};
let prevMapLevel;

let nodes = {};
let links = {};
let clusterLinks = {};
let clusters = {};
let locations = {};
let appSupergroupLinks = {};
// all the links showing on graph
// which is used to get correct info on traffic filter panel
let graphLinks = {};
let mapLevel;
let mapRoute;
let mapType;
let graphCalculated = false;
let graphCalculatedFirstTime = false;
let truncated = false;
let areFiltersApplied = false;
let groupTooLarge = false;
let ruleCoverageTruncated = false;
// linearAnimation is used to decide the animation of groups
// if true, the groups transite linearly
// otherwise, the groups trnasite following a path
let linearAnimation = true;

const selections = {
  loc: [],
  app: [],
};

const prevSelections = {
  loc: [],
  app: [],
};
let selectionType = null;
let selectionMap = {};
let hideMessage = {
  hideMoveToolAlert: false,
  hideSelectionToolAlert: false,
  hideResolveDomainsAlert: false,
  hideFullmapSelectionToolAlert: false,
  hideWarnUserModal: false,
};

let expandedClusterHrefs = [];
// expandedRoleHrefs remembers the roles the user has expanded
// while needExpandRoleHrefs remembers both user expanded and auto-expanded roles,
// so that we know the roles we need to fetch workloads for
let expandedRoleHrefs = [];
let needExpandRoleHrefs = {};

let recentAppGroups = JSON.parse(localStorage.getItem('recent_appgroups')) || [];

// getters
function getLocationByHref(href) {
  return locations[href];
}

function getClusterByHref(href) {
  return clusters[href];
}

function getClusterById(clusterId) {
  return _.find(clusters, cluster => cluster.clusterId === clusterId);
}

function getNodeByHref(href) {
  let node = nodes[href];

  if (node) {
    return node;
  }

  _.some(clusters, cluster => {
    node = _.find(cluster.nodes, node => node.href === href);

    if (node) {
      return true;
    }

    return false;
  });

  return node;
}

function getLinkByHref(href, identifier) {
  // First look through interapp links:
  let link = links[href];

  if (link) {
    return link;
  }

  // Second look through clusterLinks:
  const clusterLink = clusterLinks[href];

  if (clusterLink) {
    return clusterLink;
  }

  // Then see if the link in a cluster or unconnected node:
  _.some(clusters, cluster => {
    link = _.find(
      cluster.links,
      link =>
        link.href === href ||
        // if it's internet traffic, try matching identifier
        link.identifier === identifier,
    );

    if (link) {
      return true;
    }

    return false;
  });

  if (link) {
    return link;
  }

  _.some(nodes, node => {
    link = _.find(node.links, link => link.href === href || link.identifier === identifier);

    if (link) {
      return true;
    }

    return false;
  });

  return link;
}

// setters
function setGraphCalculatedFlag() {
  graphCalculated = false;
}

function setPosition(data) {
  if (!data) {
    return;
  }

  if (data.type === 'location') {
    // dragging locations
    // if the location is new, create a new object in positions
    // otherwise, update the variables in positions.locations
    if (_.isEmpty(positions.locations[data.href])) {
      positions.locations[data.href] = {
        href: data.href,
        x: data.x,
        y: data.y,
        r: data.r,
      };
    } else {
      positions.locations[data.href] = update(positions.locations[data.href], {
        $merge: {
          href: data.href,
          x: data.x,
          y: data.y,
          r: data.r,
        },
      });
    }
  } else if (data.type === 'group' && mapRoute.id && mapRoute.type === 'location') {
    if (_.isEmpty(positions.locations[data.locHref].clusters)) {
      // if the group is new to the location, then create a new object
      positions.locations[data.locHref].clusters = {};
    }

    // put the group under its corresponding location
    positions.locations[data.locHref].clusters[data.href] = {
      href: data.href,
      locHref: data.locHref,
      x: data.x,
      y: data.y,
    };
  } else if (data.type === 'group' && data.displayType === 'full') {
    if (_.isEmpty(positions.clusters[data.href])) {
      positions.clusters[data.href] = {
        href: data.href,
        x: data.x,
        y: data.y,
      };
    } else {
      positions.clusters[data.href] = update(positions.clusters[data.href], {
        $merge: {
          href: data.href,
          x: data.x,
          y: data.y,
        },
      });
    }
  } else if (data.type === 'group' && !mapRoute.id) {
    // for group view without location view
    if (_.isEmpty(positions.summaryClusters[data.href])) {
      positions.summaryClusters[data.href] = {
        href: data.href,
        x: data.x,
        y: data.y,
      };
    } else {
      positions.summaryClusters[data.href] = update(positions.summaryClusters[data.href], {
        $merge: {
          href: data.href,
          x: data.x,
          y: data.y,
        },
      });
    }
  } else if (data.cluster) {
    let cluster = positions.clusters[data.cluster.href];

    if (!cluster) {
      positions.clusters[data.cluster.href] = cluster = data.cluster;
    }

    if (!cluster.nodes) {
      cluster.nodes = {};
    }

    if (data.node) {
      // Then find the node that was changed
      const node = cluster.nodes[data.node.href];

      if (node) {
        node.x = data.node.x;
        node.y = data.node.y;
      } else {
        delete data.node.labels; // we don't need to put that in
        cluster.nodes[data.node.href] = data.node;
      }
    }
  }
}

function setSelections(data) {
  selections[mapType] = data;
}

function removeSelections() {
  selections[mapType] = [];
}

function setSelectionType(data) {
  selectionType = data;
}

function setExpandedClusters(data) {
  expandedClusterHrefs = data;

  if (mapType === 'loc') {
    localStorage.setItem('expandedClusters', JSON.stringify(expandedClusterHrefs));
  }
}

function removeExpandedCluster() {
  if (mapRoute && mapRoute.id) {
    expandedClusterHrefs = _.reject(expandedClusterHrefs, href => href !== mapRoute.id);
  }

  if (mapType === 'loc') {
    localStorage.setItem('expandedClusters', JSON.stringify(expandedClusterHrefs));
  }
}

function clearExpandedClusters() {
  if (mapRoute && mapRoute.id) {
    expandedClusterHrefs = [];
  }

  if (mapType === 'loc') {
    localStorage.removeItem('expandedClusters');
  }
}

function setExpandedRoles(data) {
  const maximumExpandedRoles = parseInt(localStorage.getItem('maximum_expanded_roles'), 10) || 3; // change based on traffic parameters

  if (expandedRoleHrefs.length >= maximumExpandedRoles) {
    expandedRoleHrefs = _.takeRight(expandedRoleHrefs, maximumExpandedRoles - 1);
  }

  expandedRoleHrefs = _.union(expandedRoleHrefs, data);

  // The groups should stay in place
  if (
    selections[mapType].length &&
    selections[mapType][0].type !== 'appGroup' &&
    selections[mapType][0].type !== 'group'
  ) {
    selections[mapType] = [];
  }
}

function removeExpandedRoles(data) {
  expandedRoleHrefs = _.difference(expandedRoleHrefs, data);

  // The groups should stay in place
  if (
    selections[mapType].length &&
    selections[mapType][0].type !== 'appGroup' &&
    selections[mapType][0].type !== 'group'
  ) {
    selections[mapType] = [];
  }
}

function nextAppGroup(appGroupType, connectedAppGroup, connectedGroups) {
  const connected = (connectedGroups || locations[appGroupType]?.connectedAppGroups)?.filter(
    appgroup => !appgroup.isHidden,
  );

  if (!connected || !connected.length) {
    return;
  }

  const index = _.findIndex(connected, appGroup => connectedAppGroup && appGroup.href === connectedAppGroup.href);
  const nextIndex = index + 1 === connected.length ? 0 : index + 1;

  return connected[nextIndex].href;
}

function prevAppGroup(appGroupType, connectedAppGroup, connectedGroups) {
  const connected = (connectedGroups || locations[appGroupType]?.connectedAppGroups)?.filter(
    appgroup => !appgroup.isHidden,
  );

  if (!connected || !connected.length) {
    return;
  }

  const index = _.findIndex(connected, appGroup => connectedAppGroup && appGroup.href === connectedAppGroup.href);
  const prevIndex = index <= 0 ? connected.length - 1 : index - 1;

  return connected[prevIndex].href;
}

function calculateAndPositionGraph() {
  if (!MapPageStore.isLoaded()) {
    return;
  }

  prevMapLevel = mapLevel;
  mapLevel = MapPageStore.getMapLevel();
  mapRoute = MapPageStore.getMapRoute();
  mapType = MapPageStore.getMapType();

  if (mapType === 'loc') {
    calculateAndPositionLocations();
  }

  if (mapType === 'app') {
    calculateAndPositionAppGroups();
  }
}

function calculateAndPositionAppGroups() {
  if (graphCalculatedFirstTime) {
    prevClusters = clusters;
  }

  emptyPositions = false;

  // Only continue if everything is loaded from the backend
  // expandedClusterHrefs are from mapRoute
  expandedClusterHrefs = mapLevel === 'connectedAppGroup' ? [mapRoute.id, mapRoute.previd] : [];

  if (!GraphDataUtils.isTrafficLoaded(expandedClusterHrefs)) {
    graphCalculated = false;

    return;
  }

  truncated = false;
  locations = {};
  appSupergroupLinks = {};
  clusterLinks = {};
  clusters = {};
  graphLinks = {};
  nodes = {};
  links = {};
  groupTooLarge = false;

  const trafficFilters = TrafficFilterStore.getAll();

  if (mapLevel === 'globalAppGroup') {
    graphCalculated = true;
  } else if (mapLevel === 'focusedAppGroup' || mapLevel === 'connectedAppGroup') {
    let focusedAppGroup;
    let connectedAppGroup;

    if (mapLevel === 'focusedAppGroup') {
      focusedAppGroup = TrafficStore.getAppGroupNode(mapRoute.id);

      if (!focusedAppGroup) {
        graphCalculated = true; // If the route doesn't exist, return empty things

        return;
      }

      if (focusedAppGroup.tooManyWorkloads) {
        graphCalculated = true;
        groupTooLarge = true;

        return;
      }

      clusters[focusedAppGroup.href] = GraphRenderUtils.getParsedAppGroup(focusedAppGroup, 'full', mapRoute.id);
    } else if (mapLevel === 'connectedAppGroup') {
      connectedAppGroup = TrafficStore.getAppGroupNode(mapRoute.id);
      focusedAppGroup = TrafficStore.getAppGroupNode(mapRoute.previd);

      if (focusedAppGroup) {
        clusters[focusedAppGroup.href] = GraphRenderUtils.getParsedAppGroup(focusedAppGroup, 'full', mapRoute.previd);
      }

      if (connectedAppGroup) {
        clusters[connectedAppGroup.href] = GraphRenderUtils.getParsedAppGroup(connectedAppGroup, 'full', mapRoute.id);
      }
    }

    // Calculate Roles and Workloads
    needExpandRoleHrefs = {};

    const roleThreshold = parseInt(localStorage.getItem('role_collapse'), 10) || 1;
    const scope = mapRoute.previd || mapRoute.id;
    const loadConnectedGroups = JSON.parse(sessionStorage.getItem('loadConnectedGroups')) || [];
    const isConnectedGroupExpanded =
      mapRoute.previd && loadConnectedGroups.includes([mapRoute.previd, mapRoute.id].join(','));

    TrafficStore.getAllRoleNodes().forEach(role => {
      let counts;

      // If this role is not in the focused group, and the connected group is not expanded, then get the counts with respect to this scope
      if (
        role.appGroupParent !== scope &&
        (!isConnectedGroupExpanded || !role.counts[role.appGroupParent]) &&
        role.counts[scope]
      ) {
        counts = role.counts[scope];
      }

      const entityCounts = counts ? counts.entityCounts : role.entityCounts;

      if (entityCounts && clusters[role.appGroupParent]) {
        if (entityCounts < roleThreshold || expandedRoleHrefs.includes(role.href)) {
          // if the role needs to be expanded, don't render and save it in needExpandRoleHrefs instead
          needExpandRoleHrefs[role.href] = role.href;
        } else {
          nodes[role.href] = {
            type: role.type,
            href: role.href,
            data: counts ? {...role, ...counts} : role,
          };
        }
      }
    });

    TrafficStore.getAllWorkloadNodes().forEach(workload => {
      if (needExpandRoleHrefs[workload.roleParent] && clusters[workload.appGroupParent]) {
        // only display the workloads that are in expanded roles
        nodes[workload.href] = {
          type: workload.type,
          href: workload.href,
          collapsible: expandedRoleHrefs.includes(workload.roleParent),
          data: workload,
        };
      }
    });

    GraphFilterUtils.filterNodesByPolicyState(nodes, trafficFilters);

    const allTraffics = [
      ...TrafficStore.getAllRoleTraffics(),
      ...TrafficStore.getAllWorkloadTraffics(),
      ...TrafficStore.getAllMixedRoleTraffics(),
    ];

    const isNodeInFocusedGroup = node =>
      node &&
      (mapRoute.previd
        ? ((node.data && node.data.appGroupParent) || node.appGroupParent) === mapRoute.previd
        : ((node.data && node.data.appGroupParent) || node.appGroupParent) === mapRoute.id);

    const isNodeInConnectedGroup = node =>
      mapRoute.previd && node && ((node.data && node.data.appGroupParent) || node.appGroupParent) === mapRoute.id;

    const isConsumingTraffic = traffic =>
      isNodeInConnectedGroup(nodes[traffic.source.href]) && isNodeInFocusedGroup(nodes[traffic.target.href]);

    const isProvidingTraffic = traffic =>
      isNodeInConnectedGroup(nodes[traffic.target.href]) && isNodeInFocusedGroup(nodes[traffic.source.href]);

    const isConsumingExpandedRole = traffic =>
      Object.keys(needExpandRoleHrefs).includes(traffic.source.href) &&
      isNodeInConnectedGroup(TrafficStore.getNode(traffic.source.href)) &&
      isNodeInFocusedGroup(TrafficStore.getNode(traffic.target.href));

    const isProvidingExpandedRole = traffic =>
      Object.keys(needExpandRoleHrefs).includes(traffic.target.href) &&
      isNodeInConnectedGroup(TrafficStore.getNode(traffic.target.href)) &&
      isNodeInFocusedGroup(TrafficStore.getNode(traffic.source.href));

    const isFocusedTraffic = traffic =>
      (isNodeInFocusedGroup(nodes[traffic.source.href]) &&
        (isNodeInFocusedGroup(nodes[traffic.target.href]) || RenderUtils.isInternetIpList(traffic.target))) ||
      (isNodeInFocusedGroup(nodes[traffic.target.href]) &&
        (isNodeInFocusedGroup(nodes[traffic.source.href]) || RenderUtils.isInternetIpList(traffic.source)));

    const isConnnectedTraffic = traffic =>
      (isNodeInConnectedGroup(nodes[traffic.source.href]) &&
        (isNodeInConnectedGroup(nodes[traffic.target.href]) || RenderUtils.isInternetIpList(traffic.target))) ||
      (isNodeInConnectedGroup(nodes[traffic.target.href]) &&
        (isNodeInConnectedGroup(nodes[traffic.source.href]) || RenderUtils.isInternetIpList(traffic.source)));

    const expandedRoleTraffic = [];

    allTraffics.forEach(traffic => {
      if (
        isFocusedTraffic(traffic) ||
        (isConnectedGroupExpanded && isConnnectedTraffic(traffic)) ||
        (mapRoute.type === 'consuming' && isConsumingTraffic(traffic)) ||
        (mapRoute.type === 'providing' && isProvidingTraffic(traffic))
      ) {
        if (nodes[traffic.source.href]) {
          nodes[traffic.source.href].traffic = true;
        }

        if (nodes[traffic.target.href]) {
          nodes[traffic.target.href].traffic = true;
        }

        links[traffic.href] = {
          href: traffic.href,
          connections: {...traffic.connections},
          data: traffic,
        };
      } else if (mapRoute.type === 'consuming' && isConsumingExpandedRole(traffic) && nodes[traffic.target.href]) {
        // If traffic is consuming role traffic which has been expanded add it to the expanded role traffic
        // Make sure the connected node in the focused group hasn't been filtered out
        expandedRoleTraffic.push(traffic.source.href);
      } else if (mapRoute.type === 'providing' && isProvidingExpandedRole(traffic) && nodes[traffic.source.href]) {
        // If traffic is providing role traffic which has been expanded add it to the expanded role traffic
        // Make sure the connected node in the focused group hasn't been filtered out
        expandedRoleTraffic.push(traffic.target.href);
      }
    });

    _.forOwn(nodes, node => {
      // Leave nodes in place if the role Parent had traffic to the focused group
      if (
        isNodeInConnectedGroup(node) &&
        !isConnectedGroupExpanded &&
        !node.traffic &&
        !expandedRoleTraffic.includes(node.data && node.data.roleParent)
      ) {
        delete nodes[node.href];
      }
    });

    GraphRenderUtils.parseNodesForRender(nodes);
    GraphRenderUtils.parseNodesLinksForRender(nodes, links);
    GraphFilterUtils.filterLinks(links, trafficFilters);
    GraphRenderUtils.fillNonDiscoveryClusters(nodes, links, clusters, 'full', mapRoute, mapLevel);

    const appGroupTraffic = TrafficStore.getAppGroupTrafficsForAppGroup(focusedAppGroup?.href);

    const filters = TrafficFilterStore.getAll();
    const truncated =
      ruleCoverageTruncated &&
      MapPageStore.getPolicyVersion() === 'draft' &&
      (!filters.allowBlockedTraffic || !filters.allowAllowedTraffic);

    // Filter appGroup Traffic links
    GraphRenderUtils.parseNodesLinksForRender([], appGroupTraffic);
    // If rule coverage is truncated, show app groups before the rule coverage returns
    GraphFilterUtils.filterLinks(appGroupTraffic, trafficFilters, truncated);

    const connectedAppGroups = Object.values(appGroupTraffic).reduce(
      (result, traffic) => {
        const sourceHref = traffic.data.source.href;
        const targetHref = traffic.data.target.href;
        const hideTraffic = traffic.data.source.isFiltered || traffic.data.target.isFiltered;

        // set appGroup traffic isHidden to true if the app group has isFiltered === true
        if (sourceHref === focusedAppGroup?.href) {
          result.providing.push({...traffic.data.target, isHidden: traffic.isHidden || hideTraffic});
          result.providingTraffic.push(traffic);
        } else if (targetHref === focusedAppGroup?.href) {
          result.consuming.push({...traffic.data.source, isHidden: traffic.isHidden || hideTraffic});
          result.consumingTraffic.push(traffic);
        }

        return result;
      },
      {consuming: [], providing: [], consumingTraffic: [], providingTraffic: []},
    );

    const hiddenPolicyStates = TrafficFilterStore.getHiddenPolicyStates();

    GraphFilterUtils.filterClustersByPolicyState(connectedAppGroups.consuming, hiddenPolicyStates);
    GraphFilterUtils.filterClustersByPolicyState(connectedAppGroups.providing, hiddenPolicyStates);

    focusedAppGroup = {
      ...focusedAppGroup,
      ...connectedAppGroups,
      ruleCoverageTruncated: truncated,
    };

    const consumingNext = nextAppGroup('consuming', connectedAppGroup, focusedAppGroup.consuming);
    const consumingPrev = prevAppGroup('consuming', connectedAppGroup, focusedAppGroup.consuming);
    const providingNext = nextAppGroup('providing', connectedAppGroup, focusedAppGroup.providing);
    const providingPrev = prevAppGroup('providing', connectedAppGroup, focusedAppGroup.providing);

    if (focusedAppGroup && mapLevel === 'focusedAppGroup') {
      if (!_.isEmpty(focusedAppGroup.consuming)) {
        locations.consuming = GraphRenderUtils.getParsedAppSupergroup(
          focusedAppGroup,
          'consuming',
          null,
          consumingNext,
          consumingPrev,
        );
      }

      if (!_.isEmpty(focusedAppGroup.providing)) {
        locations.providing = GraphRenderUtils.getParsedAppSupergroup(
          focusedAppGroup,
          'providing',
          null,
          providingNext,
          providingPrev,
        );
      }
    } else if (focusedAppGroup && mapLevel === 'connectedAppGroup') {
      // In the connected group, don't show the AppSuperGroup if the only group is open
      if (_.size(focusedAppGroup.consuming) >= (mapRoute.type === 'consuming' ? 1 : 0)) {
        locations.consuming = GraphRenderUtils.getParsedAppSupergroup(
          focusedAppGroup,
          'consuming',
          mapRoute.type === 'consuming' && connectedAppGroup,
          consumingNext,
          consumingPrev,
        );
      }

      if (_.size(focusedAppGroup.providing) >= (mapRoute.type === 'providing' ? 1 : 0)) {
        locations.providing = GraphRenderUtils.getParsedAppSupergroup(
          focusedAppGroup,
          'providing',
          mapRoute.type === 'providing' && connectedAppGroup,
          providingNext,
          providingPrev,
        );
      }
    }

    graphLinks = {...links};

    GraphPositionUtils.positionAppGroupGraph(locations, clusters, positions);
    appSupergroupLinks = GraphRenderUtils.getParsedAppSupergroupLinks(
      locations,
      clusters,
      focusedAppGroup.href,
      mapRoute,
    );
    graphCalculated = true;
  }

  // Re-assess this based on needExpandedRoleHrefs
  if (!GraphDataUtils.isTrafficLoaded(expandedClusterHrefs, needExpandRoleHrefs)) {
    graphCalculated = false;
  }
}

// The maximum number of locations to show
const LOCATIONS_NUMBER_MAX = parseInt(localStorage.getItem('max_locations'), 10) || 500;

function calculateAndPositionLocations() {
  // calculate previous values at the very beginning
  if (graphCalculatedFirstTime) {
    prevClusters = clusters;
  }

  // Only continue if everything is loaded from the backend
  // expandedClusterHrefs are from localStorage
  if (!GraphDataUtils.isTrafficLoaded(expandedClusterHrefs)) {
    return;
  }

  const trafficFilters = TrafficFilterStore.getAll();
  const hiddenPolicyStates = TrafficFilterStore.getHiddenPolicyStates();
  const filters = mapLevel === 'full' ? FilterStore.getScopeFilters() : FilterStore.getScopeFiltersNoRole();

  areFiltersApplied = filters.length || trafficFilters.highlight;

  let locationHref;

  truncated = false;
  groupTooLarge = false;
  nodes = {};
  links = {};
  clusterLinks = {};
  clusters = {};
  locations = {};
  graphLinks = {};
  appSupergroupLinks = {};

  // if mapLevel is not location and the positions in localStorage is empty
  // back to location view
  if (
    TrafficStore.getAllLocationNodes().length < 200 &&
    mapLevel !== 'location' &&
    mapLevel !== 'full' &&
    (_.isEmpty(positions) || _.isEmpty(positions.locations))
  ) {
    emptyPositions = true;

    return;
  }

  if (mapLevel !== 'workload') {
    expandedClusterHrefs = [];

    if (mapType === 'loc') {
      localStorage.removeItem('expandedClusters');
    }
  }

  // get clusters
  TrafficStore.getAllClusterNodes().forEach(cluster => {
    if (cluster.entityCounts) {
      clusters[cluster.href] = {
        type: 'group',
        href: cluster.href,
        data: cluster,
        tooManyWorkloads: cluster.tooManyWorkloads,
      };
    }
  });

  if (mapLevel === 'location') {
    TrafficStore.getAllLocationNodes().forEach(location => {
      locations[location.href] = {
        data: location,
      };
    });

    GraphRenderUtils.parseLocationsForRender(locations, filters);

    if (Object.keys(locations).length < LOCATIONS_NUMBER_MAX) {
      locationTruncation = false;
    } else {
      locations = {};
      locationTruncation = true;
    }

    GraphPositionUtils.positionLocationGraph(locations, positions, mapLevel, filters);
    localStorage.setItem('positions', JSON.stringify(positions));

    clusters = {}; // clean clusters
    graphCalculated = true;
    graphCalculatedFirstTime = true;
    emptyPositions = false;
  } else if (mapLevel === 'group') {
    locationHref = getSessionUri(getInstanceUri('labels'), {label_id: mapRoute.id});
    // calculate & filter clusters
    GraphFilterUtils.filterNodesByMapLevel({}, clusters, mapRoute);
    GraphFilterUtils.filterNodesByScope(clusters, filters);
    GraphFilterUtils.filterClustersByPolicyState(clusters, hiddenPolicyStates);

    const maxClustersPerLocation = parseInt(localStorage.getItem('max_clusters_per_location'), 10) || 2000;

    if (_.size(clusters) > maxClustersPerLocation) {
      truncated = true;

      let counter = 0;

      clusters = _.transform(
        clusters,
        (result, cluster, key) => {
          result[key] = cluster;

          if (++counter === 200) {
            return false;
          }
        },
        {},
      );
    }

    // only calculate location circles in cluster view when we have location view.
    // otherwise, don't calculate locations.
    if (mapRoute.id) {
      TrafficStore.getAllLocationNodes().forEach(location => {
        locations[location.href] = {
          data: location,
        };
      });

      if (!locations[locationHref]) {
        locations = {};
        clusters = {};
        graphCalculated = true;

        return;
      }

      GraphRenderUtils.parseLocationsForRender(locations, filters, mapRoute, truncated);

      if (Object.keys(locations).length < LOCATIONS_NUMBER_MAX) {
        locationTruncation = false;
      } else {
        locations = {[locationHref]: locations[locationHref]};
        locationTruncation = true;
      }
    }

    GraphRenderUtils.parseClustersForRender(clusters, 'summary');

    if (_.isEmpty(clusters)) {
      locations = {};
      clusters = {};
      graphCalculated = true;

      return;
    }

    // filter & calculate cluster links
    TrafficStore.getAllClusterTraffics().forEach(traffic => {
      if (
        clusters[traffic.source.href] &&
        clusters[traffic.target.href] &&
        traffic.source.href !== traffic.target.href
      ) {
        clusterLinks[traffic.href] = {
          href: traffic.href,
          connections: {...traffic.connections},
          data: traffic,
        };
      }
    });
    GraphRenderUtils.parseNodesLinksForRender(clusters, clusterLinks);
    graphLinks = {...clusterLinks};
    GraphFilterUtils.filterLinks(clusterLinks, trafficFilters);
    GraphRenderUtils.calculateLinkColor(clusterLinks);
    GraphRenderUtils.calculateClustersId(clusters, prevClusters);
    // position the clusters and locations
    GraphPositionUtils.positionClusterGraph(clusters, locations, positions, mapRoute, filters, truncated);

    graphCalculated = true;
    graphCalculatedFirstTime = true;
  } else if (mapLevel === 'workload') {
    locationHref = getSessionUri(getInstanceUri('labels'), {label_id: mapRoute.previd});

    const focusedCluster = clusters[mapRoute.id];

    if (mapRoute.id && !focusedCluster) {
      // if role traffic or simple traffic isn't loaded, or the focused cluster doesn't exist
      // then stop the calculations and return everything as empty
      clusters = {};

      if (!focusedCluster) {
        graphCalculated = true;
      }

      return;
    }

    if (focusedCluster && focusedCluster.tooManyWorkloads) {
      graphCalculated = true;
      groupTooLarge = true;

      return;
    }

    // Calculate Locations
    TrafficStore.getAllLocationNodes().forEach(location => {
      locations[location.href] = {
        data: location,
      };
    });

    if (!locations[locationHref]) {
      locations = {};
      clusters = {};
      graphCalculated = true;

      return;
    }

    GraphRenderUtils.parseLocationsForRender(locations, filters, mapRoute);

    // Calculate Clusters and Links
    const allClusters = clusters;

    clusters = {};

    TrafficStore.getAllClusterTraffics().forEach(traffic => {
      if (
        traffic.source.href !== traffic.target.href &&
        (traffic.source.href === mapRoute.id || traffic.target.href === mapRoute.id)
      ) {
        clusterLinks[traffic.href] = {
          href: traffic.href,
          connections: _.cloneDeep(traffic.connections),
          data: traffic,
          source: allClusters[traffic.source.href] && allClusters[traffic.source.href].data,
          target: allClusters[traffic.target.href] && allClusters[traffic.target.href].data,
        };
      }
    });

    graphLinks = {...clusterLinks};

    // Filter Cluster Links
    GraphFilterUtils.filterLinksByScope(clusterLinks, filters, mapRoute.id);
    GraphFilterUtils.filterLinks(clusterLinks, trafficFilters);
    // Get Clusters for final set of Links
    GraphRenderUtils.getClustersConnectedToLinks(clusterLinks, clusters, allClusters);

    clusters[mapRoute.id] = allClusters[mapRoute.id];
    GraphFilterUtils.filterClustersByPolicyState(clusters, hiddenPolicyStates);

    // Clear map if the focused cluster has no workloads
    if (allClusters[mapRoute.id].data.entityCounts === 0 || !clusters[mapRoute.id] || _.isEmpty(clusters)) {
      truncated = false;
      groupTooLarge = false;
      nodes = {};
      links = {};
      clusterLinks = {};
      clusters = {};
      locations = {};
      graphLinks = {};
      appSupergroupLinks = {};

      graphCalculated = true;

      return;
    }

    // get all expanded clusters
    const expandedClusters = {};

    expandedClusters[focusedCluster.href] = focusedCluster;
    expandedClusterHrefs.forEach(href => {
      const expandedCluster = clusters[href];

      if (expandedCluster) {
        expandedClusters[href] = expandedCluster;
      }
    });

    // get all tokens (not centered cluster, and not expanded clusters)
    const tokenClusters = {};

    for (const cluster of Object.values(clusters)) {
      if (!expandedClusters[cluster.href]) {
        // if the cluster isn't expanded, then put it in token clusters
        tokenClusters[cluster.href] = cluster;
      }
    }

    // Parse Clusters and Links
    GraphRenderUtils.parseClustersForRender(expandedClusters, 'full', mapRoute.id);
    GraphRenderUtils.parseClustersForRender(tokenClusters, 'token');
    GraphRenderUtils.parseNodesLinksForRender(clusters, clusterLinks);

    // Calculate Roles and Workloads
    needExpandRoleHrefs = {};

    const roleThreshold = parseInt(localStorage.getItem('role_collapse'), 10) || 1;

    TrafficStore.getAllRoleNodes().forEach(role => {
      if (role.entityCounts && expandedClusters[role.clusterParent]) {
        if (role.entityCounts < roleThreshold || expandedRoleHrefs.includes(role.href)) {
          // if the role needs to be expanded, don't render and save it in needExpandRoleHrefs instead
          needExpandRoleHrefs[role.href] = role.href;
        } else {
          nodes[role.href] = {
            type: role.type,
            href: role.href,
            data: role,
          };
        }
      }
    });
    TrafficStore.getAllWorkloadNodes().forEach(workload => {
      if (needExpandRoleHrefs[workload.roleParent] && expandedClusters[workload.clusterParent]) {
        // only display the workloads that are in expanded roles
        nodes[workload.href] = {
          type: workload.type,
          href: workload.href,
          collapsible: expandedRoleHrefs.includes(workload.roleParent),
          data: workload,
        };
      }
    });

    const allTraffics = [
      ...TrafficStore.getAllRoleTraffics(),
      ...TrafficStore.getAllWorkloadTraffics(),
      ...TrafficStore.getAllMixedRoleTraffics(),
    ];

    allTraffics.forEach(traffic => {
      if (
        (RenderUtils.isInternetIpList(traffic.source) && nodes[traffic.target.href]) ||
        (nodes[traffic.source.href] && RenderUtils.isInternetIpList(traffic.target)) ||
        (nodes[traffic.source.href] && nodes[traffic.target.href])
      ) {
        links[traffic.href] = {
          href: traffic.href,
          connections: _.cloneDeep(traffic.connections),
          data: traffic,
        };
      }
    });

    // filter nodes
    GraphFilterUtils.filterNodesByPolicyState(nodes, trafficFilters);
    GraphFilterUtils.filterLinksByNodeHrefs(links, _.map(nodes, 'href'));
    // parse nodes and links
    GraphRenderUtils.parseNodesForRender(nodes);
    GraphRenderUtils.parseNodesLinksForRender(nodes, links);
    // get all links that showing on the graph
    graphLinks = Object.assign(graphLinks, links);
    // filter links
    GraphFilterUtils.filterLinks(links, trafficFilters);
    // calculate expanded clusters
    GraphRenderUtils.fillNonDiscoveryClusters(nodes, links, expandedClusters, 'full', mapRoute);
    GraphRenderUtils.calculateClustersId(clusters, prevClusters);
    // remove the clusterLinks between two clusters if they are expanded
    GraphRenderUtils.reconcileInterappLinks(links, clusterLinks);

    // if more then 5 locations, only show connected locations
    let isConnectedLocations = false;
    const maxLocations = parseInt(localStorage.getItem('max_locations'), 10) || 5;

    if (_.values(locations).length > maxLocations) {
      // filter locations
      const locationKeys = _.transform(
        clusters,
        (result, {labels}) => {
          if (_.isEmpty(labels)) {
            result.add('discovered');
          } else if (labels.loc && labels.loc.href) {
            result.add(labels.loc.href);
          } else {
            result.add('no_location');
          }
        },
        new Set(),
      );

      locations = _.transform(
        locations,
        (result, location, key) => {
          if (locationKeys.has(key)) {
            result[key] = location;
          }
        },
        {},
      );

      isConnectedLocations = true;
    }

    // clean the map if focusedCluster does not exist in any locations
    if (!locations[focusedCluster.labels.loc.href]) {
      truncated = false;
      groupTooLarge = false;
      nodes = {};
      links = {};
      clusterLinks = {};
      clusters = {};
      locations = {};
      graphLinks = {};
      appSupergroupLinks = {};

      graphCalculated = true;

      return;
    }

    GraphPositionUtils.positionSweetSpotGraph(
      locations,
      clusters,
      focusedCluster,
      expandedClusters,
      positions,
      isConnectedLocations,
      filters,
    );

    // always draw the expanded clusters on the top of the others
    clusters = _.transform(
      clusters,
      (result, cluster, key) => {
        if (!expandedClusterHrefs.includes(key) && focusedCluster.href !== key) {
          result[key] = cluster;
        }
      },
      {},
    );
    Object.assign(clusters, expandedClusters);

    graphCalculated = true;
    graphCalculatedFirstTime = true;
  } else if (mapLevel === 'full') {
    // if we should be showing the full graph
    for (const cluster of Object.values(clusters)) {
      if (!cluster.data.labels.length) {
        delete clusters[cluster.href];
      }
    }

    TrafficStore.getAllWorkloadNodes().forEach(node => {
      if (node.href) {
        nodes[node.href] = {
          type: node.type,
          href: node.href,
          data: node,
        };
      }
    });

    TrafficStore.getAllWorkloadTraffics().forEach(traffic => {
      links[traffic.href] = {
        href: traffic.href,
        connections: {...traffic.connections},
        data: traffic,
      };
    });
    // In full map, the graphLinks is based on everything before filters
    // In the other views, the graphLinks is based on the data from TrafficStore.
    graphLinks = {...links};

    GraphFilterUtils.filterBeforeCalculatingGraph(nodes, links, clusters, mapRoute, filters, trafficFilters);
    GraphRenderUtils.calculateGraph(nodes, links, clusters, prevClusters, selections, mapRoute);

    for (const cluster of Object.values(clusters)) {
      // if the cluster has no nodes because they were filtered out,
      // but they do originally have nodes, it should not be displayed
      if (
        !cluster.nodes.length &&
        (cluster.workloadsNum ||
          cluster.containerWorkloadsNum ||
          cluster.virtualServersNum ||
          cluster.virtualServicesNum)
      ) {
        delete clusters[cluster.href];
      }
    }

    GraphFilterUtils.filterAfterCalculatingGraph(nodes, links, clusters, filters, trafficFilters);
    GraphPositionUtils.positionFullGraph(nodes, clusters, positions);

    graphCalculated = true;
    graphCalculatedFirstTime = true;
  }
}

function storeUpdated() {
  selections[mapType] = _.filter(selections[mapType], selection => {
    if (selection.type === 'location') {
      return TrafficStore.getNode(selection.href);
    }

    if (selection.type === 'traffic') {
      return TrafficStore.getTraffic(selection.href);
    }

    if (selection.type === 'workload' || selection.type === 'virtualService') {
      return TrafficStore.getNode(selection.href);
    }

    if (selection.type === 'group' || selection.type === 'role') {
      const href = selection.href;

      return href.includes('discover') && selection.type === 'group'
        ? getClusterByHref(href) || getClusterById(href)
        : TrafficStore.getNode(href);
    }

    if (selection.type === 'appGroup') {
      return TrafficStore.getAppGroupNode(selection.href);
    }

    if (selection.type === 'internet' || selection.type === 'iplist' || selection.type === 'fqdn') {
      return !selection.linkHref || TrafficStore.getTraffic(selection.linkHref);
    }
  });
  calculateSelections(true);
}

// update the selected component
function calculateSelections(isSelected) {
  _.forOwn(selections[mapType], selection => {
    const clusterHref = selection.clusterHref;

    if (
      selection.type === 'workload' ||
      selection.type === 'virtualServer' ||
      selection.type === 'virtualService' ||
      selection.type === 'role'
    ) {
      let updatingNodes;

      if (_.isEmpty(clusterHref)) {
        updatingNodes = nodes;
      } else if (clusters[clusterHref]) {
        updatingNodes = clusters[clusterHref].nodes;
      }

      if (updatingNodes) {
        for (const node of Object.values(updatingNodes)) {
          node.selected = selection.href === node.href || (isSelected && node.selected) ? isSelected : false;
        }
      }
    } else if (selection.type === 'traffic') {
      if (_.isEmpty(clusterHref)) {
        // links outside groups
        if (links[selection.href]) {
          links[selection.href].selected = isSelected;
        } else if (clusterLinks[selection.href]) {
          clusterLinks[selection.href].selected = isSelected;
        } else {
          // unconnected nodes links
          for (const {links} of Object.values(nodes)) {
            if (links) {
              links.forEach(link => {
                link.selected = selection.href === link.href || (isSelected && link.selected) ? isSelected : false;
              });
            }
          }
        }
      } else if (clusters[clusterHref]) {
        for (const link of Object.values(clusters[clusterHref].links)) {
          link.selected = selection.href === link.href || (isSelected && link.selected) ? isSelected : false;
        }
      }
    } else if ((selection.type === 'group' || selection.type === 'appGroup') && clusters[selection.href]) {
      // todo(Xianlin): need think about whether should use clusterId for discovered clusters
      clusters[selection.href].selected = isSelected;

      if (MapPageStore.getMapLevel() === 'full') {
        GraphFilterUtils.findUnconnectedClusters(links, selection.href, isSelected, clusters);
      }
    } else if (selection.type === 'location' && locations[selection.href]) {
      locations[selection.href].selected = isSelected;
    } else if (selection.type === 'internet' || selection.type === 'iplist' || selection.type === 'fqdn') {
      if (_.isEmpty(clusterHref) && nodes[selection.nodeHref]) {
        // internet/iplist for unconnected nodes
        for (const internet of Object.values(nodes[selection.nodeHref].internets)) {
          internet.selected = internet.identifier === selection.type ? isSelected : false;
        }
      } else if (clusters[clusterHref] && clusters[clusterHref].internets) {
        const clusterInternets = clusters[clusterHref].internets.find(internet => internet.type === selection.type);

        // Update the selections with the latest cluster internets
        if (clusterInternets && clusterInternets.internets) {
          selection.internetLinks = clusterInternets.internets;
        }

        for (const internet of Object.values(clusters[clusterHref].internets)) {
          internet.selected =
            internet.cluster.href === clusterHref && internet.type === selection.type ? isSelected : false;
        }
      }
    }
  });
}

function calculateHoveredComponent(link, ifHover) {
  if (ifHover) {
    if (link.source.type === 'group' && link.target.type === 'group') {
      // cluster link
      const nextState = GraphHoveringUtils.hoverClusterLink(link, _.values(clusterLinks), _.values(clusters));

      nextState.clusterLinks.forEach(l => {
        clusterLinks[l.href] = l;
      });
      nextState.clusters.forEach(c => {
        clusters[c.href] = c;
      });
    } else {
      //all other link
      const nextState = GraphHoveringUtils.hoverLink(link, _.values(links), _.values(nodes), _.values(clusters));

      nextState.links.forEach(l => {
        links[l.href] = l;
      });
      nextState.clusters.forEach(c => {
        clusters[c.href] = c;
      });
      nextState.nodes.forEach(n => {
        nodes[n.href] = n;
      });
    }
  } else if (link.source.type === 'group' && link.target.type === 'group') {
    // cluster link
    const nextState = GraphHoveringUtils.unhoverClusterAndLinks(_.values(clusterLinks), _.values(clusters));

    nextState.clusters.forEach(c => {
      clusters[c.href] = c;
    });
    nextState.clusterLinks.forEach(l => {
      clusterLinks[l.href] = l;
    });
  } else {
    const nextState = GraphHoveringUtils.unhoverNodeAndLink(_.values(links), _.values(nodes), _.values(clusters));

    nextState.links.forEach(l => {
      links[l.href] = l;
    });
    nextState.clusters.forEach(c => {
      clusters[c.href] = c;
    });
    nextState.nodes.forEach(n => {
      nodes[n.href] = n;
    });
  }
}

function calculateUnhoverFromSelections() {
  let nextState = {clusters, links, nodes, clusterLinks};
  const allSelections = selections[mapType || 'loc'];

  if (allSelections.length) {
    if (RenderUtils.isInternetIpList(allSelections[0])) {
      // iplist, internet
      nextState = GraphHoveringUtils.hoverInternet(
        allSelections[0],
        nextState.links,
        nextState.nodes,
        nextState.clusters,
      );
    } else if (
      allSelections[0].type !== 'appGroup' &&
      allSelections[0].type !== 'group' &&
      allSelections[0].type !== 'traffic'
    ) {
      // node other than clusters
      nextState = GraphHoveringUtils.hoverNode(allSelections[0], nextState.links, nextState.nodes, nextState.clusters);
    } else if (allSelections[0].type === 'traffic') {
      // traffic
      const allLinks = [
        ..._.values(links),
        ..._.values(clusters).flatMap(cluster => cluster.links),
        ..._.values(clusterLinks),
      ];
      const isMultiSelection = allSelections.length > 1;

      allSelections.forEach(selectedLink => {
        allLinks.forEach(link => {
          if (selectedLink.href === link.href) {
            if (selectedLink.clusterHref) {
              nextState = GraphHoveringUtils.hoverLink(
                link,
                nextState.links,
                nextState.nodes,
                nextState.clusters,
                isMultiSelection,
              );
            } else {
              nextState = GraphHoveringUtils.hoverClusterLink(
                link,
                nextState.clusterLinks,
                nextState.clusters,
                isMultiSelection,
              );
            }
          }
        });
      });
    }

    (nextState.links || []).forEach(l => {
      links[l.href] = l;
    });
    (nextState.clusters || []).forEach(c => {
      clusters[c.href] = c;
    });
    (nextState.nodes || []).forEach(n => {
      nodes[n.href] = n;
    });
    (nextState.clusterLinks || []).forEach(l => {
      clusterLinks[l.href] = l;
    });
  }
}

function setRecentAppGroups(recentAppGroup) {
  if (mapType === 'app' && !_.isEmpty(recentAppGroup) && TrafficStore.isAppGroupsLoaded()) {
    const addedRecentAppGroup = {
      id: recentAppGroup.previd || recentAppGroup.id,
      type: 'focused',
    };

    addedRecentAppGroup.name = TrafficStore.getAppGroupNode(addedRecentAppGroup.id)
      ? TrafficStore.getAppGroupNode(addedRecentAppGroup.id).name
      : null;

    const isUniqueAppGroup = !_.some(recentAppGroups, ['id', addedRecentAppGroup.id]);

    //only add if it is a valid url
    if (addedRecentAppGroup.name) {
      if (!isUniqueAppGroup) {
        recentAppGroups = _.filter(recentAppGroups, appGroup => appGroup.id !== addedRecentAppGroup.id);
      }

      recentAppGroups.push(addedRecentAppGroup);

      if (recentAppGroups.length > 8) {
        recentAppGroups.shift();
      }

      localStorage.setItem('recent_appgroups', JSON.stringify(recentAppGroups));
    }
  }
}

function verifyRecentAppGroups(appGroups) {
  if (TrafficStore.isAppGroupsLoaded()) {
    recentAppGroups = appGroups.filter(appGroup => TrafficStore.getAppGroupNode(appGroup.id));
    localStorage.setItem('recent_appgroups', JSON.stringify(recentAppGroups));
  }
}

function calculateRecentAppGroups() {
  verifyRecentAppGroups(recentAppGroups);

  return [...recentAppGroups].reverse().map(appGroup => {
    const vulnerability =
      TrafficStore.getAppGroupNode(appGroup.id) && TrafficStore.getAppGroupNode(appGroup.id).vulnerability;

    return {...appGroup, vulnerability};
  });
}

function resetLayout(data) {
  if (mapLevel === 'location') {
    positions.locations = {};
    positions.clusters = {};
    positions.nodes = {};
    positions.summaryClusters = {};
  }

  if (mapLevel === 'group') {
    if (positions.locations[data.href]) {
      positions.locations[data.href].clusters = {};
    }
  }

  if (mapLevel === 'full') {
    if (data.type === 'group') {
      if (positions.clusters[data.href]) {
        positions.clusters[data.href].nodes = {};
      }
    } else {
      positions.locations = {};
      positions.clusters = {};
      positions.nodes = {};
      positions.summaryClusters = {};
    }
  }

  if (mapLevel === 'workload') {
    if (positions.clusters[data.href]) {
      positions.clusters[data.href].nodes = {};
    }
  }

  if (mapLevel === 'focusedAppGroup' || mapLevel === 'connectedAppGroup') {
    positions.locations = {};
    positions.clusters = {};
    positions.nodes = {};
    positions.summaryClusters = {};
  }

  localStorage.setItem('positions', JSON.stringify(positions));
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      // Traffic
      case Constants.LOCATION_SUMMARY_GET_SUCCESS:
      case Constants.APP_GROUP_SUMMARY_GET_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken, MapPageStore.dispatchToken]);
        setRecentAppGroups(MapPageStore.getMapRoute());
        break;

      case Constants.NETWORK_TRAFFIC_GET_SUCCESS:
      case Constants.NETWORK_TRAFFIC_ITERATE_COMPLETE:
      case Constants.NETWORK_TRAFFIC_REBUILD_COMPLETE:
        dispatcher.waitFor([
          MapSpinnerStore.dispatchToken,
          TrafficStore.dispatchToken,
          MapPageStore.dispatchToken,
          TrafficFilterStore.dispatchToken,
        ]);

        if (!MapSpinnerStore.getTrafficSpinner()) {
          storeUpdated();
        }

        break;

      case Constants.SEC_POLICY_RULE_COVERAGE_CREATE_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        break;

      case Constants.AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS:
      case Constants.DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS:
      case Constants.WORKLOADS_VERIFY_CAPS_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        break;

      // Map page store
      case Constants.UPDATE_MAP_ROUTE:
      case Constants.UPDATE_MAP_TYPE:
        dispatcher.waitFor([MapPageStore.dispatchToken]);
        setRecentAppGroups(action.data);

        if (action.data === 'loc') {
          expandedClusterHrefs = JSON.parse(localStorage.getItem('expandedClusters')) || [];
        }

        linearAnimation = false;
        break;

      // Filters
      case Constants.ADD_FILTERS:
      case Constants.REMOVE_FILTER:
        dispatcher.waitFor([FilterStore.dispatchToken, TrafficStore.dispatchToken]);
        break;

      // Selections
      case Constants.SELECT_COMPONENT:
        setSelections(action.data);
        calculateSelections(true);
        this.emit(UPDATE_EVENT);

        return true;

      case Constants.UNSELECT_COMPONENT:
        calculateSelections(false);
        removeSelections(action.data);
        this.emit(UPDATE_EVENT);

        return true;

      case Constants.UPDATE_COMPONENT_SELECTION:
        calculateSelections(false);
        removeSelections(action.data);
        setSelections(action.data);
        calculateSelections(true);
        this.emit(UPDATE_EVENT);

        return true;

      case Constants.UPDATE_MULTIPLE_SELECTION:
        prevSelections[mapType] = [];
        prevSelections[mapType] = action.data;

        return true;

      case Constants.UPDATE_HOVERED_COMPONENT:
        calculateHoveredComponent(action.data, true);
        this.emit(UPDATE_EVENT);

        return true;

      case Constants.UPDATE_UNHOVERED_COMPONENT:
        calculateHoveredComponent(action.data, false);
        calculateUnhoverFromSelections(action.data, false);
        this.emit(UPDATE_EVENT);

        return true;

      case Constants.UPDATE_SELECTION_TYPE:
        setSelectionType(action.data);
        break;

      // Expansion of tokens
      case Constants.EXPAND_CLUSTER:
        setExpandedClusters(action.data);
        break;

      case Constants.REMOVE_EXPANDED_CLUSTER:
        removeExpandedCluster();
        break;

      case Constants.CLEAR_EXPANDED_CLUSTERS:
        clearExpandedClusters();
        break;

      case Constants.EXPAND_ROLE:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        setExpandedRoles(action.data);
        linearAnimation = true;
        break;

      case Constants.COLLAPSE_ROLE:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        removeExpandedRoles(action.data);
        linearAnimation = true;
        break;

      case Constants.UNMOUNT_GRAPH:
        linearAnimation = false;
        break;

      // traffic filters
      case Constants.RESET_DEFAULT_FILTERS:
      case Constants.SELECT_TRAFFIC_FILTERS:
      case Constants.SELECT_TRAFFIC_TIME_FILTERS:
        dispatcher.waitFor([TrafficFilterStore.dispatchToken, TrafficStore.dispatchToken]);
        calculateAndPositionGraph();
        break;

      case Constants.SELECT_TRAFFIC_CONNECTION_FILTERS:
      case Constants.SELECT_SERVICE_FILTERS:
        dispatcher.waitFor([TrafficStore.dispatchToken, TrafficFilterStore.dispatchToken]);
        break;

      case Constants.UPDATE_GRAPH_CALCULATED:
        setGraphCalculatedFlag();

        return true;

      // Graph position
      case Constants.UPDATE_POSITION:
        setPosition(action.data);
        localStorage.setItem('positions', JSON.stringify(positions));
        break;

      case Constants.NOT_SHOWING_MESSAGE:
        hideMessage = action.data.hideMessage;
        break;

      case Constants.CLOSE_DIALOG:
        break;

      case Constants.SET_TOTAL_WORKLOADS:
        break;

      case Constants.SET_MAP_POLICY_VERSION:
        dispatcher.waitFor([MapPageStore.dispatchToken, TrafficStore.dispatchToken]);
        break;

      case Constants.SET_APP_MAP_VERSION:
        dispatcher.waitFor([MapPageStore.dispatchToken]);
        break;

      case Constants.KVPAIRS_GET_INSTANCE_SUCCESS:
        if (action.options.params.key === 'hide_message') {
          hideMessage = _.isEmpty(action.data) ? hideMessage : action.data;
        }

        break;

      case Constants.ORGS_GET_INSTANCE_SUCCESS:
        break;

      case Constants.APP_GROUPS_GET_COLLECTION_SUCCESS:
        break;

      case Constants.EXPAND_COLLAPSE_CONNECTED_GROUP:
        break;

      case Constants.RESET_LAYOUT:
        resetLayout(action.data);
        break;

      case Constants.SEC_POLICY_RULE_COVERAGE_IS_TRUNCATED:
        ruleCoverageTruncated = action.data;

        return true;

      default:
        return true;
    }

    calculateAndPositionGraph();
    calculateSelections(true);
    localStorage.setItem('positions', JSON.stringify(positions));
    this.emitChange();

    return true;
  },

  addUpdateListener(callback) {
    this.on(UPDATE_EVENT, callback);
  },

  removeUpdateListener(callback) {
    this.removeListener(UPDATE_EVENT, callback);
  },

  getClusters() {
    return _.values(clusters);
  },

  getCluster(href) {
    return getClusterByHref(href) || getClusterById(href);
  },

  getLocations() {
    return _.values(locations);
  },

  getLocation(href) {
    return getLocationByHref(href);
  },

  getNodes() {
    return _.values(nodes);
  },

  getNode(href) {
    return getNodeByHref(href);
  },

  getLinks() {
    return _.values(links);
  },

  getLink(href, identifier) {
    return getLinkByHref(href, identifier);
  },

  getClusterLinks() {
    return _.values(clusterLinks);
  },

  getAppSupergroupLinks() {
    return _.values(appSupergroupLinks);
  },

  getGraphLinks() {
    return graphLinks;
  },

  getSelections() {
    return selections[mapType || 'loc'];
  },

  getPrevSelections() {
    return prevSelections[mapType || 'loc'];
  },

  getSelectionType: () => selectionType,

  getSelectionMap() {
    selectionMap = {};
    selections[mapType || 'loc'].forEach(component => {
      selectionMap[component.identifier] = component;
    });

    return selectionMap;
  },

  getHideMessage() {
    return hideMessage;
  },

  getExpandedClusters() {
    return expandedClusterHrefs;
  },

  getExpandedRoles() {
    return needExpandRoleHrefs;
  },

  isGraphCalculated() {
    return graphCalculated;
  },

  arePositionsEmpty() {
    return emptyPositions;
  },

  areFiltersApplied() {
    return areFiltersApplied;
  },

  getPrevMapLevel() {
    return prevMapLevel;
  },

  getTruncated() {
    return truncated;
  },

  getLocationsTruncated() {
    return locationTruncation;
  },

  getGroupTooLarge() {
    return groupTooLarge;
  },

  getGraphData() {
    return {
      locations: Object.values(locations),
      clusters: Object.values(clusters),
      nodes: Object.values(nodes),
      links: Object.values(links),
      clusterLinks: Object.values(clusterLinks),
      appSupergroupLinks: Object.values(appSupergroupLinks),
      expandedClusters: expandedClusterHrefs,
      // This needs to use needExpandRoleHrefs because the expandedRoleHrefs does not contain
      // the expanded roles calculated by the configurable expand all roles less than X workloads
      // This is needed for the demos
      expandedRoles: needExpandRoleHrefs,
      recentAppGroups: calculateRecentAppGroups(),
      emptyPositions,
      linearAnimation,
      groupTooLarge,
      ruleCoverageTruncated,
    };
  },

  getLinearAnimation: () => linearAnimation,

  getRecentAppGroups: () => calculateRecentAppGroups(),

  getLocationNodes: () => locations,
  getNextAppGroup: (appGroupType, connectedAppGroup) => nextAppGroup(appGroupType, connectedAppGroup),
  getPrevAppGroup: (appGroupType, connectedAppGroup) => prevAppGroup(appGroupType, connectedAppGroup),
});
