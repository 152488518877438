/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import cx from 'classnames';
import {Component, PropTypes, Children} from 'react';
import {TransitionMotion} from 'react-motion';
import {isClickInBrowsingContext} from '../../utils/dom';
import ItemsContainer from './MenuItemsContainer';
import {dropdownHorizontal as dropdownMotions} from './motions';

const slope = (ax, ay, bx, by) => (by - ay) / (bx - ax);
const slopeToleranceTop = 10;
const slopeToleranceBottom = 50;

// Set true if you want to see forgiving mouse movement paths
const showForgivingPath = false;

class Dropdown extends Component {
  static propTypes = {
    dir: PropTypes.number,
    style: PropTypes.object,
    replica: PropTypes.bool,
    active: PropTypes.bool,

    onClose: PropTypes.func.isRequired,
    onScope: PropTypes.func,
    saveSubDropdownItemsRef: PropTypes.func,
    focusItemOnOpen: PropTypes.oneOf(['first', 'last']),
  };

  static defaultProps = {
    dir: 0,
    replica: false,
    onScope: _.noop,
    saveSubDropdownItemsRef: _.noop,
  };

  constructor(props) {
    super(props);

    this.state = {showSubDropdown: false};
    this.subDropdownConfig = [];
    this.items = [];
    this.itemList = null;
    this.subDropdownItemsList = null;
  }

  componentWillMount() {
    this.renderSubDropdown = this.renderSubDropdown.bind(this);

    this.saveRef = this.saveRef.bind(this);
    this.saveItemsRef = this.saveItemsRef.bind(this);
    this.saveSubDropdownRef = this.saveSubDropdownRef.bind(this);
    this.saveSubDropdownItemsRef = this.saveSubDropdownItemsRef.bind(this);

    this.focusItemBound = this.focusItem.bind(this);
    this.applyStateIntention = this.applyStateIntention.bind(this);

    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleItemFocus = this.handleItemFocus.bind(this);
    this.handleItemMouse = this.handleItemMouse.bind(this);
    this.handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);

    this.handleSubDropdownClose = this.handleSubDropdownClose.bind(this);
    this.handleSubDropdownScope = this.handleSubDropdownScope.bind(this);
  }

  componentDidMount() {
    if (!this.props.replica) {
      // Primary dropdown should set focus on itself automatically
      this.scopeFocus(this.props.focusItemOnOpen);

      // Only primary dropdown should listen to mouse click outside of dropdown to close itself and subdropdowns
      this.handleDocumentMouseDown = this.handleDocumentMouseDown.bind(this);
      document.addEventListener('mousedown', this.handleDocumentMouseDown);
    }

    const focusedChild = this.items.find(
      item =>
        item.props.initiallyFocused ||
        traverseMenuItems(item.props.children, child => Boolean(child?.props?.initiallyFocused)),
    );

    if (focusedChild) {
      this.focusItem(focusedChild);
    }
  }

  componentWillUnmount() {
    this.items = null;
    this.resetScopeFocus(false);

    if (!this.props.replica) {
      this.restoreFocusOnSavedElement();
      document.removeEventListener('mousedown', this.handleDocumentMouseDown);
    }
  }

  saveRef(dropdown) {
    this.dropdown = dropdown;
  }

  saveSubDropdownRef(dropdown) {
    this.subDropdown = dropdown;

    if (!dropdown) {
      this.subDropdownItemsList = null;
    }
  }

  saveItemsRef(itemList) {
    this.itemList = itemList;
    this.items = itemList.items;

    if (this.props.replica) {
      this.props.saveSubDropdownItemsRef(itemList);
    }
  }

  saveSubDropdownItemsRef(itemList) {
    this.subDropdownItemsList = itemList;
  }

  scopeFocus(focusOnItem) {
    if (this.focuseScoped) {
      return;
    }

    this.focuseScoped = true;

    // Handle keys events
    document.addEventListener('keydown', this.handleDocumentKeyDown);

    // Save focus on parent dropdown's item if it is currently focused, to restore focus on it by pressing left arrow
    // If none is focused (that's mean <body> is activeElement),
    // then pressing Esc will focus trigger button by default and click on outside of dropdown will focus something else
    if (
      this.props.replica &&
      document.activeElement &&
      document.activeElement.classList.contains('Menu-item') &&
      this.dropdown &&
      !this.dropdown.contains(document.activeElement)
    ) {
      this.savedFocusedElement = document.activeElement;
    }

    if (focusOnItem) {
      this.focusItem(this.items[focusOnItem === 'first' ? 0 : this.items.length - 1]);
    } else if (focusOnItem !== false) {
      // If focusOnItem has not been set, we need to focus on dropdown itself
      // But if dropdown is taller than screen, browser's top will jump to dropdown's top,
      // to avoid this we creaded empty element 'focuser' in the very beginning of dropdown
      this.dropdown.querySelector('.Menu-focuser').focus();
      this.focusedItem = null;
    }

    this.props.onScope();
  }

  resetScopeFocus(restoreFocus, cascadeDown) {
    if (this.focuseScoped) {
      this.focusedItem = null;
      this.mouseLastX = 0;
      this.mouseLastY = 0;
      this.mouseLastItem = null;
      this.focuseScoped = false;

      // Remove listeners
      document.removeEventListener('keydown', this.handleDocumentKeyDown);
    }

    if (cascadeDown) {
      this.clearStateIntention();
      this.subDropdownConfig = [];

      if (this.subDropdown) {
        this.subDropdown.resetScopeFocus(false, cascadeDown);
      }

      if (this.state.showSubDropdown) {
        this.setState({showSubDropdown: false});
      }
    }

    this.clearMouseSlopeTimeout();

    if (restoreFocus) {
      this.restoreFocusOnSavedElement();
    }
  }

  restoreFocusOnSavedElement() {
    // Reset focus on element that was focused before current dropdown
    if (this.savedFocusedElement && typeof this.savedFocusedElement.focus === 'function') {
      this.savedFocusedElement.focus();
    }
  }

  handleDocumentMouseDown(evt) {
    // If clicking outside of dropdown
    if (evt.target !== this.dropdown && !this.dropdown.contains(evt.target)) {
      // Reset focus scoping right away to allow setting focus into other elements (input, etc) by this click
      this.resetScopeFocus(true);
      // Close menu
      this.props.onClose();
    }
  }

  handleDocumentKeyDown(evt) {
    let {key} = evt;

    if (evt.key === 'Tab') {
      key = evt.shiftKey ? 'ArrowUp' : 'ArrowDown';
    }

    switch (key) {
      case 'Escape':
        evt.stopPropagation();
        // Closing by pressing Esc should focus trigger button if no elements had been focused before opening menu
        this.props.onClose(true);
        break;
      case 'ArrowUp':
        evt.preventDefault();
        this.focusPreviousItem();
        break;
      case 'ArrowRight':
        evt.preventDefault();

        if (this.subDropdown && this.subDropdownConfig.length) {
          this.resetScopeFocus(false);
          this.subDropdown.scopeFocus('first');
        } else {
          this.focusNextItem();
        }

        break;
      case 'ArrowLeft':
        evt.preventDefault();

        if (this.props.replica) {
          this.resetScopeFocus(true, true);
        } else {
          this.focusPreviousItem();
        }

        break;
      case 'ArrowDown':
        evt.preventDefault();
        this.focusNextItem();
        break;
    }
  }

  handleItemFocus(evt, item) {
    evt.stopPropagation();
    this.focusItem(item);
  }

  handleItemClick(evt, item) {
    // Close menu after click. If it link, close only if user wants to opent it in the same browser context
    // Don't close if user pressed Ctrl, Cmd, etc.
    if (!item.props || !item.props.link || isClickInBrowsingContext(evt, item.props.target)) {
      this.props.onClose();
    }
  }

  handleItemMouse(evt, item) {
    evt.stopPropagation();
    this.clearMouseSlopeTimeout();

    if (!this.subDropdownItemsList || !this.focusedItem || !this.focusedItem.props.children) {
      this.focusItem(item);
    } else if (item !== this.focusedItem || item !== this.mouseLastItem) {
      const rect = this.subDropdownItemsList.calcRect();

      const decreasingSlope = slope(evt.pageX, evt.pageY, rect.left, rect.top - slopeToleranceTop);
      const increasingSlope = slope(evt.pageX, evt.pageY, rect.left, rect.bottom + slopeToleranceBottom);
      const prevDecreasingSlope = slope(this.mouseLastX, this.mouseLastY, rect.left, rect.top - slopeToleranceTop);
      const prevIncreasingSlope = slope(
        this.mouseLastX,
        this.mouseLastY,
        rect.left,
        rect.bottom + slopeToleranceBottom,
      );

      if (decreasingSlope > prevDecreasingSlope || increasingSlope < prevIncreasingSlope) {
        // If slope is decreasing, focus new item
        this.focusItem(item);
      } else {
        // Do nothing If slope is increasing,
        // but set timeout to focus on new item if user stops moving mouse suddenly, before reaching subdropdown
        this.mouseHoverSlopeTimeout = setTimeout(this.focusItemBound, 100, item);
      }
    }

    this.mouseLastX = evt.pageX;
    this.mouseLastY = evt.pageY;
    this.mouseLastItem = item;
  }

  handleSubDropdownClose(val) {
    this.props.onClose(val);
  }

  handleSubDropdownScope() {
    this.resetScopeFocus(false);
  }

  clearMouseSlopeTimeout() {
    if (this.mouseHoverSlopeTimeout) {
      clearTimeout(this.mouseHoverSlopeTimeout);
      this.mouseHoverSlopeTimeout = null;
    }
  }

  focusItem(item, fromKeyPress) {
    if (!this.focuseScoped) {
      if (this.subDropdown) {
        this.subDropdown.resetScopeFocus(false, true);
      }

      if (this.props.replica) {
        this.props.onScope();
      }

      this.scopeFocus(false);
    }

    if (!item) {
      return;
    }

    if (item !== this.focusedItem) {
      this.focusedItem = item;

      if (this.subDropdownConfig.length) {
        this.subDropdownConfig[0].data.setParentFocus(false);
      }

      if (item.props.children) {
        const focusChildrenIndex = this.items.indexOf(this.focusedItem);

        if (focusChildrenIndex > this.focusChildrenIndex) {
          this.focusChildrenChangeDirection = 1;
        } else if (focusChildrenIndex < this.focusChildrenIndex) {
          this.focusChildrenChangeDirection = -1;
        } else {
          this.focusChildrenChangeDirection = 0;
        }

        this.focusChildrenIndex = focusChildrenIndex;
        this.subDropdownConfig = [
          {
            key: 'dropdown',
            data: this.focusedItem,
            style: dropdownMotions.open(item.offsetTop),
          },
        ];

        this.focusedItem.setParentFocus(true);

        this.declareStateIntention({showSubDropdown: true}, fromKeyPress ? 0 : undefined);
      } else if (this.subDropdownConfig.length) {
        this.subDropdownConfig = [];
        this.declareStateIntention({showSubDropdown: false}, fromKeyPress ? 0 : undefined);
      }
    }

    item.focus();
  }

  focusNextItem() {
    const {items} = this;
    const focusedItemIndex = items.indexOf(this.focusedItem);
    const nextItem = items[focusedItemIndex + 1] || items[0];

    this.focusItem(nextItem, true);
  }

  focusPreviousItem() {
    const {items} = this;
    const focusedItemIndex = items.indexOf(this.focusedItem);
    const previousItem = items[focusedItemIndex - 1] || items[items.length - 1];

    this.focusItem(previousItem, true);
  }

  declareStateIntention(intentionalState, delay = 100) {
    this.clearStateIntention();

    if (delay) {
      this.stateIntentionTimeout = setTimeout(this.applyStateIntention, delay, intentionalState);
    } else {
      this.applyStateIntention(intentionalState);
    }
  }

  clearStateIntention() {
    if (this.stateIntentionTimeout) {
      clearTimeout(this.stateIntentionTimeout);
      this.stateIntentionTimeout = null;
    }
  }

  applyStateIntention(intentionalState) {
    this.setState(intentionalState);
  }

  subEnter({style}) {
    return dropdownMotions.enter(style.y.val);
  }

  subLeave({style}) {
    return dropdownMotions.leave(style.y);
  }

  renderSubDropdown(interpolatedStyles) {
    return (
      <span>
        {interpolatedStyles.map(config => {
          const {key, style, data: focusedItem} = config;
          const children = focusedItem !== null ? focusedItem.props.children : undefined;

          return (
            <Dropdown
              key={key}
              replica
              active={this.state.showSubDropdown}
              style={style}
              dir={this.focusChildrenChangeDirection}
              ref={this.saveSubDropdownRef}
              saveSubDropdownItemsRef={this.saveSubDropdownItemsRef}
              onScope={this.handleSubDropdownScope}
              onClose={this.handleSubDropdownClose}
            >
              {children}
            </Dropdown>
          );
        })}
      </span>
    );
  }

  render() {
    const {
      props,
      props: {replica, style, active},
    } = this;

    const dropdownProps = {
      ref: this.saveRef,
      className: cx('Menu-dropdown', replica ? 'Menu-dropdown--replica' : 'Menu-dropdown--withArrow', {
        'Menu-dropdown--active': active,
      }),
      style: {
        opacity: style.opacity,
        transform: `translate3d(${style.x || 0}px, ${style.y || 0}px, 0)`,
      },
    };

    return (
      <div {...dropdownProps}>
        {!props.focusItemOnOpen && <span className="Menu-focuser" tabIndex="-1" />}

        <ItemsContainer
          saveItemsRef={this.saveItemsRef}
          dir={props.dir}
          onItemClick={this.handleItemClick}
          onItemFocus={this.handleItemFocus}
          onItemMouse={this.handleItemMouse}
        >
          {this.props.children}
        </ItemsContainer>

        <TransitionMotion willEnter={this.subEnter} willLeave={this.subLeave} styles={this.subDropdownConfig}>
          {this.renderSubDropdown}
        </TransitionMotion>
      </div>
    );
  }
}

export default Dropdown;

if (showForgivingPath && typeof window === 'object') {
  const svg = d3
    .select('body')
    .append('svg')
    .attr('width', '100%')
    .attr('height', '100%')
    .attr('style', 'position:absolute;top:0;left:0;pointer-events:none;z-index:99999');

  const handleItemMouseOrigin = Dropdown.prototype.handleItemMouse;
  const saveSubDropdownRefOrigin = Dropdown.prototype.saveSubDropdownRef;
  const componentWillUnmountOrigin = Dropdown.prototype.componentWillUnmount;

  Dropdown.prototype.handleItemMouse = function (...args) {
    if (this.subDropdownItemsList && this.mouseLastX) {
      const rect = this.subDropdownItemsList.calcRect();

      this.lineUp = this.lineUp || svg.append('line').style('stroke', 'green');
      this.lineDown = this.lineDown || svg.append('line').style('stroke', 'green');

      this.lineUp
        .attr('x1', this.mouseLastX)
        .attr('y1', this.mouseLastY)
        .attr('x2', rect.left)
        .attr('y2', rect.top - slopeToleranceTop);
      this.lineDown
        .attr('x1', this.mouseLastX)
        .attr('y1', this.mouseLastY)
        .attr('x2', rect.left)
        .attr('y2', rect.bottom + slopeToleranceBottom);
    }

    handleItemMouseOrigin.apply(this, args);
  };

  Dropdown.prototype.componentWillUnmount = function (...args) {
    if (this.lineUp) {
      this.lineUp.remove();
    }

    if (this.lineDown) {
      this.lineDown.remove();
    }

    componentWillUnmountOrigin.apply(this, args);
  };

  Dropdown.prototype.saveSubDropdownRef = function (dropdown) {
    if (!dropdown) {
      if (this.lineUp) {
        this.lineUp.remove();
        this.lineUp = null;
      }

      if (this.lineDown) {
        this.lineDown.remove();
        this.lineDown = null;
      }
    }

    saveSubDropdownRefOrigin.call(this, dropdown);
  };
}

function traverseMenuItems(children, predicate) {
  if (!children) {
    return false;
  }

  return Children.toArray(children).some(
    child => predicate(child) || traverseMenuItems(child?.props?.children, predicate),
  );
}
