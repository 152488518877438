/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import IpUtils from './IpUtils';
import DataValidationUtils from './DataValidationUtils';
import {getSessionUri, getInstanceUri} from '../lib/api';

export default {
  pages: {
    getPageTypeFromPath(path) {
      let isDetail = path.split('/').length > 3;

      if (path.indexOf('create') > 0) {
        isDetail = false; // use the plural (e.g. "Rulesets","Workloads") when adding one (in most cases)
      }

      switch (path.split('/')[1]) {
        case 'provisioning':
          return intl('Provision.Status');
        case 'rulesets':
          return isDetail ? intl('Common.Ruleset') : intl('Common.Rulesets');
        case 'policygenerator':
          return intl('PolicyGenerator.PolicyGenerator');
        case 'workloads':
          return isDetail ? intl('Common.Workload') : intl('Common.Workloads');
        case 'services':
          return isDetail ? intl('Common.Service') : intl('Common.Services');
        case 'labels':
          return isDetail ? intl('Common.Label') : intl('Common.Labels');
        case 'labelgroups':
          return isDetail ? intl('Labels.Group') : intl('Labels.Groups');
        case 'pairingprofiles':
          return isDetail ? intl('PairingProfiles.Profile') : intl('PairingProfiles.Profiles');
        case 'iplists':
          return isDetail ? intl('Common.IPList') : intl('Common.IPLists');
        case 'mapgroups':
          return isDetail ? intl('Common.Group') : intl('Common.Groups');
        case 'appGroups':
          return isDetail ? intl('Common.AppGroup') : intl('Common.AppGroups');
        case 'virtualservices':
          return isDetail ? intl('Common.VirtualService') : intl('Common.VirtualServices');
        case 'orgevents':
          return isDetail ? intl('Common.OrganizationEvent') : intl('Common.OrganizationEvents');
        case 'trafficevents':
          return isDetail ? intl('TrafficEvents.Singular') : intl('TrafficEvents.List');
        case 'supportreports':
          return isDetail ? intl('SupportReports.SupportReport') : intl('SupportReports.PageName');
        case 'virtualservers':
          return isDetail ? intl('Common.VirtualServer') : intl('Common.VirtualServers');
        case 'segmentationtemplates':
          return isDetail
            ? intl('SegmentationTemplates.SegmentationTemplate')
            : intl('SegmentationTemplates.SegmentationTemplates');
        case 'blockedtraffic':
        case 'blockedtraffics':
          return intl('BlockedTraffic.Name');
        case 'groupwizard':
          return intl('Common.Group');
        case 'map':
          return intl('Common.Illumination');
        case 'settings':
          return intl('Common.Security');
        case 'pending':
          return intl('Provision.PendingProvisioning');
        case 'provision':
          return intl('Provision.Review');
        case 'revert':
          return intl('Provision.Revert.Review');
        case 'sso':
          return intl('Common.AuthenticationSettings');
        case 'myprofile':
          return intl('Users.MyProfile');
        case 'apikeys':
          return intl('Users.APIKeys.Name');
        case 'versions':
          return intl('Provision.Versions', {multiple: true});
        case 'connectivity':
          return intl('Policy.Check');
        case 'usergroups':
          return intl('Common.UserGroups');
        case 'loadbalancers':
        case 'loadbalancer':
          return intl('VirtualServers.Detail.ServerLoadBalancers');
        case 'secureconnectgateways':
          return intl('SecureGateway.SecureConnect');
        case 'api':
          return intl('Users.APIKeys.Explorer');
        case 'appgroup':
          return intl('Common.AppGroupMap');
        case 'appgroups':
          return isDetail ? intl('Common.AppGroup') : intl('Common.AppGroups');
        case 'appgrouptype':
          return intl('AppGroups.AppGroupType');
        case 'explorer':
          return intl('Common.Explorer');
        case 'health':
          return isDetail ? intl('Health.PCEHealth') : intl('Health.SuperclusterHealth');
        case 'containerclusters':
          return intl('Menu.ContainerClusters', {multiple: true});
        case 'containerworkloads':
          return intl('Common.ContainerWorkloads');
        case 'componentsold':
          return 'Components';
        case 'landing':
          return '';
        case 'trafficparameters':
          return intl('TrafficParameters.TrafficParameters');
        case 'rulesearch':
          return intl('Common.RuleSearch');
        case 'boundaries':
          return path.toLowerCase().includes('explorer')
            ? intl('EnforcementBoundaries.BlockedConnections')
            : intl('EnforcementBoundaries.Rules');
        default:
          return intl('Static.FourOhFour.PageNotFound');
      }
    },

    getAppGroupTitleFromPath(path) {
      const appGroupType = path.split('/')[3];

      switch (appGroupType) {
        case 'workloads':
          return intl('AppGroups.Workloads');
        case 'rules':
          return intl('AppGroups.Rules');
        case 'containerworkloads':
          return intl('AppGroups.ContainerWorkloads');
        case 'pairingprofiles':
          return intl('AppGroups.PairingProfiles');
        case 'vulnerabilities':
          return intl('AppGroups.Vulnerabilities');
        case 'virtualservices':
          return intl('AppGroups.VirtualServices');
        case 'virtualservers':
          return intl('AppGroups.VirtualServers');
        case 'explorer':
          return intl('AppGroups.Explorer');
        case 'policygenerator':
          return intl('AppGroups.PolicyGenerator');
      }
    },

    getListRouteFromPath(path) {
      const route = path.split('/')[1];

      switch (route) {
        case 'pairingprofiles':
          return 'pairingProfiles.list';
        case 'groups':
        case 'groupwizard':
          return 'map';
        case 'virtualservices':
          return 'virtualServices.list';
        case 'provision':
        case 'revert':
          return 'pending';
        case 'virtualservers':
          return 'virtualServers.list';
        case 'loadbalancers':
          return 'loadBalancers.list';
        case 'pending':
          return 'landing';
        case 'labelgroups':
          return 'labelGroups.list';
        case 'versions':
          return 'versions.list';
        case 'usergroups':
          return 'userGroups';
        case 'secureconnectgateways':
          return 'secureGateways.list';
        case 'blockedtraffic':
          return 'blockedTraffic';
        case 'containerclusters':
          return 'containerclusters.list';
        case 'containerworkloads':
          return 'workloads.containers.list';
        case 'iplists':
          return 'iplists.list';
        case 'services':
          return 'services.list';
        case 'rulesets':
          return 'rulesets.list';
        case 'labels':
          return 'labels.list';
      }

      return route;
    },

    getRouteWithoutAppPrefix(path = '') {
      if (path.startsWith('app.')) {
        return path.slice(4);
      }

      return path;
    },
    isCreate(path) {
      if (path.includes('appcontainerwizard')) {
        return true;
      }

      return path.includes('create');
    },
  },

  ipLists: {
    getHref(id, version) {
      return getSessionUri(getInstanceUri('ip_lists'), {
        ip_list_id: id,
        pversion: version,
      });
    },

    diff(oldList, newList) {
      if (!oldList && !newList) {
        return null;
      }

      const redLines = newList ? _.cloneDeep(newList) : _.cloneDeep(oldList);

      let type = 'old';

      if (oldList && newList) {
        redLines.nameType = oldList.name === newList.name ? 'old' : 'new';
        redLines.descriptionType = oldList.description === newList.description ? 'old' : 'new';
      } else {
        // If only one list is valid choose the default type
        type = newList ? 'new' : 'old';
        redLines.nameType = type;
        redLines.descriptionType = type;
      }

      // Set the ip_range types
      redLines.ip_ranges.map(redRange => {
        redRange.type = type;

        // If not found in the old list, mark it new
        if (oldList && !_.some(oldList.ip_ranges, oldRange => IpUtils.areIpRangesEqual(oldRange, redRange))) {
          redRange.type = 'new';
        }

        return redRange;
      });

      // Add deleted ranges and mark as state = removed
      if (oldList && newList) {
        oldList.ip_ranges.forEach(oldRange => {
          // Old range is not found in the new set of ranges add it to the front of the red-lines
          if (!_.some(redLines.ip_ranges, redRange => IpUtils.areIpRangesEqual(oldRange, redRange))) {
            const deletedRange = oldRange;

            deletedRange.type = 'old';
            deletedRange.removed = true;
            redLines.ip_ranges.unshift(deletedRange);
          }
        });
      }

      if (redLines.fqdns) {
        redLines.fqdns.map(redFqdn => {
          redFqdn.type = type;

          // If not found in the old list, mark it new
          if (oldList && !_.some(oldList.fqdns, oldFqdn => oldFqdn.fqdn === redFqdn.fqdn)) {
            redFqdn.type = 'new';
          }

          return redFqdn;
        });
      }

      // Add deleted fqdns and mark as state = removed
      if (oldList && newList) {
        oldList.fqdns.forEach(oldFqdn => {
          // Fqdn is not found in the new set of fqdns add it to the front of the red-lines
          if (!_.some(redLines.fqdns, redFqdn => oldFqdn.fqdn === redFqdn.fqdn)) {
            const deletedFqdn = oldFqdn;

            deletedFqdn.type = 'old';
            deletedFqdn.removed = true;
            redLines.fqdns.unshift(deletedFqdn);
          }
        });
      }

      return redLines;
    },
  },

  services: {
    getHref(id, version) {
      return getSessionUri(getInstanceUri('services'), {
        service_id: id,
        pversion: version,
      });
    },

    diff(oldServices, newServices) {
      if (!oldServices && !newServices) {
        return null;
      }

      const redLines = newServices ? _.cloneDeep(newServices) : _.cloneDeep(oldServices);

      let type = 'old';

      if (oldServices && newServices) {
        redLines.nameType = oldServices.name === newServices.name ? 'old' : 'new';
        redLines.descriptionType = oldServices.description === newServices.description ? 'old' : 'new';
      } else {
        // If only one list is valid choose the default type
        type = newServices ? 'new' : 'old';
        redLines.nameType = type;
        redLines.descriptionType = type;
      }

      // Set the service_port types
      if (redLines.service_ports) {
        redLines.service_ports.map(redPortRange => {
          redPortRange.type = type;

          // If not found in the old list, mark it new
          if (oldServices && oldServices.service_ports) {
            if (
              oldServices &&
              !_.some(oldServices.service_ports, oldPortRange =>
                DataValidationUtils.services.arePortRangesEqual(oldPortRange, redPortRange),
              )
            ) {
              redPortRange.type = 'new';
            }
          } else {
            redPortRange.type = 'new';
          }

          return redPortRange;
        });
      }

      // Set the windows_services types
      if (redLines.windows_services) {
        redLines.windows_services.map(redPortRange => {
          redPortRange.type = type;

          // If not found in the old list, mark it new
          if (oldServices && oldServices.windows_services) {
            if (
              oldServices &&
              !_.some(oldServices.windows_services, oldPortRange =>
                DataValidationUtils.services.arePortRangesEqual(oldPortRange, redPortRange),
              )
            ) {
              redPortRange.type = 'new';
            }
          } else {
            redPortRange.type = 'new';
          }

          return redPortRange;
        });
      }

      // Add deleted ranges and mark as state = removed
      let deletedPortRange;

      if (!redLines.service_ports) {
        redLines.service_ports = [];
      }

      if (!redLines.windows_services) {
        redLines.windows_services = [];
      }

      if (oldServices && newServices) {
        if (oldServices.service_ports) {
          oldServices.service_ports.forEach(oldPortRange => {
            // Old range is not found in the new set of ranges add it to the front of the red-lines
            if (
              !_.some(redLines.service_ports, redPortRange =>
                DataValidationUtils.services.arePortRangesEqual(oldPortRange, redPortRange),
              )
            ) {
              deletedPortRange = oldPortRange;
              deletedPortRange.type = 'old';
              deletedPortRange.removed = true;
              redLines.service_ports.unshift(deletedPortRange);
            }
          });
        }

        if (oldServices.windows_services) {
          oldServices.windows_services.forEach(oldPortRange => {
            // Old range is not found in the new set of ranges add it to the front of the red-lines
            if (
              !_.some(redLines.windows_services, redPortRange =>
                DataValidationUtils.services.arePortRangesEqual(oldPortRange, redPortRange),
              )
            ) {
              deletedPortRange = oldPortRange;
              deletedPortRange.type = 'old';
              deletedPortRange.removed = true;
              redLines.windows_services.unshift(deletedPortRange);
            }
          });
        }
      }

      return redLines;
    },
  },

  virtualServers: {
    getFriendlyMode(mode) {
      switch (mode) {
        case 'unmanaged':
          return intl('Common.NotEnforced');
        case 'enforced':
          return intl('Common.Enforced');
      }
    },

    getFriendlyModeDesc(mode) {
      switch (mode) {
        case 'unmanaged':
          return intl('VirtualServers.Detail.NotEnforcedDesc');
        case 'enforced':
          return intl('VirtualServers.Detail.EnforcedDesc');
      }
    },
  },
};
