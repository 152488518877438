/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';

const DEFAULT_TIMESPAN_DAYS = 90;
let maxExplorerQueryTimespanDays = DEFAULT_TIMESPAN_DAYS;
let advancedRuleWriting = false;

export default createApiStore(['SETTINGS_GET_'], {
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SETTINGS_GET_SUCCESS:
        maxExplorerQueryTimespanDays = action?.data?.max_explorer_query_timespan_days ?? DEFAULT_TIMESPAN_DAYS;
        advancedRuleWriting = Boolean(action?.data?.advanced_ruleset_display);
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getExplorerMaxQueryTimespan() {
    return maxExplorerQueryTimespanDays;
  },

  getAdvancedRuleWriting() {
    return !__ANTMAN__ && advancedRuleWriting;
  },
});
