/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import {Link} from 'react-router';
import Constants from '../../constants';
import {getSessionUri, getInstanceUri} from '../../lib/api';
import actionCreators from '../../actions/actionCreators';
import {RuleGrid, Navbar, SpinnerOverlay, Pagination} from '../../components';
import {ToolBar, ToolGroup} from '../../components/ToolBar';
import {RouterMixin, StoreMixin, UserMixin, PolicyGeneratorMixin} from '../../mixins';
import {
  SessionStore,
  GraphStore,
  TrafficStore,
  GeneralStore,
  RuleSearchStore,
  RulesetStore,
  PairingProfileStore,
  VirtualServerStore,
  ContainerWorkloadStore,
  VirtualServiceStore,
  OrgStore,
  EssentialServiceRulesStore,
  WorkloadStore,
} from '../../stores';
import {
  GridDataUtils,
  RestApiUtils,
  RulesetUtils,
  RenderUtils,
  GraphDataUtils,
  GroupDataUtils,
  WorkloadUtils,
  PolicyGeneratorUtils,
} from '../../utils';
import {GroupTabs, AppGroupTabs} from '.';

const groupMatches = (ref, group) =>
  !group.href.includes('discover') && group.href.split('x').every(id => ref.includes(id));

const matchingScope = (scope, group) =>
  scope.length === group.labels.length &&
  scope.every(label =>
    group.labels.find(
      groupLabel =>
        (!label.exclusion && groupLabel.href === (label.label || label.label_group)?.href) ||
        (label.exclusion && groupLabel.href !== (label.label || label.label_group)?.href),
    ),
  );

const numberMatchingLabels = (scope, group) =>
  scope.reduce(
    (result, label) =>
      result + (group.labels.some(groupLabel => groupLabel.href === (label.label && label.label.href)) ? 1 : 0),
    0,
  );

const policyGeneratorRuleset = (ruleset, group) =>
  (ruleset.external_data_set === 'illumio_rule_builder' || ruleset.external_data_set === 'illumio_policy_generator') &&
  groupMatches(ruleset.external_data_reference, group);

const segmentationTemplate = ruleset => ruleset.external_data_set === 'illumio_segmentation_templates';

const sortRulesets = (rulesets, group) =>
  rulesets.sort((a, b) => {
    const bSort =
      b.scopes[0].length +
      (policyGeneratorRuleset(b, group) ? 100 : 0) +
      50 * matchingScope(b.scopes[0], group) +
      10 * numberMatchingLabels(b.scopes[0], group) -
      (!b.enabled ? 75 : 0) -
      (segmentationTemplate(b) ? 100 : 0);

    const aSort =
      a.scopes[0].length +
      (policyGeneratorRuleset(a, group) ? 100 : 0) +
      50 * matchingScope(a.scopes[0], group) +
      10 * numberMatchingLabels(a.scopes[0], group) -
      (!a.enabled ? 75 : 0) -
      (segmentationTemplate(a) ? 100 : 0);

    return bSort - aSort;
  });

function isMatchingEndpoint(endpoints, labels) {
  return (
    endpoints.some(endpoint => endpoint.actors === 'ams') ||
    endpoints.every(endpoint => labels.includes(endpoint.label?.href))
  );
}

function findRoles(members) {
  return members.reduce((result, member) => {
    const role = member.labels.find(label => label.key === 'role');

    if (role) {
      result.add(role.href);
    }

    return result;
  }, new Set());
}

function getStateFromStores() {
  const {group, groupLabelHrefs} = this.getGroupLabelHrefs();
  const groupRulesType = this.getGroupRulesType();
  let rules = RuleSearchStore.getRules().map(RulesetUtils.addUsageEntities);

  const workloads = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, WorkloadStore.getAll());
  const virtualServers = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServerStore.getAll());
  const pairingProfiles = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, PairingProfileStore.getAll());
  let containerWorkloads = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, ContainerWorkloadStore.getAll());
  let virtualServices = WorkloadUtils.getGroupWorkloads(groupLabelHrefs, VirtualServiceStore.getAll().draft);

  const roles = [
    ...findRoles(workloads),
    ...findRoles(virtualServices),
    ...findRoles(virtualServers),
    ...findRoles(containerWorkloads),
  ];

  rules = rules.filter(rule => {
    const scopeMatch = rule.rule_set.scopes.some(
      scope => scope.length !== 0 && scope.every(label => groupLabelHrefs.includes(label.label?.href)),
    );

    const labels = [...roles, ...groupLabelHrefs];
    const extraScopeMatch = rule.unscoped_consumers && isMatchingEndpoint(rule.consumers, labels);

    const globalScopeMatch =
      rule.rule_set.scopes.some(scope => scope.length === 0) &&
      (isMatchingEndpoint(rule.consumers, labels) || isMatchingEndpoint(rule.providers, labels));

    return scopeMatch || extraScopeMatch || globalScopeMatch;
  });

  // Find the workloads in a discovery group defined by the traffic
  if (group && group.discovered) {
    containerWorkloads = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const containerWorkload = ContainerWorkloadStore.getSpecified(href);

        if (containerWorkload) {
          result.push(containerWorkload);
        }
      },
      [],
    );

    virtualServices = _.transform(
      group.nodesHrefs,
      (result, href) => {
        const virtualService = VirtualServiceStore.getSpecified(href);

        if (virtualService) {
          result.push(virtualService);
        }
      },
      [],
    );
  }

  let rulesets =
    groupRulesType === 'incomplete'
      ? RulesetStore.getAll().filter(
          ruleset => RulesetUtils.isRulesetInApplication(ruleset, group) && !ruleset.disabled,
        )
      : Object.values(
          rules.reduce((result, rule) => {
            if (result[rule.rule_set.href]) {
              result[rule.rule_set.href].rules.push(rule);

              return result;
            }

            result[rule.rule_set.href] = {
              ...rule.rule_set,
              rules: [rule],
            };

            return result;
          }, {}),
        );

  let exactScopeRulesets = [];

  if (group && this.state) {
    const {policyGeneratorRuleset} = this.state;

    // Disabled/Deleted Rulesets will not come back in the API, so force the Policy Generator ruleset to display
    if (policyGeneratorRuleset && !rulesets.some(ruleset => ruleset.href === policyGeneratorRuleset.href)) {
      rulesets.unshift(policyGeneratorRuleset);
    }

    // Move policy generator rulesets to the top, ruleset to the bottom, otherwise sort by number of scopes matching
    rulesets = sortRulesets(rulesets, group);

    if (this.state && this.state.groupType === 'appgroups') {
      exactScopeRulesets = rulesets.filter(ruleset => {
        const scopeIds = ruleset.scopes[0].map(label => label.label && label.label.href.split('/').pop());
        const groupIds = group.href.split('x');

        return (
          ruleset.scopes.length === 1 && scopeIds.length === groupIds.length && !_.difference(scopeIds, groupIds).length
        );
      });
    }
  }

  return {
    group,
    rulesets,
    exactScopeRulesets,
    virtualServices,
    virtualServers,
    containerWorkloads,
    pairingProfiles,
    essentialServiceRules: EssentialServiceRulesStore.getRules(),
    status: [RulesetStore.getStatus, RuleSearchStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [
    RouterMixin,
    UserMixin,
    PolicyGeneratorMixin,
    StoreMixin([RulesetStore, RuleSearchStore, EssentialServiceRulesStore], getStateFromStores),
  ],

  getInitialState() {
    const {groupHref, groupLabelHrefs} = this.getGroupLabelHrefs();
    const sorting = GeneralStore.getSorting('groupRules');
    const groupType = GroupDataUtils.getType(this.getPathname());

    return {
      groupType,
      groupHref,
      groupLabelHrefs,
      sorting: sorting || [],
      groupExists: true,
      providerConsumerOrder: OrgStore.providerConsumerOrder(),
    };
  },

  async componentDidMount() {
    RestApiUtils.user.orgs({representation: 'org_permissions'}, SessionStore.getUserId(), true);

    this.mapLevel = await GraphDataUtils.getMapLevelByTotalWorkloads();

    const {groupHref, vulnerabilitiesEnabled, groupType} = this.state;
    const {group, caps} = await GroupDataUtils.getGroup(
      this.state.group,
      groupHref,
      groupType,
      vulnerabilitiesEnabled,
      true,
    );
    const groupExists = !_.isEmpty(group);

    let policyGeneratorRuleset;

    if (groupExists && this.state.groupType === 'appgroups') {
      policyGeneratorRuleset = await this.getPolicyGeneratorRuleset(group);
    }

    this.setState({groupExists, caps, policyGeneratorRuleset});

    //Ensure the policy generator ruleset is loaded before sorting these
    this.getRulesets(this.state.groupLabelHrefs);

    if (group && group.discovered) {
      this.setState(() => {
        /* Set the proper count for "Discovered" group since the API doesn't return a count header. */
        const count = {...this.state.count, matched: group.nodesHrefs.length};

        return {count};
      });
    }

    GroupDataUtils.getEssentialServiceRules();
  },

  onSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('groupRules', sorting);
    this.setState({sorting});
  },

  async getRulesets() {
    let groupLabels = {};

    // Don't load labels for any discovered type of group
    if (!this.getParams().id.includes('discover')) {
      // Find the keys for all the labels in the group's scope
      const results = await Promise.all(
        this.getParams()
          .id.split('x')
          .map(id => RestApiUtils.labels.getInstance(id, false, true)),
      );

      if (this.getGroupRulesType() === 'complete') {
        // Create an object for the labels
        groupLabels = results.reduce((result, label) => {
          result.push({label: {href: label.body.href}});

          return result;
        }, []);

        RestApiUtils.ruleSearch.getCollection({providers_or_consumers: groupLabels, resolve_actors: true});

        return;
      }

      groupLabels = results.reduce((result, label) => {
        result[label.body.key] = label.body.href;

        return result;
      }, {});
    }

    RestApiUtils.ruleSets.getGroupCollection(groupLabels, 'rule_set_services_labels_and_names');

    this.setState({rulesetsLoaded: true});
  },

  getGroupRulesType() {
    const groupType = GroupDataUtils.getType(this.getPathname());

    if (groupType === 'appgroups' || this.getParams().id.split('x').length === 3) {
      return 'complete';
    }

    // For Groups with less than 3 labels, we have to use the old ruleset query
    // Once the rulesearch api supports 'no_exist' for labels we can remove this
    return 'incomplete';
  },

  getGroupLabelHrefs() {
    const groupHref = this.getParams().id;
    const groupLabelHrefs = groupHref.split('x').reduce((result, labelId) => {
      if (labelId !== 'discovered') {
        const labelHref = getSessionUri(getInstanceUri('labels'), {label_id: labelId});

        result.push(labelHref);
      }

      return result;
    }, []);

    let group = TrafficStore.getNode(groupHref);

    if (groupHref.includes('discovery')) {
      // The discovery groups are built based on traffic, and are calculated in the graph store
      // This will not work if these pages are reloaded with out loading illumination first
      group = GraphStore.getCluster(groupHref);
    }

    return {groupHref, groupLabelHrefs, group};
  },

  async handleMakePolicyGeneratorRuleset(ruleset) {
    let rulesets = [...this.state.rulesets];
    let current = this.state.policyGeneratorRuleset ? {...this.state.policyGeneratorRuleset} : null;

    // If another ruleset is being used by the Policy Generator, remove the markers
    if (current) {
      current = {
        ...current,
        external_data_reference: null,
        external_data_set: null,
      };

      // Update the rulesets array with the new value
      rulesets[rulesets.findIndex(set => current.href === set.href)] = current;

      await RestApiUtils.ruleSet.updateClean(
        current.href.split('/').pop(),
        PolicyGeneratorUtils.getStrippedRuleset(current),
      );
    }

    // Set the markers on this ruleset
    const newRuleset = {
      ...ruleset,
      external_data_set: 'illumio_policy_generator',
      external_data_reference: this.state.group.href.split('x').join(' | '),
    };

    // Update the rulesets array with the new value
    rulesets[rulesets.findIndex(set => newRuleset.href === set.href)] = newRuleset;

    await RestApiUtils.ruleSet.updateClean(
      newRuleset.href.split('/').pop(),
      PolicyGeneratorUtils.getStrippedRuleset(newRuleset),
    );

    const policyGeneratorRuleset = await this.getPolicyGeneratorRuleset(this.state.group);

    rulesets = sortRulesets(rulesets, this.state.group);

    this.setState({policyGeneratorRuleset, rulesets});
  },

  render() {
    const {groupType, group, groupHref, groupExists, providerConsumerOrder, essentialServiceRules} = this.state;
    const listPage = sessionStorage.getItem('app_group_list') === 'recents' ? {route: 'appMap'} : {route: 'appGroups'};

    if (!groupExists && !group) {
      this.replaceWith(groupType === 'appgroups' ? listPage.route : 'map');
    } else if (!group) {
      return this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null;
    }

    const grids = [];
    let ruleCount = 0;
    let title;

    if (group) {
      title = RenderUtils.truncateAppGroupName(
        _.sortBy(this.state.group.labels, 'key')
          .map(label => label.value)
          .join(' | '),
        45,
        [30, 15, 10],
      );
    }

    const rulesets = [...this.state.rulesets, ...essentialServiceRules];

    _.forEach(rulesets, ruleset => {
      let policyGenerator = null;

      if (!ruleset?.rules?.length) {
        return;
      }

      const status = ruleset.enabled || !ruleset.hasOwnProperty('enabled') ? '' : ` [${intl('Common.Disabled')}]`;

      const routeParams = ruleset.href
        ? {
            ...(ruleset.href.endsWith('essential_service_rules')
              ? {}
              : {id: GridDataUtils.getIdFromHref(ruleset.href)}),
            pversion: 'draft',
          }
        : null;

      const scopes = RenderUtils.getScope(ruleset);
      const {policyGeneratorRuleset, exactScopeRulesets} = this.state;

      if (policyGeneratorRuleset && policyGeneratorRuleset.href === ruleset.href) {
        policyGenerator = <div className="GroupRules-generator">{intl('PolicyGenerator.PolicyGeneratorRuleset')}</div>;
      } else if (exactScopeRulesets.some(exact => exact.href === ruleset.href)) {
        policyGenerator = (
          <div className="GroupRules-makegenerator" onClick={_.partial(this.handleMakePolicyGeneratorRuleset, ruleset)}>
            {intl('PolicyGenerator.MakePolicyGeneratorRuleset')}
          </div>
        );
      } else if (
        ruleset.external_data_set === 'illumio_policy_generator' ||
        ruleset.external_data_set === 'illumio_rule_builder'
      ) {
        policyGenerator = <div className="GroupRules-generated">{intl('PolicyGenerator.IllumioGenerated')}</div>;
      }

      ruleCount += ruleset.rules.length;

      const rulesetName = RenderUtils.truncateAppGroupName(ruleset.name, 120, [60, 30, 30]) + status;

      const tid = !routeParams.id && (ruleset.name.endsWith('Outbound') ? 'outbound' : 'inbound');

      grids.push(
        <div className="GroupRules-Ruleset-Rules">
          <div className="GroupRules-Ruleset">
            {ruleset.href ? (
              <Link
                className="Toolbar-link"
                to={routeParams.id ? 'rulesets.item' : 'essentialservicerules'}
                params={routeParams.id ? {...routeParams, tab: 'intrascope'} : routeParams}
                data-tid={routeParams.id ? 'ruleset-link' : 'essentialservicerules-link'}
              >
                {rulesetName}
              </Link>
            ) : (
              rulesetName
            )}
            {scopes}
            {policyGenerator}
          </div>
          <RuleGrid
            rules={ruleset.rules}
            version="draft"
            rulesetEnabled={ruleset.enabled}
            extraScopeEnabled
            providerConsumerOrder={providerConsumerOrder}
            tid={tid}
          />
        </div>,
      );
    });

    const mapRoute = GroupDataUtils.getMapRoute(group, groupHref, this.mapLevel, this.state.groupType);
    const tabs = GroupDataUtils.getTabs(this.state);
    const appGroups = this.state.groupType === 'appgroups';

    return (
      <div className="GroupRulesets" data-tid="page-appcontainer-rulesets">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        <Navbar title={title} type="detail" up={appGroups || !mapRoute ? listPage : mapRoute} />
        <div className="GroupBar">
          {appGroups ? (
            <AppGroupTabs active="rules" mapRoute={mapRoute} />
          ) : (
            <GroupTabs active="rules" tabs={tabs} group={group} />
          )}
        </div>
        {Boolean(grids.length) && (
          <ToolBar>
            <ToolGroup />
            {ruleCount ? (
              <ToolGroup tid="pagination">
                <Pagination
                  page={1}
                  totalRows={ruleCount}
                  // Everything is on one page
                  pageLength={1_000_000}
                />
              </ToolGroup>
            ) : null}
          </ToolBar>
        )}
        {grids}
      </div>
    );
  },
});
