/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import Constants from '../constants';
import Spinner from './Spinner.jsx';
import ObjectSelector from './ObjectSelector.jsx';
import {RestApiUtils, ComponentUtils} from '../utils';
import {LabelsAutocompleteStore} from '../stores';
import {StoreMixin, UserMixin} from '../mixins';
import actionCreators from '../actions/actionCreators';
import {sortAndStringifyArray} from 'utils/general';

const allLabelMap = () => ({
  app: intl('Common.AllApplications'),
  env: intl('Common.AllEnvironments'),
  loc: intl('Common.AllLocations'),
  role: intl('Common.AllRoles'),
});

function getStateFromStores() {
  return {
    dropdownValues: LabelsAutocompleteStore.getDropdownValues(),
  };
}

export default React.createClass({
  propTypes: {
    all: PropTypes.bool,
    allowCreate: PropTypes.bool,
    allowLabel: PropTypes.bool,
    allowGroup: PropTypes.bool,
    allowMultiSelect: PropTypes.bool,
    defaultSelected: PropTypes.bool,
    selected: PropTypes.array,
    onChange: PropTypes.func,
    onDirectAdd: PropTypes.func,
    placeholder: PropTypes.string,
    singleSelect: PropTypes.oneOf([intl('Common.Labels'), intl('Labels.Group')]),
    tabIndex: PropTypes.string,
    type: PropTypes.string,
    statics: PropTypes.object,
    autoFocus: PropTypes.bool,
    selectedLabels: PropTypes.array,
    resourceType: PropTypes.string,
  },

  mixins: [StoreMixin(LabelsAutocompleteStore, getStateFromStores), UserMixin],

  getDefaultProps() {
    return {
      all: false,
      allowCreate: true,
      allowLabel: true,
      onChange: _.noop,
      placeholder: intl('Labels.Select'),
      type: 'role',
      selectedLabels: [],
    };
  },

  getInitialState() {
    const stateObj = {items: {}};

    // Label Group doesnt cache dropdownvalues
    if (this.props.lgOmit) {
      stateObj.unCachedDropdownValues = {};
    }

    return stateObj;
  },

  componentDidMount() {
    if (this.props.allowLabel || this.props.defaultSelected) {
      this.getFacetValues('labels-' + this.props.type);
    }

    if (this.props.allowGroup) {
      this.getFacetValues('labelGroups-' + this.props.type);
    }

    this.setRuleItems(this.props);
  },

  componentWillReceiveProps(nextProps) {
    // refresh the component with the latest passed values
    if (this.isMounted() && !_.isEqual(this.props, nextProps)) {
      this.setRuleItems(nextProps);
    }
  },

  componentDidUpdate(prevProps) {
    if (sortAndStringifyArray(prevProps.selectedLabels) !== sortAndStringifyArray(this.props.selectedLabels)) {
      if (this.props.allowLabel) {
        this.getFacetValues('labels-' + this.props.type);
      }

      if (this.props.allowGroup) {
        this.getFacetValues('labelGroups-' + this.props.type);
      }
    }
  },

  getCustomClass(value) {
    let additionalClass = '';

    if (value && value.key) {
      additionalClass = 'ObjectSelector-item--label--' + value.key;
    }

    return additionalClass;
  },

  getFacetValues(facet = 'labels-' + this.props.type, query = '', maxResults = 25) {
    if (!facet && !this.props.singleSelect) {
      return;
    }

    const reqObj = {
      key: this.props.type,
      query,
      max_results: maxResults,
    };

    // if label group, we pass a label_group href so the autocomplete API returns
    // values only relevant and legal to that particular for that label group
    // works for both labels and labelGroups
    if (this.props.lgOmit) {
      reqObj.label_group = this.props.lgOmit;
    }

    // if label group, make sure we don't have that value already
    // we still do this since the user can select intl('Common.Labels'), without adding any value, go to intl('Labels.Group')
    // still without adding a value, and when coming back to intl('Common.Labels'), there wouldn't be an extra API call
    if (this.props.lgOmit && this.state.unCachedDropdownValues[facet + '-' + query]) {
      return;
    }

    if (this.props.type === 'all') {
      delete reqObj.key;
    }

    // facets at this points are like 'labels-role', 'labelGroups-role',
    // we are interested in the first half for RestApiUtils
    facet = facet.includes('-') ? facet.split('-')[0] : facet;

    // Only label API supports resource type.

    if (this.props.selectedLabels.length > 0) {
      reqObj.selected_scope = JSON.stringify(this.props.selectedLabels);
    }

    if (this.props.resourceType) {
      reqObj.resource_type = this.props.resourceType;
    }

    _.defer(() => {
      RestApiUtils[facet].autocomplete(null, reqObj).then(response => {
        // since label group doesn't use the store, set the unCachedDropdownValues for lable group
        if (this.props.lgOmit) {
          const unCachedDropdownValues = _.cloneDeep(this.state.unCachedDropdownValues);

          unCachedDropdownValues[facet + '-' + this.props.type + '-' + query] = response.body;
          this.setState({unCachedDropdownValues});
        }
      });
    });
  },

  // for labelGroups, reset the values for dropdown autocomplete
  // if it is single select, refetch the data
  setUncachedDropdownValues() {
    const {singleSelect, type, lgOmit, allowGroup} = this.props;
    const unCachedDropdownValues = {};

    if (singleSelect) {
      const reqObj = {
        key: type,
        label_group: lgOmit,
        max_results: 25,
      };
      const facet = allowGroup ? 'labelGroups' : 'labels';

      RestApiUtils[facet].autocomplete(null, reqObj).then(response => {
        unCachedDropdownValues[facet + '-' + type + '-'] = response.body;
        this.setState({unCachedDropdownValues});
      });
    }

    this.setState({unCachedDropdownValues});
  },

  setRuleItems(props) {
    // if user selects which type of label member to add, fetch data to populate
    if (this.props.singleSelect !== props.singleSelect) {
      if (props.allowGroup) {
        this.getFacetValues('labelGroups-' + props.type);
      } else {
        this.getFacetValues('labels-' + props.type);
      }
    }

    // This kinda needs to be in state because the parent component sends an array and a change handler
    const items = {};

    // Populate the component with default values received
    // Only one value supported at the moment..
    if (props.selected) {
      props.selected.forEach(item => {
        const newSelected = item;

        if (newSelected.type && newSelected.type === 'all') {
          items[newSelected.value] = newSelected;
        } else if (this.props.allowMultiSelect) {
          if (newSelected.value) {
            items[intl('Common.Labels')] = items[intl('Common.Labels')] || [];
            items[intl('Common.Labels')].push(newSelected);
          } else if (newSelected.name) {
            items[intl('Labels.Group')] = items[intl('Labels.Group')] || [];
            items[intl('Labels.Group')].push(newSelected);
          }
        } else if (props.selected.length === 1) {
          if (newSelected.value) {
            items[intl('Common.Labels')] = newSelected;
          } else if (newSelected.name) {
            items[intl('Labels.Group')] = newSelected;
          }
        }
      });

      // If the user had selected this field, but not chosen a value yet, the incoming props wont contain it
      if (!this.props.defaultSelected) {
        [intl('Common.Labels'), intl('Labels.Group')].forEach(item => {
          if (this.state.items[item] === null && !items[item]) {
            items[item] = null;
          }
        });
      }
    }

    this.setState({items});
  },

  addItem(item, value) {
    if (this.props.lgOmit && value) {
      this.setUncachedDropdownValues();
    }

    this.setUncachedDropdownValues();

    if (this.props.onDirectAdd) {
      this.props.onDirectAdd(item, value);
    }

    let items = _.cloneDeep(this.state.items);

    // i.e. the Labels Select for Scopes
    let removeItemKey = this.props.defaultSelected;

    if (this.props.statics) {
      removeItemKey = this.props.defaultSelected && value;
    }

    if (removeItemKey) {
      Object.keys(items).forEach(key => {
        if (key !== item) {
          delete items[key];
        }
      });
    }

    if (!value && !items[item]) {
      items[item] = null;
    } else if (value) {
      if (!this.props.allowMultiSelect && Object.keys(items)) {
        items = {};
      }

      if (this.props.allowMultiSelect) {
        items[item] = items[item] || [];
        items[item].push(value);
      } else {
        items[item] = value;
      }

      this.props.onChange(value);
    }

    this.setState({items});
  },

  createLabel(value, active, cb) {
    const isSingleSelect = (!this.props.all && !this.props.allowGroup) || !this.props.allowLabel;

    this.setState({
      status: Constants.STATUS_BUSY,
    });

    if (this.props.defaultSelected && active === 'initial') {
      active = intl('Common.Labels');
    }

    RestApiUtils.labels
      .create(
        {
          key: this.props.type,
          value,
        },
        true,
      )
      .then(response => {
        // add the newly created labels
        if (isSingleSelect) {
          // for singleSelect, active would be 'initial', which is not correct
          active = intl('Common.Labels');
        }

        this.addItem(active, {
          value: response.body.value,
          href: response.body.href,
          key: response.body.key,
        });
        // this callback functions notifies ObjectSelector the item has been added
        cb();
        // since the new label is created, some values need to be purged
        actionCreators.resetFacetValues('labelList', 'labels' + '-' + this.props.type);
      })
      .catch(_.noop)
      .then(() => {
        this.setState({
          status: Constants.STATUS_IDLE,
        });
        // get fresh facet values (since above code purges it)
        this.getFacetValues('labels-' + this.props.type);
        // Force refresh the list page so that it now shows the added labels as well
        actionCreators.forceListPageRefresh('labelList', true);
      });
  },

  removeItem(item) {
    const items = _.cloneDeep(this.state.items);

    this.props.onChange(null, items[item]);
    delete items[item];

    this.setState({items});

    if (this.props.lgOmit) {
      this.setUncachedDropdownValues();
    }
  },

  removeMulti(item, singleItem) {
    const items = _.cloneDeep(this.state.items);

    if (singleItem) {
      this.props.onChange(undefined, singleItem);
      items[item].splice(items[item].indexOf(singleItem), 1);
    } else if (items[item]) {
      this.props.onChange(undefined, items[item].pop());
    }

    if (items[item] && !items[item].length) {
      delete items[item];
    }

    this.setState({items});

    if (this.props.lgOmit) {
      this.setUncachedDropdownValues();
    }
  },

  render() {
    const hasItems = !_.isEmpty(this.state.items);
    const isSingleSelect = (!this.props.all && !this.props.allowGroup) || !this.props.allowLabel;
    const {addItem, getFacetValues, removeItem, removeMulti, getCustomClass} = this;
    const tids = ComponentUtils.tid('comp-labelselect', this.props.tid);
    const props = {
      tabIndex: this.props.tabIndex,
      placeholder: this.props.placeholder,
      items: this.state.items,
      dropdownValues: this.state[this.props.lgOmit ? 'unCachedDropdownValues' : 'dropdownValues'],
      ref: 'objectSelector',
      initialValues: [],
      customClassItems: [],
      returnValue: item => (item ? item.value || item.name : null),
      facetMap: {},
      getCustomClass,
      addItem,
      getFacetValues,
      removeItem,
      removeMulti,
      hideItemsList: this.props.hideItemsList,
      showInput: this.props.showInput,
      onFocus: this.props.onFocus,
      onBlur: this.props.onBlur,
      error: this.props.error,
      disabled: this.props.disabled,
      autoFocus: this.props.autoFocus,
      statics: this.props.statics,
      maxResults: this.props.maxResults,
    };
    let allowCreateKey;

    if (
      !this.props.allowMultiSelect &&
      (this.state.items[intl('Common.Labels')] ||
        this.state.items[intl('Labels.Group')] ||
        this.state.items[allLabelMap()[this.props.type]])
    ) {
      // If an item is already selected, never show the placeholder text
      props.placeholder = '';
    }

    if (!this.props.allowMultiSelect) {
      // It doesn't have to be a singleSelect to be allowOne
      // allowOne means they can only select any of the items out of the
      // options provided (Label, Label Group, etc)
      props.allowOne = true;
    }

    if (this.props.allowCreate && !this.isUserReadOnly()) {
      allowCreateKey = intl('Common.Labels');

      if (isSingleSelect) {
        // in singleSelect, there is only one 'initial' active state
        allowCreateKey = 'initial';
      }

      props.allowCreateItems = [allowCreateKey];
      props.allowCreateHint = {};
      props.allowCreateHint[allowCreateKey] = `(${intl('Labels.New')})`;
      props.onCreate = this.createLabel;
    }

    if (isSingleSelect) {
      props.singleSelect = this.props.allowLabel ? intl('Common.Labels') : intl('Labels.Group');
    }

    if (this.props.allowLabel) {
      props.facetMap[intl('Common.Labels')] = 'labels-' + this.props.type;
      props.initialValues.push(intl('Common.Labels'));
      props.customClassItems.push(intl('Common.Labels'), 'initial');
    }

    if (this.props.allowGroup) {
      props.facetMap[intl('Labels.Group')] = 'labelGroups-' + this.props.type;
      props.initialValues.push(intl('Labels.Group'));
      props.customClassItems.push(intl('Labels.Group'));

      if (!this.props.allowLabel) {
        props.customClassItems.push('initial');
      }
    }

    if (this.props.all && this.props.type) {
      // All means the 'All Applications', 'All Environments', etc values
      props.singleValues = {};
      props.singleValues[allLabelMap()[this.props.type]] = {
        key: this.props.type,
        type: 'all',
        value: allLabelMap()[this.props.type],
      };
      props.customClassItems.push(allLabelMap()[this.props.type]);
    }

    if (this.props.allowMultiSelect) {
      props.multiItems = [intl('Common.Labels')];

      if (this.props.allowGroup) {
        props.multiItems.push(intl('Labels.Group'));
      }
    }

    if (hasItems && !this.props.allowMultiSelect && !this.props.defaultSelected) {
      // if there are values selected, do not show other options
      props.initialValues = [];

      if (this.props.all && Object.keys(this.state.items)[0] !== allLabelMap()[this.props.type]) {
        // delete singleValues if the selected value is not an 'All ..' value
        // if a selected value is an 'All ..' value, we need to send it so that ObjectSelector knows
        // how to display it
        delete props.singleValues;
      }
    }

    if (this.props.defaultSelected) {
      props.defaultSelected = intl('Common.Labels');
    }

    if (this.props.statics) {
      props.staticsKeys = Object.keys(this.props.statics);
    }

    return (
      <div className="OSLabelSelect" data-tid={ComponentUtils.tidString(tids)}>
        {this.state.status === Constants.STATUS_BUSY && <Spinner size="xlarge" />}
        <ObjectSelector {...props} />
      </div>
    );
  },
});
