/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import _ from 'lodash';
import {GeneralUtils, GraphDataUtils, PolicyGeneratorUtils, RestApiUtils, ServiceUtils} from '..';
import {ExplorerUtils} from '.';
import {ExplorerStore, IpListStore, TrafficStore, ServiceStore} from '../../stores';
import {getId} from '../GeneralUtils';

const RULES_PER_CALL = 250;
const SERVICES_PER_MIN = 400;
const MAX_WORKLOADS_PER_RULE = 10;

function getScopeForExplorerRuleset(links) {
  return Object.values(
    links.reduce((result, link) => {
      ['app', 'env', 'loc'].forEach(key => {
        let labelForKey;

        if (link.dst_labels) {
          labelForKey = link.dst_labels.find(label => label.key === key);
        } else if (link.src_labels) {
          labelForKey = link.src_labels.find(label => label.key === key);
        }

        if (labelForKey) {
          if (!result.hasOwnProperty(key)) {
            result[key] = labelForKey;
          } else if (result[key]?.href !== labelForKey.href) {
            result[key] = null;
          }
        } else if (link.dst_labels) {
          result[key] = null;
        } else if (link.src_labels) {
          result[key] = null;
        }
      });

      return result;
    }, {}),
  ).filter(label => label);
}

async function loadExplorerPolicyData(params) {
  let trafficData;

  if (params.id && !GraphDataUtils.isSummaryDataLoaded('requested')) {
    await Promise.all([
      GraphDataUtils.getTraffic('location', {route: 'groups'}),
      GraphDataUtils.getTraffic('appGroup', {route: 'groups'}),
    ]);
  }

  if (ExplorerStore.getLoadedUuid() !== params.uuid) {
    try {
      if (params.id) {
        const labels = TrafficStore.getAppGroupNode(params.id)?.labels;

        trafficData = RestApiUtils.trafficFlows.async.getResults(params.uuid, {
          force: true,
          type: 'appGroup',
          href: params.id,
          labels: labels ? labels.map(label => label.href) : null,
        });
      } else {
        trafficData = RestApiUtils.trafficFlows.async.getResults(params.uuid, {force: true});
      }
    } catch {
      return;
    }
  }

  await Promise.all([
    trafficData,
    RestApiUtils.services.getCollection({max_results: 100_000}),
    RestApiUtils.ipLists.getInstance('any'),
  ]);

  const links = getLinks(params);
  const selected = JSON.parse(localStorage.getItem('selectedLinks')) || [];
  const selectedLinks = links.filter(link => selected.includes(link.linkKey));

  const newScopes = getScopeForExplorerRuleset(selectedLinks);

  let lastRuleset = localStorage.getItem('last_explorer_ruleset');

  if (lastRuleset) {
    lastRuleset = JSON.parse(lastRuleset);

    const lastHref = lastRuleset.href;
    let response;

    if (lastHref) {
      try {
        response = await RestApiUtils.ruleSets.getInstance(getId(lastRuleset?.href), 'draft');
      } catch {
        lastRuleset = null;
      }

      lastRuleset = response?.body;
    }

    if (lastRuleset?.scopes) {
      const allLabelGroups = await getAllLabelGroupLabels(lastRuleset.scopes);
      const expandedScopes = expandScope(lastRuleset.scopes[0], allLabelGroups);
      const scopesMatchTraffic =
        lastRuleset.enabled &&
        lastRuleset.update_type !== 'delete' &&
        areScopesMatchingTraffic(
          expandedScopes,
          newScopes.map(scope => ({label: scope})),
        );

      if (!scopesMatchTraffic) {
        lastRuleset = null;
      }
    }
  }

  return {newScopes, lastRuleset};
}

function areScopesMatchingTraffic(rulesetScopes, trafficScopes) {
  return rulesetScopes.some(scope => (!scope.length && trafficScopes.length) || isItemContained(trafficScopes, scope));
}

function getProposedRulesetFromPath(pathname) {
  return pathname.toLowerCase().includes('proposedrules');
}

function getProposedAppGroupRulesetFromPath(pathname) {
  return pathname.toLowerCase().includes('appgroups');
}

function getRuleEndpoint(endpoint, link, scope) {
  const labels = link[`${endpoint}_labels`];
  const workloadKey = `${endpoint}_workload`;
  const hrefKey = `${endpoint}_href`;
  const ipListKey = `${endpoint}_ip_lists`;
  // This will create a list of scope keys: ['app', 'env']
  const scopeKeys = scope.flat().map(scope => scope?.label?.key);

  if (labels && labels.length) {
    const endpointLabels = labels.filter(label => !scope || !scopeKeys.includes(label.key)).map(label => ({label}));

    return endpointLabels.length ? endpointLabels : [{actors: 'ams'}];
  }

  if (link[workloadKey]) {
    const workloads = Object.values(
      [...link.links].reduce((result, endpointLink) => {
        const href = endpointLink[hrefKey];

        if (href && !result[href]) {
          result[href] = {
            workload: {href: endpointLink[hrefKey], name: endpointLink[workloadKey]},
          };
        }

        return result;
      }, {}),
    );

    return workloads.length <= MAX_WORKLOADS_PER_RULE ? workloads : [{actors: 'ams'}];
  }

  if (link[ipListKey] && link[ipListKey].length) {
    // The first IP list is the most specific one
    return [{ip_list: link[ipListKey][0]}];
  }

  if (IpListStore.getAnyIpList()) {
    return [{ip_list: IpListStore.getAnyIpList()}];
  }

  return [];
}

function isItemContained(containingItems, items) {
  return (
    (!containingItems.length && !items.length) ||
    items.every(item =>
      containingItems.some(containingItem => {
        if (item.service_ports || item.windows_services || item.port || item.href?.includes('service')) {
          // For service items all the contained items should be in the containing set
          return isServiceContained(containingItem, item);
        }

        if (item.href) {
          return containingItem.href === item.href;
        }

        if (item.label) {
          return (
            (Boolean(containingItem.exclusion) === Boolean(item.exclusion) &&
              containingItem.label?.href === item.label.href) ||
            (containingItem.exclusion && containingItem.label?.href !== item.label.href)
          );
        }

        if (item.label_group) {
          return (
            (Boolean(containingItem.exclusion) === Boolean(item.exclusion) &&
              containingItem.label_group?.href === item.label_group.href) ||
            (containingItem.exclusion && containingItem.label_group?.href !== item.label_group.href)
          );
        }

        if (item.ip_list) {
          return containingItem.ip_list?.href === item.ip_list.href;
        }

        return _.isEqual(containingItem, item);
      }),
    )
  );
}

function isPortContained(containingPort, port) {
  if (containingPort.port === port.port) {
    return true;
  }

  if (containingPort.to_port && port.to_port) {
    return containingPort.port <= port.port && containingPort.to_port >= port.to_port;
  }

  if (containingPort.to_port) {
    return containingPort.port <= port.port && containingPort.to_port >= port.port;
  }

  return false;
}

function isServiceContained(containing, item) {
  const containingService = ServiceStore.getSpecified(containing.href) || containing;
  const service = ServiceStore.getSpecified(item.href) || item;

  if (containingService.href?.includes('all_services') || containingService.name === intl('Common.AllServices')) {
    return true;
  }

  if (containingService.href && service.href && containingService.href === service.href) {
    return true;
  }

  // Only check for the proto because sometimes the port is empty
  if (containingService.proto && service.proto) {
    return isPortContained(containingService, service) && containingService.proto === service.proto;
  }

  // This handles the case where the service is a user service, or a singleton port/proto
  if (containingService.service_ports && (service.service_ports || service.proto)) {
    return (service.service_ports || [service]).every(port =>
      containingService.service_ports.some(
        servicePort => isPortContained(servicePort, port) && servicePort.proto === port.proto,
      ),
    );
  }

  // For the Singleton case make sure the process/service are empty in the windows service
  if (containingService.windows_services && service.proto) {
    return containingService.windows_services.some(
      windowsService =>
        isPortContained(windowsService, service) &&
        windowsService.proto === service.proto &&
        !windowsService.process_name &&
        !windowsService.service_name,
    );
  }

  if (containingService.windows_services && service.windows_services) {
    return service.windows_services.every(port =>
      containingService.windows_services.some(
        windowsService =>
          isPortContained(windowsService, port) &&
          windowsService.proto === port.proto &&
          windowsService.process_name === port.processName &&
          windowsService.service_name === port.service_name,
      ),
    );
  }

  return false;
}

function areLabelsContained(containingLabels, labels) {
  // Find all the types of labels in the containing endpoint
  const types = containingLabels.reduce((result, label) => {
    result[(label.label || label.label_group).key] = true;

    return result;
  }, {});

  // For every type in the containing endpoint, there must be label of the same type in the other rule
  return Object.keys(types).every(type => {
    const containingLabelsForType = containingLabels.filter(label => (label.label || label.label_group).key === type);
    const labelsForType = labels.filter(label => (label.label || label.label_group).key === type);

    // And the labels of that type must be contained in the containing endpoint
    return labelsForType.length && isItemContained(containingLabelsForType, labelsForType);
  });
}

// Every resolution of the labels should be included in the containing rule
// Example: ['workloads', 'virtual_services'] and ['workloads']
function isResolutionContained(containingResolution, resolution) {
  return resolution.every(type => containingResolution.includes(type));
}

function isEndpointContained(containingEndpoint, endpoint) {
  const containingLabels = [];
  const containingOthers = [];
  const labels = [];
  const others = [];
  const allWorkloadsContainer = containingEndpoint.some(item => item.actors === 'ams');
  const allWorkloadItems = endpoint.every(
    item => item.actors === 'ams' || item.workload || item.label || item.label_group,
  );

  const allListContainer = containingEndpoint.some(item => item.ip_list?.name === intl('IPLists.Any'));
  const allListItems = endpoint.every(item => item.ip_list);

  // If the container is all and the item matches the all type
  if (allListContainer && allListItems) {
    return true;
  }

  if (allWorkloadsContainer && allWorkloadItems) {
    return true;
  }

  // Separate labels from other items
  containingEndpoint.forEach(item => {
    if (item.label || item.label_group) {
      containingLabels.push(item);
    } else if (Object.values(item)[0] !== true) {
      containingOthers.push(item);
    }
  });

  endpoint.forEach(item => {
    if (item.label || item.label_group) {
      labels.push(item);
    } else if (Object.values(item)[0] !== true) {
      others.push(item);
    }
  });

  // For label scope the containing labels should all be in the contained label set
  if (
    !(
      endpoint.every(item => item.label || Object.values(item)[0] === true) &&
      !containingLabels.length &&
      labels.length
    ) &&
    !areLabelsContained(containingLabels, labels)
  ) {
    return false;
  }

  // For other items all the contained items should be in the containing set
  return isItemContained(containingOthers, others);
}

function isRuleContained(containingRule, rule) {
  if (containingRule.href === rule.href || containingRule.unscoped_consumers !== rule.unscoped_consumers) {
    return false;
  }

  if (!isItemContained(containingRule.ingress_services, rule.ingress_services)) {
    return false;
  }

  if (
    ['consumers', 'providers'].some(
      endpoint => !isResolutionContained(containingRule.resolve_labels_as[endpoint], rule.resolve_labels_as[endpoint]),
    )
  ) {
    return false;
  }

  return ['consumers', 'providers'].every(endpoint => isEndpointContained(containingRule[endpoint], rule[endpoint]));
}

function findOverlappingRules(ruleset) {
  const removedHrefs = {};
  let rules = [...ruleset.rules];

  if (!localStorage.getItem('stopDedup')) {
    rules = rules.reduce((result, rule) => {
      // If rules are equal, they will be contained in each other, so don't remove them both
      if (
        rules.some(
          containingRule =>
            !removedHrefs[containingRule.href] &&
            containingRule.update_type !== 'delete' &&
            containingRule.enabled &&
            isRuleContained(containingRule, rule),
        )
      ) {
        removedHrefs[rule.href] = true;

        if (rule.href.includes('proposed')) {
          // Mark proposed rule as hidden
          result.push({...rule, hidden: true});
        } else {
          // Mark existing rule for deletion
          result.push({...rule, saved_update_type: rule.update_type, update_type: 'delete', proposedDelete: true});
        }
      } else if (rule.proposedDelete) {
        // Bring back existing rule
        result.push({...rule, update_type: rule.saved_update_type, proposedDelete: false});
      } else if (rule.hidden && !rule.delete) {
        // Unhide proposed rule
        result.push({...rule, hidden: false});
      } else {
        result.push(rule);
      }

      return result;
    }, []);
  }

  return {...ruleset, rules};
}

function updateRuleSettings(ruleset, {serviceType}) {
  let rules = [...ruleset.rules];

  rules = rules.map(rule => {
    let ingress_services = [...rule.ingress_services];

    if (rule.href.includes('proposed')) {
      ingress_services = ingress_services.map((service, index) => {
        // Convert from new -> port based service
        if (serviceType === 'port' && service.href?.includes('proposed')) {
          return rule.portService[index];
        }

        // Convert from port -> new service
        if (serviceType === 'new' && !service.href) {
          return rule.newService[index];
        }

        return service;
      });

      rule.ingress_services = ingress_services.filter(service => service);
    }

    return rule;
  });

  return findOverlappingRules({...ruleset, rules});
}

function expandScope(scope, labelGroupAllLabels) {
  const allLabels = scope.reduce((result, item) => {
    if (item.label_group && labelGroupAllLabels[item.label_group.href]) {
      result = [
        ...result,
        ...labelGroupAllLabels[item.label_group.href].map(label => ({label, exclusion: item.exclusion})),
      ];
    } else {
      result.push(item);
    }

    return result;
  }, []);

  const labels = allLabels.reduce(
    (result, label) => {
      result[label.label.key].push(label);

      return result;
    },
    {app: [], env: [], loc: []},
  );

  const product = ExplorerUtils.getNestedEndpointQuery({labels});

  return product;
}

function isIntraScope(endpoints, scopes) {
  return (
    endpoints.every(item => item.ip_list) ||
    scopes.some(scope =>
      areLabelsContained(
        scope,
        endpoints.filter(endpoint => endpoint.label?.key !== 'role'),
      ),
    )
  );
}

function getLabelsMap(endpoint) {
  return endpoint
    .filter(item => item.label)
    .reduce((result, item) => {
      result[item.label.key] = item.label.href;

      return result;
    }, {});
}

function inSameScope(consumer, provider, expandedScope) {
  const consumerLabels = getLabelsMap(consumer);
  const providerLabels = getLabelsMap(provider);
  const scopeLabels = getLabelsMap(expandedScope[0]);

  const keys = [...new Set([...Object.keys(consumerLabels), ...Object.keys(providerLabels)])];

  // For every key that exists in one of the two endpoints, the other endpoint should be missing or the same
  return keys.every(
    key =>
      !scopeLabels[key] || !consumerLabels[key] || !providerLabels[key] || consumerLabels[key] === providerLabels[key],
  );
}

function getLabelResolution(link, ruleEndpoint, endpoint) {
  // If labels exist in the rule endpoint
  if (ruleEndpoint.some(endpoint => endpoint.label)) {
    const typeKey = `${endpoint}_type`;
    const links = [...link.links];

    //Check for Virtual Services
    if (links.some(link => link[typeKey] === intl('Common.VirtualServices'))) {
      if (links.some(link => link[typeKey] === intl('Common.Workloads'))) {
        return ['workloads', 'virtual_services'];
      }

      return ['virtual_services'];
    }
  }

  return ['workloads'];
}

function getUsage(resolveAs) {
  if (resolveAs.includes('virtual_services')) {
    return [
      {
        [resolveAs.length === 1 ? 'usesVirtualServices' : 'usesVirtualServicesWorkloads']: true,
      },
    ];
  }

  return [];
}

function createRules({ruleset, links, selectedLinks, labelGroupAllLabels, serviceType}) {
  const expandedScopes = ruleset.scopes.map(scope => expandScope(scope, labelGroupAllLabels));
  const selected = selectedLinks ? links.filter(link => selectedLinks.includes(link.linkKey)) : [];
  const virtualServiceEndpointKeys = {};

  const rules = selected.reduce((result, link, index) => {
    let providers;
    let consumers;
    let outOfScope;
    let isConsumerIntraScope;

    // Stop on the first scope that matches the traffic
    expandedScopes.some(expandedScope => {
      // Calculate the intra and extra scope versions of the endpoints
      const intraScopeProviders = getRuleEndpoint('dst', link, expandedScope);
      const intraScopeConsumers = getRuleEndpoint('src', link, expandedScope);
      const allProviders = getRuleEndpoint('dst', link, []);
      const allConsumers = getRuleEndpoint('src', link, []);

      // Final providers must always be intra-scope
      providers = intraScopeProviders;

      // Calculate if consumer/provider is intra-scope
      // The consumer must be in the same scope as the provider
      const isConsumerInSameScope = inSameScope(allConsumers, allProviders, expandedScope);
      const isProviderIntraScope = isIntraScope(allProviders, expandedScope);

      isConsumerIntraScope = isIntraScope(allConsumers, expandedScope) && isConsumerInSameScope;
      consumers = isConsumerIntraScope ? intraScopeConsumers : allConsumers;

      // A rule is completely out of scope if the provider is out of scope,
      // or the provider is an ip list and the consumer is out of scope
      outOfScope = !isProviderIntraScope || (providers.every(item => item.ip_list) && !isConsumerIntraScope);

      return !outOfScope;
    });

    const serviceKey = `${link.port},${link.protocolNum}`;
    const exactMatch = PolicyGeneratorUtils.buildService(link);
    const portService = [{port: link.port, proto: link.protocolNum}];
    const newService = [{...PolicyGeneratorUtils.buildNewService(link), href: `proposed${serviceKey}`}];
    const resolve_labels_as = {
      providers: getLabelResolution(link, providers, 'dst'),
      consumers: getLabelResolution(link, consumers, 'src'),
    };

    providers.push(...getUsage(resolve_labels_as.providers));
    consumers.push(...getUsage(resolve_labels_as.consumers));

    let ingress_services;
    let duplicate;

    if (resolve_labels_as.providers.includes('workloads')) {
      ingress_services =
        exactMatch || (serviceType === 'new' || ServiceUtils.isIcmp(link.protocolNum) ? newService : portService);
    } else if (!virtualServiceEndpointKeys[link.endpointKey]) {
      virtualServiceEndpointKeys[link.endpointKey] = true;
      ingress_services = [];
    } else {
      duplicate = true;
    }

    if (!duplicate) {
      result.push({
        outOfScope,
        consumers,
        providers,
        ingress_services,
        portService,
        newService,
        consuming_security_principals: [],
        description: '',
        machine_auth: false,
        network_type: link.network?.name === 'External' ? 'non_brn' : 'brn',
        resolve_labels_as,
        sec_connect: false,
        stateless: false,
        unscoped_consumers: !isConsumerIntraScope,
        enabled: true,
        href: `proposed${index}`,
      });
    }

    return result;
  }, []);

  return {
    ...ruleset,
    rules: [...rules, ...(ruleset.rules ? ruleset.rules.filter(rule => rule.update_type !== 'delete') : [])],
  };
}

function getNewRulesetInfo() {
  return {
    caps: ['write', 'provision'],
    enabled: true,
    ip_tables_rules: [],
  };
}

async function getExternalDataForRuleset(scopes) {
  let referenceId =
    scopes.map(scope => scope.map(item => getId((item.label || item.label_group)?.href)).join('x')).join(',') ||
    'global';

  const data = {
    external_data_set: 'illumio_explorer_ruleset',
    external_data_reference: referenceId,
  };

  referenceId = await getUniqueReferenceId(data);

  return {...data, external_data_reference: referenceId};
}

async function getUniqueReferenceId(data) {
  let tries = 0;
  let referenceId;

  // If we didn't find a ruleset for this boundary
  // Make sure we choose a name for the ruleset that doesn't conflict with another ruleset
  while (tries < 500) {
    referenceId = tries === 0 ? data.external_data_reference : `${data.external_data_reference}--${tries}`;

    const response = await RestApiUtils.ruleSets.getCollection({external_data_reference: referenceId}, 'draft', true);

    if (!response.body.length) {
      break;
    } else {
      tries += 1;
    }
  }

  return referenceId;
}

function getLinks(params) {
  return (
    (params.id ? ExplorerStore.getAggregatedAppGroupTableLinks(params.id) : ExplorerStore.getAggregatedTableLinks()) ||
    []
  );
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function getAllLabelGroupLabels(scopes) {
  const scopeLabelGroups = scopes.reduce((result, scope) => {
    result.push(...scope.filter(item => item.label_group));

    return result;
  }, []);

  let labelGroupAllLabels = {};

  if (scopeLabelGroups.length) {
    const responses = await Promise.all(
      scopeLabelGroups.map(labelGroup => RestApiUtils.labelGroup.getAllLabels(getId(labelGroup.label_group.href))),
    );

    labelGroupAllLabels = responses.reduce((result, response, index) => {
      result[scopeLabelGroups[index].label_group.href] = response.body;

      return result;
    }, {});
  }

  return labelGroupAllLabels;
}

async function saveServices(services) {
  const objectsPerMin = SERVICES_PER_MIN;
  const createdServiceMapping = {};
  const servicesThrottleChunks = _.chunk(services, objectsPerMin);
  let nextBatch = 0;

  for (const servicesThrottle of servicesThrottleChunks) {
    // If we have calculated a time for the next batch, wait until that is done
    if (nextBatch && nextBatch > Date.now()) {
      await timeout(nextBatch - Date.now());
    }

    nextBatch = Date.now() + 60_000;

    // Create new services for all the ports without existing services
    for (const serviceChunk of _.chunk(servicesThrottle, 5)) {
      const servicesToCreate = [];
      const serviceHrefs = [];

      // Remove but save the proposed href to map back to the rules later
      serviceChunk.forEach(service => {
        // Save the dummy href
        serviceHrefs.push({href: service.href});

        const createService = {...service};

        // Remove the dummy href
        delete createService.href;
        servicesToCreate.push(createService);
      });

      try {
        const responses = await Promise.all(
          servicesToCreate.map(service => RestApiUtils.services.create(service, 'draft', false)),
        );

        responses.forEach((response, index) => {
          // Use the original proposed href to map to the new repsonse href
          createdServiceMapping[serviceHrefs[index].href] = response.body.href;
        });
      } catch (error) {
        console.error(error);

        return 'error';
      }
    }
  }

  return createdServiceMapping;
}

function getNewServices(rules) {
  return Object.values(
    rules.reduce((result, rule) => {
      rule.ingress_services.forEach(service => {
        if (service.href?.includes('proposed')) {
          result[service.href] = service;
        }
      });

      return result;
    }, {}),
  );
}

export async function saveRuleset(ruleset) {
  // Find the rules that need to be created
  const rules = ruleset.rules.filter(
    rule =>
      (!rule.href || rule.href.includes('proposed') || rule.proposedUpdate) && !rule.hidden && !rule.proposedDelete,
  );
  // Find the services that need to be saved
  const newServices = getNewServices(rules);

  let serviceCreation;

  if (newServices.length) {
    // Map saved Service hrefs to the rules before saving them
    serviceCreation = await saveServices(newServices);

    if (serviceCreation === 'error') {
      return;
    }

    rules.map(rule => {
      rule.ingress_services.forEach(service => {
        service.href = serviceCreation[service.href] || service.href;
      });

      return rule;
    });
  }

  if (ruleset.href) {
    const rulesToDelete = PolicyGeneratorUtils.getStrippedDeletedRules(ruleset.rules);

    if (rulesToDelete.length) {
      await RestApiUtils.secRules.deleteClean(GeneralUtils.getId(ruleset.href), {
        rules: rulesToDelete,
      });
    }
  }

  // Only add 250 rules at a time
  const ruleChunks = _.chunk(rules, RULES_PER_CALL);
  let newRulesetHref;

  // 'For in' will wait asynchronously in the loop
  for (const index in ruleChunks) {
    const rulesChunk = ruleChunks[index];
    const partialRuleset = {...ruleset, rules: rulesChunk};

    // Only create the ruleset the first time
    if (index === '0' && !ruleset.href) {
      const response = await RestApiUtils.ruleSets.create(
        PolicyGeneratorUtils.getStrippedRuleset(partialRuleset, true),
      );

      newRulesetHref = response.body.href;
    } else {
      await RestApiUtils.ruleSet.update(
        GeneralUtils.getId(newRulesetHref || ruleset.href),
        PolicyGeneratorUtils.getStrippedRuleset(partialRuleset),
      );
    }
  }

  return newRulesetHref || ruleset.href;
}

export function areLabelsInRuleWritingUserScope(labels, userPermissions) {
  const userRulesetManagerScopes = userPermissions
    .filter(permissions => permissions.role?.href?.includes('ruleset_manager'))
    .map(permissions => permissions.scope);
  const trafficLabels = labels.filter(label => label.key !== 'role').map(label => ({label}));

  return areScopesMatchingTraffic(userRulesetManagerScopes, trafficLabels);
}

export default {
  getLinks,
  saveRuleset,
  createRules,
  saveServices,
  getNewRulesetInfo,
  updateRuleSettings,
  areLabelsContained,
  findOverlappingRules,
  getAllLabelGroupLabels,
  loadExplorerPolicyData,
  getExternalDataForRuleset,
  getProposedRulesetFromPath,
  getScopeForExplorerRuleset,
  areLabelsInRuleWritingUserScope,
  getProposedAppGroupRulesetFromPath,
};
