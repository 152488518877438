/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {PropTypes} from 'react';
import intl from 'intl';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-label-group';

export default React.createClass({
  propTypes: {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.any,
    type: PropTypes.string,
    exclusion: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      onClick: null,
      text: '',
    };
  },

  render() {
    const classes = cx({
      'LabelGroup': true,
      'LabelGroup--role': this.props.type === 'role',
      'LabelGroup--app': this.props.type === 'app',
      'LabelGroup--env': this.props.type === 'env',
      'LabelGroup--loc': this.props.type === 'loc',
      'LabelGroup--created': this.props.type === 'created',
      'LabelGroup--deleted': this.props.type === 'deleted',
      'LabelGroup--exclusion': this.props.exclusion,
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.type);
    const {type} = this.props;

    let {text} = this.props;
    let icon = this.props.icon ? this.props.icon : null;
    let exclusionText = '';
    let titleText = text;

    if (this.props.exclusion && ['role', 'app', 'env', 'loc'].includes(icon || type)) {
      exclusionText = intl('Labels.Excluded', {type: icon || type});
    }

    if (type && ['role', 'app', 'env', 'loc'].includes(type)) {
      if (Array.isArray(text)) {
        text = this.props.text.map(({props: {children}}) => children).join('');
      }

      titleText = this.props.exclusion ? intl('Labels.Excluded', {type}) : intl('Labels.Text', {type});
      titleText = `${titleText} ${text}`;

      icon = icon || type;
    }

    if (icon === 'loc') {
      icon = 'location';
    }

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)} onClick={this.props.onClick} title={titleText}>
        <span className="LabelGroup-content" data-tid="label-group-content">
          {icon ? (
            <div className={`LabelGroup-icon-group LabelGroup--${icon}`}>
              <Icon labelGroup name={icon} position="before" />
            </div>
          ) : null}
          <span className="LabelGroup-text" data-tid="elem-text">
            {`${exclusionText} ${text}`}
          </span>
        </span>
      </div>
    );
  },
});
