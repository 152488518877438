/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Component} from 'react';
import intl from 'intl';
import {Icon, ObjectSelector, Badge, Spinner} from '../..';
import SettingsOrgStore from '../../../stores/SettingsOrgStore';
import actionCreators from '../../../actions/actionCreators';
import {CreateRulesetModal} from '.';
import {RestApiUtils, RenderUtils} from '../../../utils';
import {ExplorerPolicyUtils} from '../../../utils/Explorer';
import cx from 'classnames';

export default class SelectRuleset extends Component {
  constructor(props) {
    super(props);

    this.getRulesets = this.getRulesets.bind(this);
    this.rulesetSelect = this.rulesetSelect.bind(this);
    this.rulesetRemove = this.rulesetRemove.bind(this);
    this.handleRulesetCreate = this.handleRulesetCreate.bind(this);
    this.handleConfirmRulesetCreate = this.handleConfirmRulesetCreate.bind(this);
    this.customListItem = this.customListItem.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOverCaret = this.handleMouseOverCaret.bind(this);
    this.getFacetValues = this.getFacetValues.bind(this);

    const labelsObject = _.reduce(
      this.props.labels,
      (result, label) => {
        if (!label.href.includes('label_group')) {
          result[label.key] = label.href;
        }

        return result;
      },
      {},
    );

    this.getRulesets(labelsObject);

    this.state = {rulesets: [], dropdownValues: {}};
  }

  componentWillReceiveProps(nextProps) {
    const {selectedRuleset, edit} = nextProps;

    if (!_.isEmpty(selectedRuleset)) {
      // Only use the selected as the saved if not in edit mode
      const savedRuleset = edit ? this.state.savedRuleset : selectedRuleset;

      let options = savedRuleset ? {[savedRuleset?.name]: savedRuleset} : {};

      options = this.state.rulesets.reduce((result, ruleset) => {
        if (ruleset.name !== savedRuleset?.name) {
          result[ruleset.name] = ruleset;
        }

        return result;
      }, options);

      this.setState({
        selectedRuleset: {[selectedRuleset.name]: selectedRuleset},
        savedRuleset,
        options,
      });
    } else {
      this.setState({selectedRuleset: {}});
    }
  }

  componentDidUpdate() {
    if (this.input) {
      this.input.refs?.itemInput?.click();
    }
  }

  async getRulesets(labelsObject) {
    const {customRulesets, withScopes, labels} = this.props;
    const response = await RestApiUtils.ruleSets.getGroupCollection(labelsObject, 'rule_set_services_labels_and_names');
    const matched = Number(response.headers.get('x-matched-count')) || 0;
    const total = Number(response.headers.get('x-total-count')) || 0;
    const rulesets = [...(customRulesets || []), ...this.sortRulesets(response.body, labelsObject)];
    const advancedRuleWriting = SettingsOrgStore.getAdvancedRuleWriting();

    let ruleset;

    // If there is a ruleset with a scope choose it
    // If there are 2 or more label make sure the scope length is greater than 1
    // Location ring fencing can have one scope, other things should suggest a new ruleset with a better scope
    if (
      !customRulesets &&
      rulesets.length &&
      rulesets[0].scopes[0].length &&
      (Object.keys(labelsObject).length < 2 || rulesets[0].scopes[0].length > 1)
    ) {
      const singleResponse = await RestApiUtils.ruleSets.getInstance(rulesets[0].href.split('/').pop(), 'draft', true);

      ruleset = singleResponse.body;
    } else if (advancedRuleWriting && Object.keys(labelsObject).some(key => key !== 'role' && key !== 'loc')) {
      // If the only non-role label is 'Loc' use the global ruleset
      // Otherwise, create a new ruleset
      const labelsArray = this.props.groupTypes.length
        ? this.props.groupTypes.map(key => this.props.labels[key]).filter(Boolean)
        : Object.values(this.props.labels);
      const name = labelsArray
        .filter(label => label.value)
        .sort((a, b) => (a.key > b.key ? 1 : -1))
        .map(label => label.value)
        .join(' | ');
      const potentialRuleset = response.body.find(ruleset => ruleset.name === name);

      ruleset = {
        // Prevent duplicate names when creating a new policy generator ruleset
        name: potentialRuleset ? RenderUtils.getUniqueRulesetName(name, response.body) : name,
        scopes: [labelsArray.filter(label => label.href).map(label => ({label}))],
      };

      const dataReference = _.compact(labelsArray.map(label => label.href && label.href.split('/').pop()))
        .sort((a, b) => a - b)
        .join(' | ');

      if (
        Object.keys(labels).length > 1 &&
        !response.body.some(ruleset => ruleset.external_data_reference === dataReference)
      ) {
        ruleset.external_data_set = 'illumio_policy_generator';
        ruleset.external_data_reference = dataReference;
      } else {
        // If deleted PG ruleset exists, don't add the data reference, and change the name to 'duplicate'
        ruleset.name = potentialRuleset
          ? RenderUtils.getUniqueRulesetName(name, response.body, intl('Common.Duplicate'))
          : name;
      }

      rulesets.unshift(ruleset);
    } else if (rulesets.length) {
      ruleset = rulesets[0];
    } else if (withScopes && labels) {
      ruleset = {
        name: _.sortBy(Object.values(labels), 'key')
          .map(label => label.value)
          .join(' | '),
        scopes: [Object.values(labels).map(label => ({label}))],
      };
    } else {
      const defaultName = intl('Common.Global');
      let name;

      try {
        const response = await RestApiUtils.ruleSets.getCollection({name: defaultName}, 'draft', true);
        const matching = response.body;

        for (let tries = 0; tries < matching.length + 1; tries += 1) {
          name = tries === 0 ? defaultName : `${defaultName}--${tries}`;

          if (!matching.some(match => match.name === name)) {
            break;
          }
        }
      } catch (error) {
        console.error(error);
      }

      ruleset = {name, scopes: [[]]};
    }

    this.setState({rulesets, matched, total}, () => this.props.onSelect(ruleset));
  }

  async getFacetValues(key, query) {
    if (key === 'search') {
      const params = {query, facet: 'name', max_results: 100};

      const response = await RestApiUtils.ruleSets.facets(params, 'draft', true);

      this.setState({
        dropdownValues: {
          [`search-${query || ''}`]: response.body,
        },
      });
    }
  }

  sortRulesets(rulesets, labels) {
    // Don't add to the segementation templete rulesets or disabled rulesets
    const filteredRulesets = rulesets.filter(
      ruleset =>
        ruleset.external_data_set !== 'illumio_segmentation_templates' &&
        ruleset.enabled &&
        ruleset.update_type !== 'delete' &&
        ruleset.scopes.some(scope =>
          scope.every(item => {
            const labelIncluded = item.label && Object.values(labels).includes(item.label.href);

            return (item.exclusion && !labelIncluded) || (!item.exclusion && labelIncluded);
          }),
        ),
    );

    // Choose the most specific scope
    return filteredRulesets.sort((a, b) => {
      const dataSetRank = ['illumio_rule_builder', 'illumio_policy_generator', 'illumio_explorer_ruleset'];

      const scopeLengthDiff = a.scopes[0].length - b.scopes[0].length;
      const dataSetRankDiff = dataSetRank.indexOf(a.external_data_set) - dataSetRank.indexOf(b.external_data_set);
      const labelTypesRankDiff =
        RenderUtils.getLabelTypesRank(a.scopes[0]) - RenderUtils.getLabelTypesRank(b.scopes[0]);
      const latestDiff = a.updated_at > b.updated_at ? 1 : a.updated_at < b.updated_at ? -1 : 0;

      return -1 * (scopeLengthDiff || dataSetRankDiff || labelTypesRankDiff || latestDiff);
    });
  }

  async rulesetSelect(key, value) {
    let ruleset = value;

    if (key.includes(intl('ObjectSelector.MatchingResults')) || key.includes(intl('ObjectSelector.MatchingResult'))) {
      this.props.onEdit(false);

      return;
    }

    if (key === intl('Rulesets.SearchAllRulesets') && !value) {
      this.setState({items: {[key]: value}});

      return;
    }

    this.setState({items: {}});

    if (typeof value === 'string') {
      const singleResponse = await RestApiUtils.ruleSets.getCollection(
        {name: value, representation: 'rule_set_services_labels_and_names'},
        'draft',
        true,
      );

      ruleset = singleResponse.body.length ? singleResponse.body[0] : {};
    } else if (value.href && value.href !== this.state.savedRuleset.href) {
      const singleResponse = await RestApiUtils.ruleSets.getInstance(value.href.split('/').pop(), 'draft', true);

      ruleset = singleResponse.body;
    } else if (value.href) {
      ruleset = this.state.savedRuleset;
    }

    this.props.onEdit(false);
    this.props.onSelect(ruleset);
  }

  rulesetRemove() {
    this.setState({items: {}});
    this.props.onSelect({});
  }

  handleRulesetCreate(withScope) {
    actionCreators.openDialog(
      <CreateRulesetModal
        scopes={this.props.labels}
        forceScope={withScope}
        name={this.input.refs.itemInput.value}
        onConfirm={this.handleConfirmRulesetCreate}
      />,
    );
  }

  handleConfirmRulesetCreate(name, description, labels) {
    const ruleset = {
      name,
      description,
      scopes: [
        Object.values(labels)
          .filter(scope => Object.values(scope).length)
          .map(scope => (scope.href.includes('label_group') ? {label_group: scope} : {label: scope})),
      ],
    };

    this.props.onSelect(ruleset);
    this.props.onEdit(false);

    const rulesets = [...this.state.rulesets];

    // If we are creating a new ruleset, add it to the list
    if (!this.state.rulesets.filter(rs => rs.name === ruleset.name && !rs.href).length) {
      rulesets.push(ruleset);
      this.setState({rulesets});
    }
  }

  handleEdit() {
    this.props.onEdit('ruleset');
    this.setState({removeHighlight: false});
  }

  handleCancel() {
    this.props.onEdit(false);
    this.props.onSelect(this.state.savedRuleset);
  }

  handleMouseOver(item) {
    if (this.state.savedRuleset?.name !== item.value) {
      this.setState({removeHighlight: true});
    }
  }

  handleMouseOverCaret() {
    if (this.state.removeHighlight) {
      this.setState({removeHighlight: false});
    }
  }

  customListItem({text, props, item}) {
    const {rulesets, matches, items, removeHighlight, savedRuleset} = this.state;
    let className = `${props.className || ''} OSRulesetSelectResultItem`;
    let tid;
    let name = text;

    // Highlight the 'Create Ruleset' option at the bottom
    if (item?.footer) {
      className += ' OSRulesetSelectFooter';
      tid = 'create-ruleset';
    }

    if (savedRuleset?.name === text && !removeHighlight) {
      className += ' OSRulesetSelectSelected';
    }

    if (text.includes(' | ')) {
      name = RenderUtils.truncateAppGroupName(text, 40, [20, 10, 10]);
    }

    const ruleset = _.find(rulesets, ruleset => ruleset.name === item);
    const newRuleset = ruleset && !ruleset.href && !item?.footer;
    const facetRuleset = matches && matches.length && !ruleset && !item?.footer;
    const scopeCount = ruleset?.scopes.length;
    const matchingScope = (ruleset?.scopes || [[]]).find(scope =>
      ExplorerPolicyUtils.areLabelsContained(
        scope,
        Object.values(this.props.labels).map(label => ({label})),
      ),
    );

    const facetClasses = cx({
      'OSServiceSelectResultItem-Name': true,
      'OSServiceSelectResultItem-Facet': facetRuleset,
      'OSServiceSelectResultItem-FirstFacet': facetRuleset && item === matches[0],
    });

    return (
      <li key={text} title={name} {...props} className={className} data-tid={`comp-select-results-item ${tid}`}>
        <span className={facetClasses}>
          {newRuleset && <Badge type="new" />}
          <div className={facetClasses}>{name}</div>
        </span>
        {ruleset && (!items || !items.hasOwnProperty(intl('Rulesets.SearchAllRulesets'))) && (
          <span className="OSServiceSelectResultItem-Scope">
            {RenderUtils.renderScopeLabels(matchingScope || [])}
            {String(scopeCount > 1 ? `+${scopeCount - 1}` : '')}
          </span>
        )}
      </li>
    );
  }

  render() {
    if (!this.state) {
      return <Spinner size="small" />;
    }

    const {selectedRuleset, options} = this.state;
    const {editable, withScopes, allRulesets} = this.props;
    const facetMap = allRulesets ? {[intl('Rulesets.SearchAllRulesets')]: 'search'} : {};

    if (this.props.edit) {
      return (
        <div className="MapFormPanel-Selector" data-tid="map-info-panel-row-value">
          <ObjectSelector
            singleValues={options}
            showSingleMatches={true}
            items={this.state.items || {}}
            addItem={this.rulesetSelect}
            removeItem={this.rulesetRemove}
            placeholder={_.isEmpty(selectedRuleset) ? intl('Rulesets.CreateOrSelect') : ''}
            returnValue={item => item}
            allowOne={true}
            ref={node => (this.input = node)}
            dropdownValues={this.state.dropdownValues}
            initialValues={Object.keys(facetMap)}
            facetMap={facetMap}
            getFacetValues={this.getFacetValues}
            autoFocus={true}
            isInitial={true}
            showSingleValuesFirst={true}
            customClassItems={['initial']}
            customListItem={this.customListItem}
            customCaretIcon={
              <span onMouseOver={this.handleMouseOverCaret}>
                <Icon name="caret-up" size="xlarge" styleClass="ObjectSelector-down-arrow" />
              </span>
            }
            onClose={this.handleCancel}
            onMouseOver={this.handleMouseOver}
            footerValues={[
              {
                footer: true,
                text: intl('Map.CreateRuleset'),
                className: 'ObjectSelector-dd-values-item--action',
                onClick: _.partial(this.handleRulesetCreate, withScopes),
              },
            ]}
          />
        </div>
      );
    }

    const selected = this.props.selectedRuleset;
    const edit = editable;

    return (
      <div
        className={`MapFormPanel-Selected-Item${edit ? ' MapFormPanel-Selected-Item--Editable' : ''}`}
        data-tid="map-info-panel-row-value"
        onClick={edit ? this.handleEdit : _.noop}
      >
        <span className="MapFormPanel-Selected-Ruleset">
          {!_.isEmpty(selected) && !selected.href && <Badge type="new" />}
          <span className="MapFormPanelForm-Item-Name" title={selected.name}>
            {selected && selected.name && selected.name.includes(' | ')
              ? RenderUtils.truncateAppGroupName(selected.name, 40, [20, 10, 10])
              : selected && selected.name}
          </span>
        </span>
        {edit ? (
          <span className="Icon-Edit">
            <Icon name="caret-down" size="xlarge" tid="edit-ruleset" />
          </span>
        ) : null}
      </div>
    );
  }
}
