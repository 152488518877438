/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {SessionStore} from '../../stores';
import {UserMixin} from '../../mixins';
import {isAPIAvailable} from '../../lib/api';

const tesseReactRouteNamesMap = () => ({
  'app.landing': {
    viewName: () => intl('Common.Home'),
    isAvailable: () => __ANTMAN__,
  },
  'app.myprofile': {
    viewName: () => intl('Users.MyProfile'),
  },
  'app.myroles.list': {
    viewName: () => intl('Users.MyRoles'),
  },
  'app.apikeys.list': {
    viewName: () => intl('Users.APIKeys.MyAPIKeys'),
    isAvailable: () => !SessionStore.isEdge(),
  },
  'app.apiKeySettings.view': {
    viewName: () => intl('APIKeySettings.APIKeySettings'),
    isAvailable: () => UserMixin.isUserOwner(),
  },
  'app.virtualServices.list': {
    viewName: () => intl('Common.VirtualServices'),
    collectionName: 'virtualServices',
    isAvailable: () => SessionStore.isKubernetesSupported() && !SessionStore.isEdge(),
  },
  'app.workloads.list': {
    viewName: () => intl('Common.Workloads'),
    collectionName: 'workloads',
  },
  'app.workloads.vens.list': {
    viewName: () => intl('Common.VENS'),
    collectionName: 'vens',
    isAvailable: () => !SessionStore.isCrowdstrike(),
  },
  'app.workloads.containers.list': {
    viewName: () => intl('Common.ContainerWorkloads'),
    collectionName: 'containerWorkloads',
    isAvailable: () => !__ANTMAN__ && !SessionStore.isEdge(),
  },
  'app.networks.list': {
    viewName: () => intl('Menu.Networks', {multiple: true}),
    isAvailable: () => !__ANTMAN__ && SessionStore.isInfrastructureEnabled() && !SessionStore.isEdge(),
  },
  'app.rulesets.list': {
    viewName: () => intl('Common.Rulesets'),
    isAvailable: () => !SessionStore.isUserWithReducedScope() && !SessionStore.isEdge(),
  },
  'app.pending': {
    viewName: () => intl('Provision.DraftChanges'),
    isAvailable: () => !SessionStore.isUserWithReducedScope(),
  },
  'app.versions.list': {
    viewName: () => intl('Provision.Versions', {multiple: true}),
    isAvailable: () => !SessionStore.isUserWithReducedScope(),
  },
  'app.services.list': {
    viewName: () => intl('Common.Services'),
    collectionName: 'services',
  },
  'app.iplists.list': {
    viewName: () => intl('Common.IPLists'),
    collectionName: 'ipLists',
  },
  'app.labels.list': {
    viewName: () => intl('Common.Labels'),
    collectionName: 'labels',
    isAvailable: () => !SessionStore.isEdge(),
  },
  'app.pairingProfiles.list': {
    viewName: () => intl('PairingProfiles.Profiles'),
    isAvailable: () => SessionStore.isPairingProfilesEnabled() && !SessionStore.isEdge(),
  },
  'app.userGroups.list': {
    viewName: () => intl('Common.UserGroups'),
  },
  'app.labelGroups.list': {
    viewName: () => intl('Labels.Groups'),
    collectionName: 'labelGroups',
    isAvailable: () => !SessionStore.isEdge(),
  },
  'app.virtualServers.list': {
    viewName: () => intl('Common.VirtualServers'),
    collectionName: 'virtualServers',
    isAvailable: () => !__ANTMAN__ && !SessionStore.isEdge(),
  },
  'app.loadBalancers.list': {
    viewName: () => intl('Menu.LoadBalancers'),
    isAvailable: () => !__ANTMAN__ && SessionStore.isInfrastructureEnabled() && !SessionStore.isEdge(),
  },
  'app.containerClusters.list': {
    viewName: () => intl('Menu.ContainerClusters', {multiple: true}),
    collectionName: 'containerClusters',
    isAvailable: () =>
      SessionStore.isKubernetesSupported() && SessionStore.isInfrastructureEnabled() && !SessionStore.isEdge(),
  },
  'app.secureGateways.list': {
    viewName: () => intl('SecureGateway.SecureConnect'),
    isAvailable: () => !__ANTMAN__ && SessionStore.isInfrastructureEnabled() && !SessionStore.isEdge(),
  },
  'app.accessRestrictions.list': {
    viewName: () => intl('AccessRestriction.AccessRestrictionTitle'),
    isAvailable: () => !__ANTMAN__ && SessionStore.isAccessRestrictionEnabled(),
  },
  'app.switches.list': {
    viewName: () => intl('Menu.Switches'),
    isAvailable: () =>
      SessionStore.isInfrastructureEnabled() &&
      SessionStore.isNetworkEnforcementNodeEnabled() &&
      !SessionStore.isEdge(),
  },
  'app.events': {
    viewName: () => intl('Common.Events'),
    isAvailable: () => SessionStore.isEventsEnabled(),
  },
  'app.reports.list': {
    viewName: () => intl('Exports.PageName'),
  },
  'app.coreservices.list': {
    viewName: () => intl('CoreServices.CoreServices'),
    isAvailable: () => !SessionStore.isEdge() && SessionStore.isCoreServicesEnabled(),
  },
  'app.coreservicesettings': {
    viewName: () => intl('CoreServices.CoreServiceSettings'),
    params: {mode: 'view'},
    isAvailable: () => !SessionStore.isEdge() && SessionStore.isCoreServicesSettingsEnabled(),
  },
  'app.supportBundles.ven.list': {
    viewName: () => intl('SupportReports.PageName'),
    isAvailable: () =>
      !SessionStore.isCrowdstrike() && (!UserMixin.isUserReadOnly(false) || UserMixin.isWorkloadManager()),
  },
  'app.supportBundles.pce.list': {
    viewName: () => intl('PCESupportBundles.PageName'),
    isAvailable: () => !SessionStore.isEdge() && (UserMixin.isUserOwner() || UserMixin.isUserAdmin()),
  },
  'app.events.config.detail': {
    viewName: () => intl('Common.EventSettings'),
    params: {mode: 'view'},
    isAvailable: () => !SessionStore.isEdge() && SessionStore.isHealthEnabled() && SessionStore.isEventsEnabled(),
  },
  'app.venLibrary': {
    viewName: () => intl('VEN.Library'),
    isAvailable: () => !SessionStore.isCrowdstrike() && SessionStore.isVenLibraryEnabled(),
  },
  'app.securitysettings.section': {
    viewName: () => intl('Common.Security'),
    isAvailable: () => SessionStore.isSettingsEnabled(),
    aliases: [
      {
        viewName: () => `${intl('Common.Security')} - ${intl('Common.StaticPolicy')}`,
        params: {section: 'staticscopes'},
        isAvailable: () => !__ANTMAN__ && !SessionStore.isEdge() && SessionStore.isSettingsEnabled(),
      },
      {
        viewName: () => `${intl('Common.Security')} - ${intl('Firewall.Coexistence.Title')}`,
        params: {section: 'firewallcoexistence'},
        isAvailable: () => !SessionStore.isEdge() && SessionStore.isSettingsEnabled(),
      },
      {
        viewName: () => `${intl('Common.Security')} - ${intl('Common.BlockAction')}`,
        params: {section: 'blockaction'},
        isAvailable: () => !__ANTMAN__ && !SessionStore.isEdge() && SessionStore.isSettingsEnabled(),
      },
      {
        viewName: () => `${intl('Common.Security')} - ${intl('Settings.Containers.ContainersPolicy')}`,
        params: {section: 'containers'},
        isAvailable: () => !__ANTMAN__ && !SessionStore.isEdge() && SessionStore.isSettingsEnabled(),
      },
      {
        viewName: () => `${intl('Common.Security')} - ${intl('Settings.LoopbackInterfaces.Title')}`,
        params: {section: 'loopbackinterfaces'},
        isAvailable: () => !__ANTMAN__ && !SessionStore.isEdge() && SessionStore.isSettingsEnabled(),
      },
      {
        viewName: () => `${intl('Common.Security')} - ${intl('Settings.IPForwarding.Title')}`,
        params: {section: 'ipforwarding'},
        isAvailable: () =>
          !__ANTMAN__ &&
          !SessionStore.isEdge() &&
          SessionStore.isSettingsEnabled() &&
          SessionStore.isIPForwardingEnabled(),
      },
      {
        viewName: () => `${intl('Common.Security')} - ${intl('Settings.SecureConnect')}`,
        params: {section: 'secureconnect'},
        isAvailable: () => !SessionStore.isEdge() && SessionStore.isSettingsEnabled(),
      },
    ],
  },
  'app.essentialservicerules': {
    viewName: () => intl('Settings.EssentialServiceRules'),
    isAvailable: () => !SessionStore.isEdge() && isAPIAvailable('essential_service_rules.get'),
  },
  'app.venoperations': {
    viewName: () => intl('Settings.IKECertificates'),
    isAvailable: () => SessionStore.isSettingsEnabled(),
  },
  'app.authenticationSettings.main': {
    viewName: () => intl('Common.Authentication'),
    isAvailable: () => UserMixin.isUserOwner(),
  },
  'app.policySettings.detail': {
    viewName: () => intl('Policy.Settings'),
    isAvailable: () => UserMixin.isUserOwner() && !SessionStore.isEdge(),
  },
  'app.offlineTimers.detail': {
    viewName: () => intl('OfflineTimers.OfflineTimers'),
    isAvailable: () => SessionStore.isSettingsEnabled() && !SessionStore.isEdge(),
  },
  'app.rbac.roles.global.list': {
    viewName: () => intl('RBAC.GlobalRoles'),
    isAvailable: () => !__ANTMAN__ && UserMixin.isUserOwner(),
  },
  'app.rbac.roles.scope.list': {
    viewName: () => intl('Common.Scopes'),
    isAvailable: () => !__ANTMAN__ && UserMixin.isUserOwner() && !SessionStore.isEdge(),
  },
  'app.rbac.users.external.groups.list': {
    viewName: () => intl('Users.ExternalGroups'),
    isAvailable: () => UserMixin.isUserOwner(),
  },
  'app.rbac.users.external.list': {
    viewName: () => intl('Users.ExternalUsers'),
    isAvailable: () => UserMixin.isUserOwner(),
  },
  'app.rbac.users.local.list': {
    viewName: () => intl('Users.LocalUsers'),
    isAvailable: () => UserMixin.isUserOwner(),
  },
  'app.rbac.serviceAccounts.list': {
    viewName: () => intl('Common.ServiceAccounts'),
    isAvailable: () => !SessionStore.isEdge() && UserMixin.isUserOwner(),
  },
  'app.rbac.users.activity.list': {
    viewName: () => intl('RBAC.UsersActivity.UserActivity'),
    isAvailable: () => UserMixin.isUserOwner(),
  },
  'app.provisioning': {
    viewName: () => intl('Provision.ProvisioningStatus'),
  },
  'app.health.list': {
    viewName: () => intl('Health.PCEHealth'),
    isAvailable: () => SessionStore.isHealthEnabled(),
  },
  'app.flowCollectionFilters.list': {
    viewName: () => intl('Common.FlowCollectionFilters'),
  },
  'app.trustedproxy.list': {
    viewName: () => intl('TrustedProxy.TrustedProxyIp'),
    isAvailable: () => UserMixin.isUserOwner(),
  },
  'app.reporting.downloads.list': {
    viewName: () => intl('Common.Reports'),
    isAvailable: () =>
      !SessionStore.isEdge() && isAPIAvailable('reports.get_collection') && SessionStore.isReportingEnabled(),
  },
  'app.boundaries.list': {
    viewName: () => intl('Workloads.EnforcementBoundaries'),
    isAvailable: () => !__ANTMAN__ && isAPIAvailable('enforcement_boundaries.get_collection'),
  },
  'app.outboundpolicy': {
    viewName: () => intl('Policy.Organizational'),
    isAvailable: () => SessionStore.isEdge(),
  },
  'app.dashboard': {
    viewName: () => intl('Common.Dashboard'),
    isAvailable: () => __ANTMAN__,
  },
});

export default tesseReactRouteNamesMap;
