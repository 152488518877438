/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import update from 'react-addons-update';
import {SessionStore} from '../stores';
import LabelStore from '../stores/LabelStore';
import LabelGroupStore from '../stores/LabelGroupStore';
import GridDataUtils from './GridDataUtils';
import {deepPluck, generateKey} from './GeneralUtils';
import LabelGroup from '../components/LabelGroup';
import Label from '../components/Label';
import {fixServiceForClient, fixServicePortForClient} from './RestApiUtils';

const scopeLabelTypes = ['app', 'env', 'loc'];
const acceptedKeys = ['label', 'label_group', 'workload', 'virtual_service', 'ip_list', 'virtual_server'];

/**
 * return an array which is accepted by the API
 * @param arr
 * @returns {Array} Array with extraneous keys removed
 */
export const fixRulesetArr = arr =>
  arr
    ? arr.reduce((result, obj) => {
        if (obj.actors) {
          result.push({actors: obj.actors});
        } else {
          acceptedKeys.forEach(key => {
            if (obj[key]) {
              result.push({[key]: {href: obj[key].href}});
            }
          });
        }

        return result;
      }, [])
    : [];

/**
 * Get an array of rules that match the given services
 * @param ruleset {Object}
 * @param service {Object}
 * @param provider {Object}
 * @param consumer {Object}
 * @return {Array} Array of merged rule objects
 */
export function getMergedRules(ruleset, service, provider, consumer, secConnect) {
  // Prepare pb/ub
  const pb = getPbUbAvengerReady(provider);
  const ub = getPbUbAvengerReady(consumer);
  let mergedRule;

  // We have a service and ruleset
  _.each(ruleset.rules, rule => {
    // Services match
    // note: not for virtual server
    if (
      rule.enabled &&
      rule.update_type !== 'delete' &&
      !rule.unscoped_consumers &&
      rule.service &&
      rule.service.href === service.href &&
      ((rule.sec_connect && secConnect) || (!rule.sec_connect && !secConnect))
    ) {
      // Look for matching pb
      const pbMatch = _.find(rule.providers, p => _.isEqual(pb, cleanPbUb(p)));
      // Look for matching ub
      const ubMatch = _.find(rule.consumers, u => _.isEqual(ub, cleanPbUb(u)));

      if (pbMatch && ubMatch) {
        mergedRule = rule;
      }

      if (pbMatch && rule.providers.length === 1 && !ubMatch) {
        mergedRule = update(rule, {
          consumers: {$push: [ub]},
        });
      } else if (ubMatch && rule.consumers.length === 1 && !pbMatch) {
        mergedRule = update(rule, {
          providers: {$push: [pb]},
        });
      }
    }
  });

  return mergedRule;
}

export function cleanPbUb(pbub) {
  return getPbUbAvengerReady(parseAvengerReadyPbUb(pbub));
}

/** Take a User selected pb or ub and format it for Avenger consumption
 * @param {Object} pb Requires href and type (label, ipList, internet, agent)
 * @return Object that is Avenger consumable
 */
export function getPbUbAvengerReady(pb) {
  if (pb.type === 'label') {
    return {label: {href: pb.href}};
  }

  if (pb.type === 'ipList') {
    return {ip_list: {href: pb.href}};
  }

  if (pb.type === 'internet') {
    return {actors: 'all'};
  }

  if (pb.type === 'workload') {
    return {workload: {href: pb.href}};
  }

  if (pb.type === 'ams') {
    return {actors: 'ams'};
  }

  if (pb.type === 'container_host') {
    return {actors: 'container_host'};
  }
}

export function parseAvengerReadyPbUb(pb) {
  let name;

  if (pb.label) {
    return {type: 'label', href: pb.label.href, value: pb.label.value, key: pb.label.key};
  }

  if (pb.label_group) {
    return {type: 'labelGroup', href: pb.label_group.href, value: pb.label_group.name, key: pb.label_group.key};
  }

  if (pb.ip_list) {
    return {type: 'ipList', href: pb.ip_list.href, name: pb.ip_list.name};
  }

  if (pb.virtual_service) {
    return {type: 'virtualService', href: pb.virtual_service.href, value: pb.virtual_service.name};
  }

  if (pb.actors) {
    if (pb.actors === 'all') {
      return {type: 'internet', href: 'internet'};
    }

    if (pb.actors === 'ams') {
      return {type: 'ams'};
    }

    if (pb.actors === 'container_host') {
      return {type: 'container_host'};
    }
  } else if (pb.workload) {
    name = pb.workload.name || pb.workload.hostname;

    return {type: 'workload', href: pb.workload.href, name};
  } else if (pb.virtual_server) {
    name = pb.virtual_server.name;

    return {type: 'virtualServer', href: pb.virtual_server.href, name};
  }
}

// Function to convert ruleset to an object that the select component accepts
export function rulesetMap(ruleset) {
  return {label: ruleset.name, value: ruleset.href};
}

const unwritableAttributes = [
  'created_at',
  'updated_at',
  'deleted_at',
  'created_by',
  'updated_by',
  'deleted_by',
  'update_type',
];

const unwritableRulesetAttributes = ['created_by_user', 'updated_by_user', 'advanced', 'hydrated'];

export function getRulesetAsWritableData(ruleset) {
  // todo: need change _.omit to transform
  const writableRulesets = _.omit(ruleset, unwritableAttributes.concat(unwritableRulesetAttributes));

  return Object.assign(writableRulesets, {
    scopes: ruleset.scopes.map(getScopeAsWritableData),
    rules: ruleset.rules.map(getRuleAsWritableData),
  });
}

export function getRuleAsWritableData(rule) {
  const data = _.omit(rule, unwritableAttributes.concat(['description']));

  if (rule.description) {
    data.description = rule.description;
  }

  return data;
}

export function getScopeAsWritableData(scope) {
  return _.pick(scope, ['pb', 'ub']);
}

export function getDuplicateRuleset(ruleset) {
  const data = _.omit(getRulesetAsWritableData(ruleset), ['href', 'description']);
  const duplicate = _.assign(data, {
    rules: data.rules.map(rule => _.omit(rule, 'href')),
  });

  duplicate.name += ' (1)';

  if (ruleset.description) {
    duplicate.description = ruleset.description;
  }

  return duplicate;
}

/**
 * If the passed ruleset considered a global ruleset (no scope)
 * @param ruleset
 * @returns {boolean|*}
 */
export function isGlobal(ruleset) {
  const scope = ruleset.scopes[0];

  return ruleset.scopes.length === 1 && _.isEqual(scope, []);
}

/**
 * If the passed ruleset has a scope with an "All" label in it
 * @param ruleset
 * @returns {boolean|*}
 */
export function scopeContainsAllLabel(ruleset) {
  return _.find(ruleset.scopes, scope => {
    if (scope.length < 3) {
      return true;
    }
  });
}

/**
 * Given an array of scopes,
 * return an array of scopes with key'd labels and a unique index
 * @param scopes
 * @returns {*}
 */
export function getScopesWithLabelKeys(scopes) {
  return scopes.map(scope => getScopeWithLabelKeys(scope));
}

/**
 * Return a scope with label types as keys
 * @param scope
 * @returns {}
 */
export function getScopeWithLabelKeys(scope) {
  const scopeWithKeys = {};

  // check if the pb is already keyed
  if (Array.isArray(scope)) {
    scope.forEach(pb => {
      let lbl;

      if (pb.label) {
        lbl = pb.label;
      } else if (pb.label_group) {
        lbl = pb.label_group;
      }

      if (lbl) {
        scopeWithKeys[lbl.key] = {...lbl, exclusion: pb.exclusion};
      }
    });
  } else if (_.isPlainObject(scope)) {
    // return scope if it already has label keys
    return scope;
  }

  return scopeWithKeys;
}

/**
 * Given an array of scopes with key'd labels,
 * return an array of scopes
 * @param scopes
 * @returns {*}
 */
export function getScopesFromLabelKeys(scopes) {
  return scopes.map(scope =>
    deepPluck(scope, 'href').reduce((result, href) => {
      if (href) {
        result.push({[String(href.includes('label_group') ? 'label_group' : 'label')]: {href}});
      }

      return result;
    }, []),
  );
}

/**
 * Given a scopes array,
 * return a flattened labels array
 * @param scopes
 * @returns {*}
 */
export function getScopesAsLabelsArray(scopes) {
  return deepPluck(scopes, 'href');
}

/**
 * Given a scopes array,
 * return a flattened label/label group stringified array
 * @param scopes
 * @returns {*}
 */
export function getScopesServicesLabelsAndGroups(scopes) {
  return JSON.stringify(
    scopes.map(scope =>
      scope.reduce((result, o) => {
        if (o.label) {
          result.push({label: o.label.href});
        }

        if (o.label_group) {
          result.push({label_group: o.label_group.href});
        }

        return result;
      }, []),
    ),
  );
}

/**
 * Return an array of keyed scopes with created/deleted flags
 * @param scopes
 * @param scopesToCompare
 * @returns {*}
 */
export function getComparedScopes(scopes, scopesToCompare) {
  if (!scopesToCompare) {
    return scopes.map((scope, index) => Object.assign(scope, {id: index}));
  }

  const scopesClone = _.cloneDeep(scopes);
  const scopesToCompareClone = _.cloneDeep(scopesToCompare);
  const comparedScopes = [];

  scopesClone.forEach(scope => {
    const existingScope = _.some(scopesToCompareClone, scopeToCompare => _.isEqual(scope, scopeToCompare));

    if (!existingScope) {
      scope.created = true;
    }

    comparedScopes.push(scope);
  });
  scopesToCompareClone.forEach(scope => {
    const inCurrentScope = _.some(scopesClone, scopeToCompare => _.isEqual(scope, scopeToCompare));

    if (!inCurrentScope) {
      scope.deleted = true;
      comparedScopes.push(scope);
    }
  });

  return comparedScopes.map((scope, index) => Object.assign(scope, {id: index}));
}

/**
 * Function to filter a rules array based on a given filter object
 *
 * @param filter
 * @param rules
 * @returns {*}
 */
export const filterRules = (filter, rules) => {
  // no default param for filter as we want to use "{}" even if filter is null
  if (Object.values(filter || {}).every(v => !v)) {
    return rules;
  }

  const filterHrefs = deepPluck(filter, 'href');

  return rules.reduce((result, rule) => {
    const ruleEntities = deepPluck(rule, 'href');
    const actors = deepPluck(rule, 'actors');
    let matched = true;

    if (filter[intl('Workloads.All')]) {
      matched = matched && actors.includes('ams');
    }

    if (filter[intl('Common.ContainerHost')]) {
      matched = matched && actors.includes('container_host');
    }

    if (filter[intl('Common.AllServices')]) {
      matched =
        matched &&
        rule.ingress_services &&
        rule.ingress_services.some(service => service.name === intl('Common.AllServices'));
    }

    if (
      SessionStore.isKubernetesSupported() &&
      filter[`${intl('Common.Providers')} ${intl('Common.UsesVirtualServices')}`]
    ) {
      matched = matched && _.get(rule, 'resolve_labels_as.providers', []).join(',') === 'virtual_services';
    }

    if (
      SessionStore.isKubernetesSupported() &&
      filter[`${intl('Common.Providers')} ${intl('Common.UsesVirtualServicesWorkloads')}`]
    ) {
      matched =
        matched && _.get(rule, 'resolve_labels_as.providers', []).sort().join(',') === 'virtual_services,workloads';
    }

    if (
      SessionStore.isKubernetesSupported() &&
      filter[`${intl('Common.Consumers')} ${intl('Common.UsesVirtualServices')}`]
    ) {
      matched = matched && _.get(rule, 'resolve_labels_as.consumers', []).join(',') === 'virtual_services';
    }

    if (
      SessionStore.isKubernetesSupported() &&
      filter[`${intl('Common.Consumers')} ${intl('Common.UsesVirtualServicesWorkloads')}`]
    ) {
      matched =
        matched && _.get(rule, 'resolve_labels_as.consumers', []).sort().join(',') === 'virtual_services,workloads';
    }

    if (filter[intl('Common.Stateless')]) {
      matched = matched && rule.stateless === true;
    }

    if (filter[intl('Common.MachineAuthentication')]) {
      matched = matched && rule.machine_auth === true;
    }

    if (filterHrefs.length) {
      matched = matched && filterHrefs.every(href => ruleEntities.includes(href));
    }

    if (matched) {
      result.push(rule);
    }

    return result;
  }, []);
};

/**
 * Check if 2 sets of scopes are different
 * @param scopes
 * @param scopesToCompare
 * @returns {Boolean}
 */
export function hasScopesDiff(scopes, scopesToCompare) {
  if (!scopesToCompare) {
    return false;
  }

  const strippedScopes = scopes.map(scope => scope.href);
  const strippedScopesToCompare = scopesToCompare.map(scope => scope.href);

  return (
    strippedScopes.some(
      scope => !_.some(strippedScopesToCompare, scopeToCompare => _.isEqual(scope, scopeToCompare)),
    ) ||
    strippedScopesToCompare.some(scope => !_.some(strippedScopes, scopeToCompare => _.isEqual(scope, scopeToCompare)))
  );
}

/**
 * Return rules with created/deleted flags on changed objects
 * @param rules
 * @param rulesToCompare
 * @returns {*}
 */
export function getComparedRules(rules, rulesToCompare) {
  if (!rulesToCompare) {
    return rules;
  }

  const idFromHref = GridDataUtils.getIdFromHref;
  const rulesClone = _.cloneDeep(rules);
  const rulesToCompareClone = _.cloneDeep(rulesToCompare);
  const comparedRules = [];

  rulesClone.forEach(rule => {
    const existingRule = _.find(
      rulesToCompareClone,
      ruleToCompare => idFromHref(rule.href) === idFromHref(ruleToCompare.href),
    );

    if (!existingRule) {
      rule.created = true;
    }

    comparedRules.push(getComparedRule(rule, existingRule));
  });
  rulesToCompareClone.forEach(rule => {
    const inCurrentRules = _.some(
      rulesClone,
      ruleToCompare => idFromHref(rule.href) === idFromHref(ruleToCompare.href),
    );

    if (!inCurrentRules) {
      rule.deleted = true;
      comparedRules.push(rule);
    }
  });

  return comparedRules;
}

/**
 * Return IP tables rules with created/deleted flags on changed objects
 * @param rules
 * @param rulesToCompare
 * @returns {*}
 */
export function getComparedIpTablesRules(rules, rulesToCompare) {
  if (!rulesToCompare) {
    return rules;
  }

  const idFromHref = GridDataUtils.getIdFromHref;
  const rulesClone = _.cloneDeep(rules);
  const rulesToCompareClone = _.cloneDeep(rulesToCompare);
  const comparedRules = [];

  rulesClone.forEach(rule => {
    const existingRule = _.find(
      rulesToCompareClone,
      ruleToCompare => idFromHref(rule.href) === idFromHref(ruleToCompare.href),
    );

    if (!existingRule) {
      rule.created = true;
    }

    comparedRules.push(getComparedIpTablesRule(rule, existingRule));
  });
  rulesToCompareClone.forEach(rule => {
    const inCurrentRules = _.some(
      rulesClone,
      ruleToCompare => idFromHref(rule.href) === idFromHref(ruleToCompare.href),
    );

    if (!inCurrentRules) {
      rule.deleted = true;
      comparedRules.push(rule);
    }
  });

  return comparedRules;
}

/**
 * Add created and deleted flag to the Ruleset Rule entities, and sort them
 *
 * @param entities
 * @param entitiesToCompare
 * @param isServices
 */
function addFlagsAndGroupEntities(entities, entitiesToCompare, isServices) {
  if (!entitiesToCompare) {
    return entities;
  }

  const newEntities = [[], [], []];

  entities.forEach(entity => {
    // Entity exists in the draft version, but not in active
    if (!hasEntity(entity, entitiesToCompare, isServices)) {
      newEntities[0].push({...entity, created: true});
    } else {
      newEntities[1].push(entity);
    }
  });

  entitiesToCompare.forEach(entity => {
    // Entity exists in the active version, but not in draft
    if (!hasEntity(entity, entities, isServices)) {
      newEntities[2].push({...entity, deleted: true});
    }
  });

  // Created entities go in the beginning, removed entities go in the end
  // Existing entities are left in the middle
  return [...newEntities[0], ...newEntities[1], ...newEntities[2]];
}

/**
 * Return a rule with created/deleted flags on changed objects
 * @param rule
 * @param ruleToCompare
 * @returns {*}
 */
export function getComparedRule(rule, ruleToCompare) {
  if (!ruleToCompare) {
    return rule;
  }

  const comparedRule = _.cloneDeep(rule);

  // check for secureconnect change
  if (comparedRule.sec_connect !== ruleToCompare.sec_connect) {
    comparedRule.sec_connect_change = ruleToCompare.sec_connect ? 'disabled' : 'enabled';
  }

  if (
    generateKey(comparedRule.ingress_services.map(fixServiceForClient)) !==
    generateKey(ruleToCompare.ingress_services.map(fixServiceForClient))
  ) {
    comparedRule.ingress_services.updated = true;
  }

  if (generateKey(comparedRule.resolve_labels_as) !== generateKey(ruleToCompare.resolve_labels_as)) {
    comparedRule.virtualServiceUsageUpdated = true;
  }

  // TODO simplify the number of flags for sec_connect, etc.
  if (comparedRule.sec_connect !== ruleToCompare.sec_connect) {
    comparedRule.secConnectChanged = true;

    if (comparedRule.sec_connect) {
      comparedRule.secConnectAdded = true;
    }

    if (ruleToCompare.sec_connect) {
      comparedRule.secConnectRemoved = true;
    }
  }

  if (comparedRule.machine_auth !== ruleToCompare.machine_auth) {
    comparedRule.machineAuthChanged = true;

    if (comparedRule.machine_auth) {
      comparedRule.machineAuthAdded = true;
    }

    if (ruleToCompare.machine_auth) {
      comparedRule.machineAuthRemoved = true;
    }
  }

  if (comparedRule.stateless !== ruleToCompare.stateless) {
    comparedRule.statelessChanged = true;

    if (comparedRule.stateless) {
      comparedRule.statelessAdded = true;
    }

    if (ruleToCompare.stateless) {
      comparedRule.statelessRemoved = true;
    }
  }

  comparedRule.providers = addFlagsAndGroupEntities(comparedRule.providers, ruleToCompare.providers);
  comparedRule.consumers = addFlagsAndGroupEntities(comparedRule.consumers, ruleToCompare.consumers);
  comparedRule.ingress_services.map(fixServiceForClient);
  ruleToCompare.ingress_services.map(fixServiceForClient);
  comparedRule.ingress_services = addFlagsAndGroupEntities(
    comparedRule.ingress_services,
    ruleToCompare.ingress_services,
    true,
  );

  // check for consuming security principals
  comparedRule.consuming_security_principals.forEach(consumer => {
    // entity was created
    if (!hasUserGroup(consumer, ruleToCompare.consuming_security_principals)) {
      consumer.created = true;
    }
  });
  ruleToCompare.consuming_security_principals.forEach(consumer => {
    // entity was removed
    if (!hasUserGroup(consumer, comparedRule.consuming_security_principals)) {
      const clonedProvider = _.cloneDeep(consumer);

      clonedProvider.deleted = true;
      comparedRule.consuming_security_principals.push(clonedProvider);
    }
  });

  if (rule.enabled && !ruleToCompare.enabled) {
    comparedRule.enabledChanged = true;
  }

  if (!rule.enabled && ruleToCompare.enabled) {
    comparedRule.disabledChanged = true;
  }

  return comparedRule;
}

/**
 * Return a IP tables rule with created/deleted flags on changed objects
 * @param rule
 * @param ruleToCompare
 * @returns {*}
 */
export function getComparedIpTablesRule(rule, ruleToCompare) {
  if (!ruleToCompare) {
    return rule;
  }

  const comparedRule = _.cloneDeep(rule);

  //check for statement changes
  comparedRule.statements.forEach(statement => {
    if (
      !_.some(
        ruleToCompare.statements,
        compareStatement =>
          statement.table_name === compareStatement.table_name &&
          statement.chain_name === compareStatement.chain_name &&
          statement.parameters === compareStatement.parameters,
      )
    ) {
      statement.created = true;
    }
  });
  ruleToCompare.statements.forEach(statement => {
    if (
      !_.some(
        comparedRule.statements,
        compareStatement =>
          statement.table_name === compareStatement.table_name &&
          statement.chain_name === compareStatement.chain_name &&
          statement.parameters === compareStatement.parameters,
      )
    ) {
      const clonedStatement = _.cloneDeep(statement);

      clonedStatement.deleted = true;
      comparedRule.statements.push(clonedStatement);
    }
  });

  // check for providers changes
  comparedRule.actors.forEach(actor => {
    // entity was created
    if (!hasEntity(actor, ruleToCompare.actors)) {
      actor.created = true;
    }
  });
  ruleToCompare.actors.forEach(actor => {
    // entity was removed
    if (!hasEntity(actor, comparedRule.actors)) {
      const clonedActor = _.cloneDeep(actor);

      clonedActor.deleted = true;
      comparedRule.actors.push(clonedActor);
    }
  });

  if (rule.enabled && !ruleToCompare.enabled) {
    comparedRule.enabledChanged = true;
  }

  if (!rule.enabled && ruleToCompare.enabled) {
    comparedRule.disabledChanged = true;
  }

  //check for ipversion change
  if (rule.ip_version !== ruleToCompare.ip_version) {
    comparedRule.ipVersionChanged = true;
  }

  return comparedRule;
}

/**
 * Determine if a set of rules if different than another set
 * @param rules
 * @returns {*}
 */
export function hasRulesUpdate(rules) {
  return rules.some(rule => rule.update_type !== null);
}

/**
 * Return whether or not an entity is contained within a collection of entities
 * @param entity
 * @param entities
 * @param isServices
 * @returns {boolean}
 */
export function hasEntity(entity, entities, isServices) {
  const idFromHref = GridDataUtils.getIdFromHref;

  return _.some(entities, item => {
    if (isServices) {
      if (entity.href && item.href) {
        return idFromHref(entity.href) === idFromHref(item.href);
      }

      return generateKey(entity) === generateKey(item);
    }

    const value = _.values(entity)[0];
    const compareValue = _.values(item)[0];

    if (typeof value === 'boolean') {
      return generateKey(entity) === generateKey(item);
    }

    return _.isString(value) && _.isString(compareValue)
      ? value === compareValue
      : idFromHref(value.href) === idFromHref(compareValue.href);
  });
}

/**
 * Return whether or not a userGroup is contained within a collection of userGroups
 * @param userGroup
 * @param userGroups
 * @returns {boolean}
 */
export function hasUserGroup(userGroup, userGroups) {
  const idFromHref = GridDataUtils.getIdFromHref;

  return _.some(userGroups, ug => idFromHref(userGroup.href) === idFromHref(ug.href));
}

/**
 * Check if ruleset scope contains label with specified keys
 * @param keys
 * @param scope
 * @returns {boolean}
 */
export function hasLabelKeyInScope(keys, scope) {
  return scope.some(o => keys.includes(LabelStore.getSpecified(o.href).key));
}

export function hasLabelKeyInKeyedScope(keys, scope) {
  return keys.some(key => scope[key] && scope[key].type !== 'all');
}

/**
 * Determine if an entity is unmanaged
 * @param entity
 */
export function isUnmanagedEntity(entity) {
  return entity.ip_list || _.isEqual(entity, getAnythingEntity());
}

/**
 * Determine if an entity is managed
 * @param entity
 */
export function isManagedEntity(entity) {
  return (
    entity.label ||
    entity.label_group ||
    entity.workload ||
    entity.virtual_service ||
    _.isEqual(entity, getAllManagedWorkloadsEntity())
  );
}

/**
 * Check an array of entities for an unmanaged entity
 * @param entities
 * @returns boolean
 */
export function hasUnmanagedEntity(entities) {
  return entities.some(entity => isUnmanagedEntity(entity));
}

/**
 * Check an array of entities for a managed entity
 * @param entities
 * @returns boolean
 */
export function hasManagedEntity(entities) {
  return entities.some(entity => isManagedEntity(entity));
}

/**
 * Return an array of entities without unmanaged entities
 * @param entities
 * @returns {*}
 */
export function removeUnmanagedEntities(entities) {
  return entities.filter(entity => isManagedEntity(entity));
}

/**
 * Return an array of entities without managed entities
 * @param entities
 * @returns {*}
 */
export function removeManagedEntities(entities) {
  return entities.filter(entity => isUnmanagedEntity(entity));
}

/**
 * Return an array of entities without `All Workloads` entity
 * @param entities
 * @returns {*}
 */
export function removeAllWorkloadsEntity(entities) {
  return _.filter(entities, p => !_.isEqual(p, getAllManagedWorkloadsEntity()));
}

/**
 * Return entity that represents Everything
 * @returns {{actors: string}}
 */
export function getAnythingEntity() {
  return {
    actors: 'all',
  };
}

/**
 * Return entity that represents All Workloads
 * @returns {{actors: string}}
 */
export function getAllManagedWorkloadsEntity() {
  return {
    actors: 'ams',
  };
}

/**
 * Return a string array of scope label types that can be used in rule writing
 * @param scopes
 * @returns {Array}
 */
export function getAvailableScopeLabelTypes(scopes) {
  return _.transform(
    scopes.pb,
    (result, pb) => {
      _.each(pb, labelOrGroup => {
        const label = labelOrGroup.label;
        const labelGroup = labelOrGroup.label_group;
        const labelKey =
          LabelStore.getSpecified(label && label.href).key ||
          LabelGroupStore.getSpecified(labelGroup && labelGroup.href).key;

        if (!result.includes(labelKey) && !scopeLabelTypes.includes(labelKey)) {
          result.push(labelKey);
        }
      });
    },
    [],
  );
}

/**
 * Return a string array of scope label types that are being used by the specified ruleset
 * @param ruleset
 * @returns {Array}
 */
export function getUsedScopeLabelTypes(ruleset) {
  const usedLabelTypes = [];

  function addLabelType(entity) {
    if (entity.label) {
      const label = LabelStore.getSpecified(entity.label.href);

      if (label && scopeLabelTypes.includes(label.key)) {
        usedLabelTypes.push(label.key);
      }
    }
  }
  ruleset.rules.forEach(rule => {
    rule.providers.forEach(provider => addLabelType(provider));
    rule.consumers.forEach(consumer => addLabelType(consumer));
  });

  return _.uniq(usedLabelTypes);
}

/**
 * Return whether the ruleset has a scope match
 * @param ruleset
 * @param labelHrefs array of label href strings
 * @returns true/false
 */
export function rulesetHasScope(ruleset, labelHrefs) {
  return _.some(ruleset.scopes, scope => {
    if (!scope || scope.length < labelHrefs.length) {
      return;
    }

    const pbLabelHrefs = getScopeHrefs(scope);
    const pbIntersection = _.intersection(pbLabelHrefs, labelHrefs);

    if (pbIntersection.length !== labelHrefs.length) {
      return;
    }

    return true;
  });
}

/**
 * Return the number of applications that contain the specified ruleset
 * @param ruleset
 * @param applications
 * @returns {Number}
 */
export function applicationsInRuleset(ruleset, applications) {
  return getApplicationsInRuleset(ruleset, applications).length;
}

/**
 * Return the applications containing the specified ruleset
 * @param ruleset
 * @param applications
 * @returns {Array}
 */
export function getApplicationsInRuleset(ruleset, applications) {
  return _.filter(applications, application => application.rule_set && application.rule_set.href === ruleset.href);
}

export function doesScopeExactMatchApplication(scope, application) {
  const scopeWithKeys = getScopeWithLabelKeys(scope);

  const scopeLabels = {
    app: scopeWithKeys.app ? scopeWithKeys.app.href : null,
    env: scopeWithKeys.env ? scopeWithKeys.env.href : null,
    loc: scopeWithKeys.loc ? scopeWithKeys.loc.href : null,
  };

  const appLabels = {
    app: application.app_label ? application.app_label.href : null,
    env: application.env_label ? application.env_label.href : null,
    loc: application.loc_label ? application.loc_label.href : null,
  };

  return scopeLabels.app === appLabels.app && scopeLabels.env === appLabels.env && scopeLabels.loc === appLabels.loc;
}

/**
 * Check if the specified scope matches the group labels
 * @param scope
 * @param application
 * @returns {boolean}
 */
export function isScopeInApplication(scope, application) {
  const scopeWithKeys = getScopeWithLabelKeys(scope);
  const scopeExclusions = scope.reduce((result, item) => {
    result[(item.label || item.label_group).key] = item.exclusion;

    return result;
  }, {});

  const scopeLabels = {
    app: scopeWithKeys.app ? scopeWithKeys.app.href : null,
    env: scopeWithKeys.env ? scopeWithKeys.env.href : null,
    loc: scopeWithKeys.loc ? scopeWithKeys.loc.href : null,
  };

  const appLabels = _.reduce(
    application.labels,
    (memo, label) => {
      memo[label.key] = label.href;

      return memo;
    },
    {},
  );

  return ['app', 'env', 'loc'].every(
    key =>
      !scopeLabels[key] ||
      (!scopeExclusions[key] && scopeLabels[key] === appLabels[key]) ||
      (scopeExclusions[key] && scopeLabels[key] !== appLabels[key]),
  );
}

/**
 * Return application that matches given scope and has that ruleset as primary ruleset
 * @param scope
 * @param applications
 * @param ruleset
 * @returns {*}
 */
export function getApplicationInScope(scope, applications, ruleset) {
  return _.find(
    applications,
    app => app.rule_set && app.rule_set.href === ruleset.href && doesScopeExactMatchApplication(scope, app),
  );
}

/**
 * Check if ruleset is part of an application
 * @param ruleset
 * @param application
 * @returns {*}
 */
export function isRulesetInApplication(ruleset, application) {
  return ruleset.scopes.some(scope => isScopeInApplication(scope, application));
}

/**
 * Removes a set of label hrefs from a scope and return the result
 * @param scopes
 * @param labelHrefs array of label href strings
 * @returns filtered list of scopes
 */
export function rulesetRemoveScope(scopes, labelHrefs) {
  return _.filter(scopes, scope => {
    if (!scope || scope.length < labelHrefs.length) {
      return true;
    }

    const pbLabelHrefs = getScopeHrefs(scope);
    const pbIntersection = _.intersection(pbLabelHrefs, labelHrefs);

    if (pbIntersection.length !== labelHrefs.length) {
      return true;
    }
  });
}

/**
 * Return the list of hrefs of the ruleset scope pb/ub object
 * @param ruleset.scope
 * @returns labelHrefs array of label href strings
 */
export function getScopeHrefs(scopeObjects) {
  return _.map(scopeObjects, obj => {
    if (obj) {
      if (obj.label) {
        return obj.label.href;
      }

      if (obj.label_group) {
        return obj.label_group.href;
      }
    }
  });
}

/**
 * Return whether two rules are unchanged or not
 * Known limitation: doesn't check for user group changes
 *
 * @param rule         Rule
 * @param newRule      Rule to compare
 * @returns {boolean}
 */
export function ruleUnchanged(rule, newRule) {
  if (!rule || !newRule) {
    return false;
  }

  // If the number of providers or consumers are not same, mark as modified
  if (rule.providers.length !== newRule.providers.length || rule.consumers.length !== newRule.consumers.length) {
    return false;
  }

  const services = rule.ingress_services
    .map(item => (item.href ? item.href.replace('active', 'draft') : generateKey(fixServicePortForClient(item))))
    .sort()
    .join(',');
  const newRuleServices = newRule.ingress_services
    .map(item => (item.href ? item.href.replace('active', 'draft') : generateKey(fixServicePortForClient(item))))
    .sort()
    .join(',');

  if (services !== newRuleServices) {
    return false;
  }

  if (generateKey(rule.resolve_labels_as) !== generateKey(newRule.resolve_labels_as)) {
    return false;
  }

  const ruleProvidersHref = rule.providers.flatMap(item => (item.actors ? item.actors : deepPluck(item, 'href')));
  const newRuleProvidersHref = newRule.providers.flatMap(item => (item.actors ? item.actors : deepPluck(item, 'href')));

  // This means that there is a provider which has one or more items which are changed
  const providersChanged =
    _.uniqBy([...ruleProvidersHref, ...newRuleProvidersHref], href => href.replace('active', 'draft')).length >
    ruleProvidersHref.length;

  if (providersChanged) {
    return false;
  }

  const ruleConsumersHref = rule.consumers.flatMap(item => (item.actors ? item.actors : deepPluck(item, 'href')));
  const newRuleConsumersHref = newRule.consumers.flatMap(item => (item.actors ? item.actors : deepPluck(item, 'href')));
  const consumersChanged =
    _.uniqBy([...ruleConsumersHref, ...newRuleConsumersHref], href => href.replace('active', 'draft')).length >
    ruleConsumersHref.length;

  return !consumersChanged;
}

/**
 * Return the status for a rule
 * @param rule, version, rulesetEnabled
 * @returns object of type/text of status
 */
export function getRuleProvisionStatus(rule, version) {
  let text;
  let type;

  if (rule.update_type) {
    switch (rule.update_type) {
      case 'delete':
        if (rule.proposedDelete) {
          text = intl('Rulesets.Rules.DeleteProposed');
        } else if (version === 'draft') {
          text = intl('Rulesets.Rules.DeletionPending');
        } else {
          text = intl('Rulesets.Rules.Deleted');
        }

        type = 'deleted';
        break;
      case 'create':
        if (version === 'draft') {
          text = intl('Rulesets.Rules.AdditionPending');
        } else {
          text = intl('Rulesets.Rules.Added');
        }

        type = 'created';
        break;
      case 'update':
        if (rule.proposedUpdate) {
          text = intl('Rulesets.Rules.ModifiedProposed');
        } else if (version === 'draft') {
          text = intl('Rulesets.Rules.ModifiedPending');
        } else {
          text = intl('Rulesets.Rules.Modified');
        }

        type = 'updated';
        break;
    }
  } else if (rule.deleted) {
    if (version === 'draft') {
      text = intl('Rulesets.Rules.DeletionPending');
    } else {
      text = intl('Rulesets.Rules.Deleted');
    }

    type = 'deleted';
  } else if (rule.created) {
    if (version === 'draft') {
      text = intl('Rulesets.Rules.AdditionPending');
    } else {
      text = intl('Rulesets.Rules.Added');
    }

    type = 'created';
  } else if (rule.href.includes('proposed')) {
    text = intl('Rulesets.Rules.Proposed');
  }

  if (rule.href.includes('proposed')) {
    type = 'disabled';
  }

  return {
    type,
    text,
  };
}

export function markLabels(entity, labelTypeMap) {
  if (entity.deleted) {
    return;
  }

  if (entity.label) {
    labelTypeMap[entity.label.key] = true;
  } else if (entity.label_group) {
    labelTypeMap[entity.label_group.key] = true;
  }
}

export function formatScopeLabel(type, label, exclusion = false) {
  if (label && _.isString(label.href)) {
    if (label.href.indexOf('label_group') > 0) {
      return <LabelGroup text={label.name} type={type} exclusion={exclusion || label.exclusion} />;
    }

    return <Label text={label.value} type={type} exclusion={exclusion || label.exclusion} />;
  }

  switch (type) {
    case 'app':
      return <Label text={intl('Common.AllApplications')} type={type} />;
    case 'loc':
      return <Label text={intl('Common.AllLocations')} type={type} />;
    case 'env':
      return <Label text={intl('Common.AllEnvironments')} type={type} />;
    //no default
  }
}

export function addUsageEntities(rule) {
  ['providers', 'consumers'].forEach(type => {
    if (rule.use_workload_subnets?.includes(type) && !rule[type].some(entity => entity.useWorkloadSubnets)) {
      rule[type].unshift({useWorkloadSubnets: true});
    }

    if (_.get(rule, type, []).some(entity => entity.usesVirtualServices || entity.usesVirtualServicesWorkloads)) {
      // Don't add the Virtual Service usage entity if it already exists
      return;
    }

    const resolveLabelsAsString = _.get(rule, `resolve_labels_as.${type}`, []).sort().join(',');

    if (resolveLabelsAsString === 'virtual_services') {
      rule[type].unshift({usesVirtualServices: true});
    }

    if (resolveLabelsAsString === 'virtual_services,workloads') {
      rule[type].unshift({usesVirtualServicesWorkloads: true});
    }
  });

  return rule;
}

export function getSelectedLabelsHrefs(selected) {
  const labels = Array.isArray(selected?.[intl('Common.Labels')]) ? selected[intl('Common.Labels')] : [];
  const labelGroups = Array.isArray(selected?.[intl('Labels.Groups')]) ? selected[[intl('Labels.Groups')]] : [];

  return [...labels, ...labelGroups].map(item => item.href);
}

export function getSelectedScope(facet, selected) {
  const selectedLabels = getSelectedLabelsHrefs(selected);

  // Only Labels, Label Groups and Rulesets API endpoints support label groups.
  return ['labels', 'labelGroups', 'rulesetName'].includes(facet)
    ? selectedLabels.map(href => (href.includes('label_groups') ? {label_group: {href}} : {label: {href}}))
    : selectedLabels.filter(href => !href.includes('label_groups')).map(href => ({label: {href}}));
}

export default {
  fixRulesetArr,
  formatScopeLabel,
  getMergedRules,
  cleanPbUb,
  getPbUbAvengerReady,
  parseAvengerReadyPbUb,
  rulesetMap,
  getRulesetAsWritableData,
  getRuleAsWritableData,
  getScopeAsWritableData,
  getDuplicateRuleset,
  isGlobal,
  scopeContainsAllLabel,
  getScopesWithLabelKeys,
  getScopeWithLabelKeys,
  getScopesFromLabelKeys,
  getScopesAsLabelsArray,
  getScopesServicesLabelsAndGroups,
  getComparedScopes,
  filterRules,
  hasScopesDiff,
  getComparedRules,
  getComparedRule,
  hasRulesUpdate,
  hasEntity,
  hasUserGroup,
  hasLabelKeyInScope,
  hasLabelKeyInKeyedScope,
  isUnmanagedEntity,
  isManagedEntity,
  hasUnmanagedEntity,
  hasManagedEntity,
  removeUnmanagedEntities,
  removeManagedEntities,
  removeAllWorkloadsEntity,
  getAnythingEntity,
  getAllManagedWorkloadsEntity,
  getAvailableScopeLabelTypes,
  getUsedScopeLabelTypes,
  rulesetHasScope,
  applicationsInRuleset,
  getApplicationsInRuleset,
  doesScopeExactMatchApplication,
  isScopeInApplication,
  getApplicationInScope,
  isRulesetInApplication,
  rulesetRemoveScope,
  getScopeHrefs,
  getRuleProvisionStatus,
  getComparedIpTablesRules,
  getComparedIpTablesRule,
  markLabels,
  ruleUnchanged,
  addUsageEntities,
  getSelectedLabelsHrefs,
  getSelectedScope,
};
