/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import Button from './Button.jsx';
import Icon from './Icon';
import ComponentUtils from '../utils/ComponentUtils';
import {NotificationGroup} from '.';
import actionCreators from '../actions/actionCreators';
import {ModeAlert} from '../modals';

const defaultTid = 'comp-pagination';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    page: PropTypes.number,
    totalRows: PropTypes.number,
    pageLength: PropTypes.number,
    onPageChange: PropTypes.func,
    count: PropTypes.object,
    isFiltered: PropTypes.bool,
    type: PropTypes.string,
    exceededResultsLimit: PropTypes.bool,
  },

  getInitialState() {
    return {
      checked: false,
      radioSelect: '',
      notifications: [NotificationGroup],
      notificationsShrink: false,
      notificationsSidebar: false,
      showWarningUI: this.props.exceededResultsLimit,
    };
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.exceededResultsLimit !== this.props.exceededResultsLimit) {
      this.setState({showWarningUI: nextProps.exceededResultsLimit});
    }
  },

  getDefaultProps() {
    return {
      page: 1,
      totalRows: 0,
      pageLength: 1,
      onPageChange: _.noop,
    };
  },

  handleDecrementPage() {
    this.props.onPageChange(Math.max(this.props.page - 1, 1));
  },

  handleIncrementPage() {
    const numPages = Math.ceil(this.props.totalRows / this.props.pageLength);

    this.props.onPageChange(Math.min(this.props.page + 1, numPages));
  },

  handleWarningIconClick() {
    actionCreators.openDialog(<ModeAlert message="warnUserSearchLimit" isWarnModal={true} hideCheckbox={true} />);
  },

  render() {
    let type;
    let total;

    if (this.props.count) {
      // Just in case...
      if (this.props.isFiltered) {
        type = 'matched';
        total = this.props.count.matched;
      } else {
        total = this.props.count.total;
      }
    }

    total = Number(total || this.props.totalRows);

    const numPages = Math.ceil(this.props.totalRows / this.props.pageLength);
    const tids = ComponentUtils.tid(defaultTid, this.props.tid);
    const max = Math.min(this.props.totalRows, this.props.pageLength * this.props.page);
    const asterisk = (
      <span className="Pagination-asterisk" data-tid="Pagination-asterisk">
        *
      </span>
    );
    const infoIcon = <Icon styleClass="warn" helpText="Click here for more information" name="info" size="medium" />;
    let paginationNumberString = intl(
      'Common.PaginationNumberString',
      {
        total,
        type: this.props.type ?? type ?? 'total',
        plus: Boolean(this.props.count && total > 100_000),
        notZero: max !== 0,
        min: 1 + this.props.pageLength * (this.props.page - 1),
        max,
      },
      {html: true},
    );

    paginationNumberString = total ? paginationNumberString : null;

    let paginationTypeString = intl(
      'Common.PaginationTypeString',
      {
        total,
        type: this.props.type ?? type ?? 'total',
      },
      {html: true},
    );

    paginationTypeString = total ? paginationTypeString : null;

    const pagination = (
      <div>
        <span data-tid="comp-pagination-counts" className="Pagination-Counts">
          {paginationNumberString}
          {this.state.showWarningUI ? asterisk : null} {paginationTypeString}
        </span>
        <span className="Pagination-info-icon" onClick={this.handleWarningIconClick}>
          {' '}
          {this.state.showWarningUI ? infoIcon : null}
        </span>
      </div>
    );

    return (
      <div className="Pagination" data-tid={ComponentUtils.tidString(tids)}>
        {pagination}
        {numPages > 1 && (
          <Button
            icon="back"
            content="icon-only"
            type="secondary"
            disabled={this.props.page <= 1}
            onClick={this.handleDecrementPage}
            tid="previous"
          />
        )}
        {numPages > 1 && (
          <Button
            icon="next"
            content="icon-only"
            type="secondary"
            disabled={this.props.page >= numPages}
            onClick={this.handleIncrementPage}
            tid="next"
          />
        )}
      </div>
    );
  },
});
