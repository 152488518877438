/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import {findDOMNode} from 'react-dom';
import Slider from 'react-rangeslider';
import actionCreators from '../../actions/actionCreators';
import {Icon, Checkbox, RadioGroup, Button} from '../../components';
import RestApiUtils from '../../utils/RestApiUtils';
import RenderUtils from '../../utils/RenderUtils';
import CheckboxModal from '../../modals/CheckboxModal.jsx';
import TrafficStore from '../../stores/TrafficStore';
import ServiceStore from '../../stores/ServiceStore';
import MapPageStore from '../../stores/MapPageStore';
import TrafficFilterStore from '../../stores/TrafficFilterStore';
import TimeFilter from './TimeFilter.jsx';

export default React.createClass({
  getInitialState() {
    const filters = TrafficFilterStore.getAll();
    const currentFlow = this.calculateFlowLevel(filters.trafficVolume, filters);

    return {
      hidden: true,
      filters,
      currentFlow,
      highlight: filters.highlight,
      trafficVolume: filters.trafficVolume,
      enforcementBoundariesCount: MapPageStore.getTotalEnforcementBoundaries(),
    };
  },

  componentWillMount() {
    _.defer(() => {
      if (!ServiceStore.isLoaded()) {
        // Is this a reasonable thing to do??? Probably not - what's another option?
        RestApiUtils.services.getCollection({max_results: 500});
      }

      RestApiUtils.secPolicies.getCollection();
    });

    // Create debounced version of method that invoke update action
    this.selectTrafficFiltersDebounced = _.debounce(this.selectTrafficFilters, 500);
  },

  componentDidMount() {
    TrafficFilterStore.addChangeListener(this.handleTrafficFilterUpdate);
    TrafficStore.addChangeListener(this.handleTrafficFilterUpdate);
    MapPageStore.addChangeListener(this.handleTrafficFilterUpdate);
  },

  shouldComponentUpdate(nextProps, nextState) {
    // Do not rerender if filter is hidden and remain hidden and highlighting hasn't been changed
    const isFilterHidden = !nextState.hidden || !this.state.hidden;
    const highlightFilterChangeBool = this.state.highlight !== nextState.highlight;
    const policyVersionChange = nextProps.policyVersion !== this.props.policyVersion;
    const disableChangeBool = this.props.disabled !== nextProps.disabled;

    return isFilterHidden || highlightFilterChangeBool || policyVersionChange || disableChangeBool;
  },

  componentWillUnmount() {
    TrafficFilterStore.removeChangeListener(this.handleTrafficFilterUpdate);
    TrafficStore.removeChangeListener(this.handleTrafficFilterUpdate);
    MapPageStore.removeChangeListener(this.handleTrafficFilterUpdate);
  },

  handleTrafficFilterUpdate() {
    const filters = TrafficFilterStore.getAll();
    const hidden = TrafficFilterStore.getHidden();

    if (!hidden) {
      findDOMNode(this).focus();
    }

    this.setState({
      hidden,
      filters,
      highlight: filters.highlight,
      currentFlow: this.calculateFlowLevel(filters.trafficVolume, filters),
      trafficVolume: filters.trafficVolume,
      enforcementBoundariesCount: MapPageStore.getTotalEnforcementBoundaries(),
    });
  },

  handleSetAllowedTraffic() {
    this.selectTrafficConnectionFilters({label: 'allowAllowedTraffic', value: !this.state.filters.allowAllowedTraffic});
  },

  handleSetBlockedTraffic() {
    this.selectTrafficConnectionFilters({label: 'allowBlockedTraffic', value: !this.state.filters.allowBlockedTraffic});
  },

  handleSetUnknownTraffic() {
    this.selectTrafficConnectionFilters({label: 'allowUnknownTraffic', value: !this.state.filters.allowUnknownTraffic});
  },

  handleSetPotentiallyBlockedTraffic() {
    this.selectTrafficConnectionFilters({
      label: 'allowPotentiallyBlockedTraffic',
      value: !this.state.filters.allowPotentiallyBlockedTraffic,
    });
  },

  handleSetAllowedAcrossBoundaryTraffic() {
    this.selectTrafficConnectionFilters({
      label: 'allowAllowedAcrossBoundaryTraffic',
      value: !this.state.filters.allowAllowedAcrossBoundaryTraffic,
    });
  },

  handleSetBlockedByBoundaryTraffic() {
    this.selectTrafficConnectionFilters({
      label: 'allowBlockedByBoundaryTraffic',
      value: !this.state.filters.allowBlockedByBoundaryTraffic,
    });
  },

  handleSetPotentiallyBlockedByBoundaryTraffic() {
    this.selectTrafficConnectionFilters({
      label: 'allowPotentiallyBlockedByBoundaryTraffic',
      value: !this.state.filters.allowPotentiallyBlockedByBoundaryTraffic,
    });
  },

  handleSetEnforced() {
    this.selectTrafficFilters({label: 'enforced', value: !this.state.filters.enforced});
  },

  handleSetIdle() {
    this.selectTrafficFilters({label: 'idle', value: !this.state.filters.idle});
  },

  handleSetVisibility() {
    this.selectTrafficFilters({label: 'visibility', value: !this.state.filters.visibility});
  },

  handleSetSelective() {
    this.selectTrafficFilters({label: 'selective', value: !this.state.filters.selective});
  },

  handleSetInboundTraffic() {
    this.selectTrafficFilters({label: 'allowInboundTraffic', value: !this.state.filters.allowInboundTraffic});
  },

  handleSetInterAppTraffic() {
    this.selectTrafficFilters({label: 'allowInterAppTraffic', value: !this.state.filters.allowInterAppTraffic});
  },

  handleSetInternetTraffic() {
    this.selectTrafficFilters({label: 'allowInternetTraffic', value: !this.state.filters.allowInternetTraffic});
  },

  handleSetFqdnTraffic() {
    this.selectTrafficFilters({label: 'allowFqdnTraffic', value: !this.state.filters.allowFqdnTraffic});
  },

  handleSetIntraAppTraffic() {
    this.selectTrafficFilters({label: 'allowIntraAppTraffic', value: !this.state.filters.allowIntraAppTraffic});
  },

  handleSetIpListTraffic() {
    this.selectTrafficFilters({label: 'allowIpListTraffic', value: !this.state.filters.allowIpListTraffic});
  },

  handleSetOutboundTraffic() {
    this.selectTrafficFilters({label: 'allowOutboundTraffic', value: !this.state.filters.allowOutboundTraffic});
  },

  handleSetBroadcastTraffic() {
    this.selectTrafficConnectionFilters({
      label: 'allowBroadcastTraffic',
      value: !this.state.filters.allowBroadcastTraffic,
    });
  },

  handleSetMulticastTraffic() {
    this.selectTrafficConnectionFilters({
      label: 'allowMulticastTraffic',
      value: !this.state.filters.allowMulticastTraffic,
    });
  },

  handleSetIcmpTraffic() {
    this.selectTrafficConnectionFilters({label: 'allowIcmpTraffic', value: !this.state.filters.allowIcmpTraffic});
  },

  handleSetServices(evt) {
    this.selectServiceFilters({label: 'ignoreServices', value: evt.target.value === 'ignore'});
  },

  handleSetTrafficTimeFilters() {
    if (this.state.filters.timeFilter.type !== 'provision') {
      this.selectTrafficTimeFilters({label: 'timeFilter', value: {type: 'provision'}});
    } else {
      this.selectTrafficTimeFilters({label: 'timeFilter', value: {type: 'time', value: 'anytime'}});
    }
  },

  handleSetVulnerabilitySeverity(value) {
    this.selectTrafficFilters({label: 'vulnerabilitySeverity', value});
  },

  handleSetExposedVulnerabilities() {
    this.selectTrafficFilters({label: 'exposedVulnerabilities', value: !this.state.filters.exposedVulnerabilities});
  },

  handleSetBlockedVulnerableTraffic() {
    this.selectTrafficFilters({
      label: 'showVulnerableBlockedTraffic',
      value: !this.state.filters.showVulnerableBlockedTraffic,
    });
  },

  handleSetTrafficVolume(value) {
    // Call debounced update action to recalculate everything on screen,
    // will be really fired in 500ms after user stops moving slider
    this.selectTrafficFiltersDebounced({label: 'trafficVolume', value});
    this.setState({
      trafficVolume: value,
      currentFlow: this.calculateFlowLevel(value),
    });
  },

  handleSetUnmanaged() {
    this.selectTrafficFilters({label: 'unmanaged', value: !this.state.filters.unmanaged});
  },

  calculateFlowLevel(trafficVolume, filters) {
    filters = filters || this.state.filters;

    // Calculate the current flow by normalizing the traffic volume level to the
    // min flow to max flow range. The traffic volume level is for a scale of 1-100
    // so we want its equivalent value for a scale of minFlow-maxFlow.
    return Math.round((filters.maxFlow - filters.minFlow) * ((trafficVolume - 1) / 99) + filters.minFlow);
  },

  handleHideFilter(evt) {
    // if the element being blurred out contains
    // the element that caused the blur (e.relatedTarget)
    // then don't hide it, and keep the focus on
    const relatedTarget = evt.relatedTarget || evt.nativeEvent.explicitOriginalTarget || document.activeElement;

    if (findDOMNode(this).contains(relatedTarget)) {
      findDOMNode(this).focus();

      return;
    }

    this.setState({hidden: true}, () => actionCreators.setTrafficFilterHidden(true));
  },

  selectTrafficConnectionFilters(data) {
    // Selecting Traffic Filters which get affected by the connection property.
    actionCreators.selectTrafficConnectionFilters(data);
  },

  selectServiceFilters(data) {
    actionCreators.selectServiceFilters(data);
  },

  selectTrafficFilters(data) {
    actionCreators.selectTrafficFilters(data);
  },

  selectTrafficTimeFilters(data) {
    actionCreators.selectTrafficTimeFilters(data);
  },

  handleResetToDefault() {
    actionCreators.resetDefaultFilters();
  },

  handleClose() {
    actionCreators.setTrafficFilterHidden(false);
    actionCreators.closeDialog();
  },

  handleSelectedServices() {
    const {services, checkboxes, portMap} = TrafficFilterStore.getSelectedServices();

    const serviceConfirm = updatePortMap => {
      this.setState({
        services: updatePortMap,
      });
      this.selectServiceFilters({label: 'services', value: updatePortMap});
    };

    // serviceValues: array of all the policy services
    // checkboxValues: map of all checkboxes
    // portMap: map of which ports are filtered, saved in local storage

    let messageSubtitle = intl('Map.TryAgain');

    if (MapPageStore.getMapLevel() === 'full' || MapPageStore.getMapLevel() === 'workload') {
      if (!(TrafficStore.getAllWorkloadTraffics().length || TrafficStore.getAllRoleTraffics().length)) {
        messageSubtitle = intl('Map.NoTraffic');
      }
    }

    const data = {
      title: intl('Map.Traffic.Filter.IgnoreServices'),
      messageTitle: intl('Common.NoData'),
      messageSubtitle,
      serviceValues: services,
      checkboxValues: checkboxes,
      portMap,
      confirm: serviceConfirm,
    };

    actionCreators.openDialog(<CheckboxModal data={data} />);

    this.selectServiceFilters({label: 'ignoreServices', value: true});
  },

  handleShowFilter() {
    actionCreators.setTrafficFilterHidden(!this.state.hidden);
  },

  handleHidePanel() {
    actionCreators.setTrafficFilterHidden(true);
  },

  render() {
    const {policyVersion, appMapVersion, disabled, mapLevel, mapType} = this.props;
    const {filters, hidden, highlight, currentFlow, trafficVolume, enforcementBoundariesCount} = this.state;
    const showFlowNumbers = filters.minFlow !== -1 && filters.maxFlow !== -1;
    const showBoundaries = enforcementBoundariesCount > 0;

    const classes = disabled
      ? 'DisabledFilterPanel'
      : cx('FilterPanel', {
          'FilterPanel--active': !hidden,
          'FilterPanel-draft': policyVersion === 'draft',
          'FilterPanel-vulnerability': appMapVersion === 'vulnerability',
        });

    const buttonClasses = disabled
      ? 'DisabledFilterButton'
      : cx('FilterButton', {
          'FilterButton--highlighted': highlight,
        });

    const policyStateFilters = (
      <div className="TrafficFilters">
        <h4>{intl('Common.Workloads')}</h4>
        <div className="FilterPanel-Row FilterPanel-Row--InTwoColumns">
          <Checkbox
            label={intl('Common.VisibilityOnly')}
            checked={filters.visibility}
            onChange={this.handleSetVisibility}
            tid="show-visibility"
          />
          <Checkbox label={intl('Common.Idle')} checked={filters.idle} onChange={this.handleSetIdle} tid="show-idle" />
          <Checkbox
            label={intl('Workloads.FullEnforcement')}
            checked={filters.enforced}
            onChange={this.handleSetEnforced}
            tid="show-full"
          />
          <Checkbox
            label={intl('Common.Unmanaged')}
            checked={filters.unmanaged}
            onChange={this.handleSetUnmanaged}
            tid="show-unmanaged"
          />
          <Checkbox
            label={intl('EnforcementBoundaries.SelectiveEnforcement')}
            checked={filters.selective}
            onChange={this.handleSetSelective}
            tid="show-selective"
          />
        </div>
      </div>
    );

    const servicesOptions = [
      {value: intl('Common.StandardServices'), key: 'all'},
      {
        value: (
          <span>
            {intl('Common.CustomServices')}&nbsp;
            <span className="IgnoreServicesEdit" onClick={this.handleSelectedServices} data-tid="edit-ignore-services">
              ({intl('Common.Edit')})
            </span>
          </span>
        ),
        key: 'ignore',
      },
    ];

    const vulnerabilityOptions = [
      {value: intl('Vulnerability.All'), key: false},
      {value: intl('Vulnerability.ExposedVulnerabilities'), key: true},
    ];

    let allowedAcrossBoundary = null;
    let blockedByBoundary = null;
    let potentiallyBlockedByBoundary = null;

    if (showBoundaries) {
      allowedAcrossBoundary = (
        <Checkbox
          label={intl('Common.AllowedAcrossBoundary')}
          tid="show-allowed"
          checked={filters.allowAllowedAcrossBoundaryTraffic}
          onChange={this.handleSetAllowedAcrossBoundaryTraffic}
        />
      );

      blockedByBoundary = (
        <Checkbox
          label={intl('Common.BlockedByBoundary')}
          tid="show-blocked"
          checked={filters.allowBlockedByBoundaryTraffic}
          onChange={this.handleSetBlockedByBoundaryTraffic}
        />
      );

      potentiallyBlockedByBoundary = (
        <Checkbox
          label={intl('Common.PotentiallyBlockedByBoundary')}
          tid="potentially-blocked"
          checked={filters.allowPotentiallyBlockedByBoundaryTraffic}
          onChange={this.handleSetPotentiallyBlockedByBoundaryTraffic}
        />
      );
    }

    return (
      <div className={classes} tabIndex="-1" onBlur={this.handleHideFilter}>
        <div className={buttonClasses} onClick={!disabled && this.handleShowFilter} data-tid="filter-button">
          {intl('Common.Filter')}&nbsp;
          <Icon name="dropdown" />
        </div>
        {!hidden && (
          <div className="FilterPanel-body">
            {/*Filter Panel For Vulnerability App Map. Traffic Filters are enabled only in vulnerability view of app map. Not the policy data view*/}
            {appMapVersion === 'vulnerability' && (
              <div className="TrafficFilters">
                <h4>{intl('Map.TrafficLinks')}</h4>
                <div className="FilterPanel-Row">
                  <RadioGroup
                    data={servicesOptions}
                    value={filters.ignoreServices ? 'ignore' : 'all'}
                    format="vertical"
                    tid="ignore-services"
                    onChange={this.handleSetServices}
                  />
                </div>
                <div className="FilterPanel-Divider" />
                <div className="FilterPanel-Row FilterPanel-Row--InTwoColumns">
                  <Checkbox
                    label={intl('Map.Traffic.Filter.AllowIntraGroup')}
                    tid="show-intra-group"
                    checked={filters.allowIntraAppTraffic}
                    onChange={this.handleSetIntraAppTraffic}
                  />
                  <Checkbox
                    label={intl('PCE.FQDN')}
                    tid="show-fqdn"
                    checked={filters.allowFqdnTraffic}
                    onChange={this.handleSetFqdnTraffic}
                  />
                  <Checkbox
                    label={intl('Common.Internet')}
                    tid="show-internet"
                    checked={filters.allowInternetTraffic}
                    onChange={this.handleSetInternetTraffic}
                  />
                  <Checkbox
                    label={intl('Common.IPList')}
                    tid="show-ip-list"
                    checked={filters.allowIpListTraffic}
                    onChange={this.handleSetIpListTraffic}
                  />
                </div>
                <div className="FilterPanel-Divider" />
                <div className="FilterPanel-Row FilterPanel-Row--InTwoColumns">
                  <Checkbox
                    label={intl('Map.Traffic.Broadcast')}
                    tid="show-broadcast"
                    checked={filters.allowBroadcastTraffic}
                    onChange={this.handleSetBroadcastTraffic}
                  />
                  <Checkbox
                    label={intl('Map.Traffic.Multicast')}
                    tid="show-multicast"
                    checked={filters.allowMulticastTraffic}
                    onChange={this.handleSetMulticastTraffic}
                  />
                  <Checkbox
                    label={intl('Protocol.ICMP')}
                    tid="show-icmp"
                    checked={filters.allowIcmpTraffic}
                    onChange={this.handleSetIcmpTraffic}
                  />
                </div>

                <div className="FilterPanel-Divider" />
                <h4>{intl('Vulnerability.ExposureScore')}</h4>
                <div className="FilterPanel-Row">
                  <RadioGroup
                    data={vulnerabilityOptions}
                    value={filters.exposedVulnerabilities}
                    format="vertical"
                    tid="ignore-services"
                    onChange={this.handleSetExposedVulnerabilities}
                  />
                </div>
                <div className="FilterPanel-Divider" />
                <h4>{intl('Vulnerability.Traffic')}</h4>

                <div className="FilterPanel-Row VulnerabilitySeverity">
                  <Slider
                    min={1}
                    max={5}
                    step={1}
                    labels={RenderUtils.getVulnerabilityOptions()}
                    value={filters.vulnerabilitySeverity}
                    onChange={this.handleSetVulnerabilitySeverity}
                  />
                </div>
                <div className="FilterPanel-Row VulnerablityTraffic">
                  <Checkbox
                    label={intl('Vulnerability.BlockedTraffic')}
                    tid="show-blocked-vulnerable"
                    checked={filters.showVulnerableBlockedTraffic}
                    onChange={this.handleSetBlockedVulnerableTraffic}
                  />
                </div>
              </div>
            )}
            {mapLevel !== 'location' && appMapVersion !== 'vulnerability' && (
              <div className="TrafficFilters">
                <h4>{intl('Map.TrafficLinks')}</h4>
                <div className="FilterPanel-Row">
                  <RadioGroup
                    data={servicesOptions}
                    value={filters.ignoreServices ? 'ignore' : 'all'}
                    format="vertical"
                    tid="ignore-services"
                    onChange={this.handleSetServices}
                  />
                </div>
                <div className="FilterPanel-Divider" />
                <div className="FilterPanel-Row FilterPanel-Row--InTwoColumns">
                  <Checkbox
                    label={showBoundaries ? intl('Map.Traffic.Filter.BlockedNoRule') : intl('Common.Blocked')}
                    tid="show-blocked"
                    checked={filters.allowBlockedTraffic}
                    onChange={this.handleSetBlockedTraffic}
                  />
                  {blockedByBoundary}
                  <Checkbox
                    label={
                      showBoundaries
                        ? intl('Map.Traffic.Filter.PotentiallyBlockedNoRule')
                        : intl('Common.PotentiallyBlocked')
                    }
                    tid="potentially-blocked"
                    checked={filters.allowPotentiallyBlockedTraffic}
                    onChange={this.handleSetPotentiallyBlockedTraffic}
                  />
                  {potentiallyBlockedByBoundary}
                  <Checkbox
                    label={showBoundaries ? intl('Map.Traffic.Filter.AllowedByRule') : intl('Common.Allowed')}
                    tid="show-allowed"
                    checked={filters.allowAllowedTraffic}
                    onChange={this.handleSetAllowedTraffic}
                  />
                  {policyVersion === 'reported' ? (
                    <Checkbox
                      label={intl('Common.Unknown')}
                      tid="show-unknown"
                      checked={filters.allowUnknownTraffic}
                      onChange={this.handleSetUnknownTraffic}
                    />
                  ) : (
                    allowedAcrossBoundary
                  )}
                </div>
                <div className="FilterPanel-Divider" />
                <div className="FilterPanel-Row FilterPanel-Row--InTwoColumns">
                  {mapType === 'loc' ? (
                    <Checkbox
                      label={intl('Common.Outbound')}
                      tid="show-outbound"
                      checked={filters.allowOutboundTraffic}
                      onChange={this.handleSetOutboundTraffic}
                    />
                  ) : null}
                  {mapType === 'loc' ? (
                    <Checkbox
                      label={intl('Common.Inbound')}
                      tid="show-inbound"
                      checked={filters.allowInboundTraffic}
                      onChange={this.handleSetInboundTraffic}
                    />
                  ) : null}
                  <Checkbox
                    label={intl('Map.Traffic.Filter.AllowIntraGroup')}
                    tid="show-intra-group"
                    checked={filters.allowIntraAppTraffic}
                    onChange={this.handleSetIntraAppTraffic}
                  />
                  {mapType === 'loc' ? (
                    <Checkbox
                      label={intl('Map.Traffic.Filter.AllowExtraGroup')}
                      tid="show-extra-group"
                      checked={filters.allowInterAppTraffic}
                      onChange={this.handleSetInterAppTraffic}
                    />
                  ) : null}
                  <Checkbox
                    label={intl('PCE.FQDN')}
                    tid="show-fqdn"
                    checked={filters.allowFqdnTraffic}
                    onChange={this.handleSetFqdnTraffic}
                  />
                  <Checkbox
                    label={intl('Common.Internet')}
                    tid="show-internet"
                    checked={filters.allowInternetTraffic}
                    onChange={this.handleSetInternetTraffic}
                  />
                  <Checkbox
                    label={intl('Common.IPList')}
                    tid="show-ip-list"
                    checked={filters.allowIpListTraffic}
                    onChange={this.handleSetIpListTraffic}
                  />
                </div>

                <div className="FilterPanel-Divider" />
                <div className="FilterPanel-Row FilterPanel-Row--InTwoColumns">
                  <Checkbox
                    label={intl('Map.Traffic.Broadcast')}
                    tid="show-broadcast"
                    checked={filters.allowBroadcastTraffic}
                    onChange={this.handleSetBroadcastTraffic}
                  />
                  <Checkbox
                    label={intl('Map.Traffic.Multicast')}
                    tid="show-multicast"
                    checked={filters.allowMulticastTraffic}
                    onChange={this.handleSetMulticastTraffic}
                  />
                  <Checkbox
                    label={intl('Protocol.ICMP')}
                    tid="show-icmp"
                    checked={filters.allowIcmpTraffic}
                    onChange={this.handleSetIcmpTraffic}
                  />
                </div>

                <div className="FilterPanel-Divider" />
                <TimeFilter filter={filters.timeFilter} />
                {policyVersion === 'reported' && (
                  <div className="FilterPanel-Row FilterPanel-Row--InTwoColumns">
                    <Checkbox
                      label={intl('Map.Traffic.Filter.SinceLastProvision')}
                      tid="show-old-traffic"
                      checked={filters.timeFilter.type === 'provision'}
                      onChange={this.handleSetTrafficTimeFilters}
                    />
                  </div>
                )}

                {/*show traffic volume sliders only in full map*/}
                {mapLevel === 'full' && <div className="FilterPanel-Divider" />}
                {mapLevel === 'full' && (
                  <div className="FilterPanel-Row">
                    <div className="TrafficVolumeFilterTitle" data-tid="traffic-volume-filter">
                      <span>{intl('Map.Traffic.Filter.FilterByConnections')}</span>
                      {showFlowNumbers && <span className="FlowLevel">≥ {currentFlow}</span>}
                    </div>
                    <Slider
                      ref="trafficRange"
                      tabIndex="0"
                      tooltip={false}
                      min={1}
                      max={100}
                      value={trafficVolume}
                      onChange={this.handleSetTrafficVolume}
                    />
                    <div className="TrafficVolumeFilterText">
                      {showFlowNumbers && <div>{filters.minFlow}</div>}
                      {showFlowNumbers && <div>{filters.maxFlow}</div>}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="FilterPanel-Divider" />
            {policyStateFilters}
            <div className="FilterPanel-Divider" />
            <div className="FilterPanel-Row-Actions ">
              <Button
                text={intl('Common.Close')}
                content="text-only"
                tid="close"
                type="nofill"
                onClick={this.handleHidePanel}
              />
              <Button
                text={intl('Common.ResetToDefault')}
                type="secondary"
                tid="reset"
                onClick={this.handleResetToDefault}
                disabled={!TrafficFilterStore.areFiltersDefault()} // Set the disabled according to the changes in default filter.
              />
            </div>
          </div>
        )}
      </div>
    );
  },
});
