/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import React, {PropTypes} from 'react';
import intl from 'intl';
import Icon from './Icon.jsx';
import ComponentUtils from '../utils/ComponentUtils';
import SessionStore from '../stores/SessionStore';

const defaultTid = 'comp-label';

export default React.createClass({
  propTypes: {
    icon: PropTypes.string,
    onClick: PropTypes.func,
    text: PropTypes.any,
    type: PropTypes.string,
    exclusion: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      onClick: null,
      text: '',
    };
  },

  render() {
    const type = this.props.type === 'role' && SessionStore.isEdge() ? undefined : this.props.type;

    const classes = cx({
      'Label': true,
      'Label--link': this.props.onClick,
      'Label--role': type === 'role',
      'Label--app': type === 'app',
      'Label--env': type === 'env',
      'Label--loc': type === 'loc',
      'Label--scopes': type === 'scopes',
      'Label--created': type === 'created',
      'Label--deleted': type === 'deleted',
      'Label--disabled': type === 'disabled',
      'Label--usergroup': type === 'usergroup',
      'Label--exclusion': this.props.exclusion,
      'Label--hasicon': this.props.icon,
      'Label--noleftborder': !['role', 'app', 'env', 'loc'].includes(type),
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.type);
    let icon = this.props.icon ? this.props.icon : null;

    let exclusionText = '';
    let text = this.props.text;
    let titleText = text;

    if (this.props.exclusion && ['role', 'app', 'env', 'loc'].includes(icon || type)) {
      exclusionText = intl('Labels.Excluded', {type: icon || type});
    }

    if (type && ['role', 'app', 'env', 'loc'].includes(type)) {
      // EYE-80061 - props.text is sometimes an array of JSX to handle text highlighting/matching in dropdowns
      if (Array.isArray(text)) {
        text = this.props.text.map(({props: {children}}) => children).join('');
      }

      titleText = this.props.exclusion ? intl('Labels.Excluded', {type}) : intl('Labels.Text', {type});
      titleText = `${titleText} ${text}`;

      icon = icon || type;
    }

    if (icon === 'loc') {
      icon = 'location';
    }

    return (
      <div className={classes} data-tid={ComponentUtils.tidString(tids)} onClick={this.props.onClick} title={titleText}>
        {icon ? (
          <div className={`Label-icon Label-icon--${icon}`}>
            <Icon name={icon} />
          </div>
        ) : null}
        <span className="Label-text" data-tid="elem-text">
          {`${exclusionText} ${text}`}
        </span>
      </div>
    );
  },
});
